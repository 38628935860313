define("primathon-ember-web/components/ai-development/introduction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='aiDevelopment-introductionContainer'>
    <div class='contentContainer'>
      <h2>{{@intro.heading}} </h2>
      <p>
        {{#each @intro.description as |item|}}
          {{item}}
          <br /><br />
        {{/each}}
      </p>
      <a href={{@intro.buttonLink}}>
        <button {{on 'click' (if @data.gtagEventName (fired-gtag-event @data.gtagEventName) (fn (noop)))}}>
          {{@intro.buttonText}}
        </button>
      </a>
    </div>
    <Common::AppImage
      @src={{@intro.imageSrc}}
      class='{{if @imgStyle @imgStyle "w-[567px] h-[624px]"}}'
      alt='introduction'
      title='introduction'
    />
  </div>
  */
  {
    "id": "yXF7iMbG",
    "block": "[[[10,0],[14,0,\"aiDevelopment-introductionContainer\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"contentContainer\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,[30,1,[\"heading\"]]],[1,\" \"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"description\"]]],null]],null],null,[[[1,\"        \"],[1,[30,2]],[1,\"\\n        \"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,3],[15,6,[30,1,[\"buttonLink\"]]],[12],[1,\"\\n      \"],[11,\"button\"],[4,[38,2],[\"click\",[52,[30,3,[\"gtagEventName\"]],[28,[37,4],[[30,3,[\"gtagEventName\"]]],null],[28,[37,5],[[28,[37,6],null,null]],null]]],null],[12],[1,\"\\n        \"],[1,[30,1,[\"buttonText\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,7],[[16,0,[29,[[52,[30,4],[30,4],\"w-[567px] h-[624px]\"]]]],[24,\"alt\",\"introduction\"],[24,\"title\",\"introduction\"]],[[\"@src\"],[[30,1,[\"imageSrc\"]]]],null],[1,\"\\n\"],[13]],[\"@intro\",\"item\",\"@data\",\"@imgStyle\"],false,[\"each\",\"-track-array\",\"on\",\"if\",\"fired-gtag-event\",\"fn\",\"noop\",\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/ai-development/introduction.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});