define("primathon-ember-web/constants/emberjs/emberjs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EMBER = void 0;
  const EMBER = {
    whyChooseEmber: {
      title: 'Why Should You Choose Ember Js Development Services?',
      description: "Ember.js stands out as a user-friendly JavaScript framework crafted for building robust web and mobile applications. This open-source tool facilitates the creation of fast and secure applications tailored for businesses. Get ready to hire our skilled Ember.js  developers to enhance your business’s digital presence! Our committed Ember JS developers breathe life into innovation, guaranteeing robust, scalable, and efficient solutions delivered within your project's timeframe. Here's why you should choose us:",
      imageSrc: '/assets/emberjs/ember-js.png',
      alt: 'Ember Js Development Services',
      listItems: [{
        id: 1,
        text: '100% Skilled and Certified Developers'
      }, {
        id: 2,
        text: 'Possesses an Average of 5+ Years of Experience'
      }, {
        id: 3,
        text: 'Swift Onboarding Process'
      }, {
        id: 4,
        text: 'Competitive Pricing Models'
      }, {
        id: 5,
        text: 'Transparent and Smooth Communication'
      }]
    },
    keySpecifications: {
      title: 'Key Specifications of Ember Framework That Makes It Unique',
      cardData: [{
        id: 1,
        imageSrc: '/assets/emberjs/configuration.png',
        cardTitle: 'Convention over Configuration',
        content: 'Ember.js follows the principle of "convention over configuration," which means that it comes with a set of predefined conventions and best practices for structuring code and building applications.'
      }, {
        id: 2,
        imageSrc: '/assets/emberjs/ecosystem.png',
        cardTitle: 'Expansive Ecosystem',
        content: 'Ember.js offers a rich selection of addons, providing developers with the tools to seamlessly and efficiently incorporate new features into their applications.'
      }, {
        id: 3,
        imageSrc: '/assets/emberjs/reduced-coding.png',
        cardTitle: 'Reduced Coding',
        content: 'Ember.js demands minimal coding efforts compared to other prominent JavaScript development frameworks.'
      }, {
        id: 4,
        imageSrc: '/assets/emberjs/compatibility.png',
        cardTitle: 'Seamless Compatibility',
        content: 'Ember.js framework ensures backward compatibility, enabling developers to effortlessly implement upgrades or modifications to existing software.'
      }, {
        id: 5,
        imageSrc: '/assets/emberjs/friendly-platform.png',
        cardTitle: 'Developer-Friendly Platform',
        content: 'Ember.js is widely embraced by developers, facilitating the creation of custom and distinctive applications tailored for businesses with ease.'
      }]
    },
    customizedEmberJsServices: {
      title: 'We offer Customized Ember.js Development Services for Premium Web App Development',
      cardData: [{
        id: '1',
        cardTitle: 'Custom Ember.js Application Development',
        content: 'Our team of experts diligently analyzes your requirements to provide customized solutions tailored to meet your unique business needs.'
      }, {
        id: '2',
        cardTitle: 'Ember.js Consulting',
        content: "Our team provides expert guidance on Ember.js architecture, best practices, and advanced features, ensuring optimal utilization of the framework's capabilities."
      }, {
        id: '3',
        cardTitle: 'UI/UX Design for Ember.js Applications',
        content: 'Our specialists design user interfaces that are easy to use and visually appealing, enhancing user experience and driving engagement.'
      }, {
        id: '4',
        cardTitle: 'Ember.js Application Maintenance and Support',
        content: 'Our experts continuously manage updates, maintain performance, and optimize functionality to ensure your application runs smoothly and remains competitive in the market.'
      }, {
        id: '5',
        cardTitle: 'Migration to Ember.js',
        content: 'Being a trusted Ember.js company, we effortlessly transition current applications to the Ember.js framework, leveraging its robust features and enhancing scalability and performance for future growth and innovation.'
      }]
    },
    whyChoose: {
      title: 'Why Choose Primathon for Ember.js Development?',
      cardData: [{
        id: 1,
        imageSrc: '/assets/emberjs/experienced-team.svg',
        cardTitle: 'Experienced Team of Ember.js Developers',
        cardData: 'Our dedicated Ember.js web application developers boast extensive experience and expertise, enabling us to deliver high-end business solutions tailored to your specific needs.'
      }, {
        id: 2,
        imageSrc: '/assets/emberjs/proven-excellence.svg',
        cardTitle: 'Proven Excellence in Ember.js App Development',
        cardData: 'With over 7 years of expertise, we have consistently delivered top-notch Ember.js applications, renowned for their high productivity and user-friendly interface, tailored to meet diverse requirements.'
      }, {
        id: 3,
        imageSrc: '/assets/emberjs/best-practices.svg',
        cardTitle: 'We Use Latest Ember.js Features and Best Practices',
        cardData: 'We prioritize adherence to industry best practices and leverage cutting-edge technologies to deliver optimal results for you.'
      }, {
        id: 4,
        imageSrc: '/assets/emberjs/flexible-management.svg',
        cardTitle: 'Flexible Engagement Models',
        cardData: "We understand that every business has unique requirements and budgets. That's why our engagement models are highly flexible, allowing you to choose the most suitable one for your project without strict parameters."
      }, {
        id: 5,
        imageSrc: '/assets/emberjs/on-time-delivery.svg',
        cardTitle: 'On-Time Delivery',
        cardData: 'Our Ember.js professionals are committed to delivering projects within the specified timeframe. We adhere to the best industry practices and maintain the highest level of quality to ensure timely delivery of your project.'
      }, {
        id: 6,
        imageSrc: '/assets/emberjs/support.svg',
        cardTitle: '24x7 Support',
        cardData: 'Our dedicated support team provides round-the-clock assistance, ensuring all queries and concerns are addressed promptly and effectively.'
      }]
    },
    whyHire: {
      title: 'Why Hire EmberJS Developers from Primathon?',
      description: 'Our ember.js developers excel in their domain, boasting over 7 years of experience in crafting scalable and high-performing python applications. We remain abreast of the latest ember advancements and tools, guaranteeing that your project is constructed using cutting-edge technology. Security is paramount in our development process, ensuring that your ember applications are shielded against threats and vulnerabilities. We fine-tune your ember.js  applications for speed and efficiency, delivering exceptional user experiences within the project timeline and budget constraints.',
      listItems: [{
        id: 1,
        text: 'Experience 1.5X Faster Delivery'
      }, {
        id: 2,
        text: 'Work at Your Time Zone'
      }, {
        id: 3,
        text: 'Hassle-free communication'
      }, {
        id: 4,
        text: '24/7 Support'
      }],
      service1: {
        number: '6+',
        text: 'Years in business'
      },
      service2: {
        number: '80+',
        text: 'Software Developers'
      },
      service3: {
        number: '150+',
        text: 'Project Delivered'
      },
      service4: {
        number: '100+',
        text: 'Happy Client'
      }
    },
    emberJsDevelopmentServices: {
      title: "Primathon's Approach to Ember.js Development Services",
      cardData: [{
        id: 1,
        imageSrc: '/assets/emberjs/consultation.svg',
        cardTitle: 'Comprehensive Consultation',
        content: "Primathon begins by conducting thorough consultations to understand the client's business goals, requirements, and challenges, ensuring alignment before commencing development."
      }, {
        id: 2,
        imageSrc: '/assets/emberjs/customized-solution.svg',
        cardTitle: 'Customized Solutions',
        content: 'Tailoring Ember.js development services to match the specific needs and preferences of each client, whether they require web or mobile applications.'
      }, {
        id: 3,
        imageSrc: '/assets/emberjs/agile-development.svg',
        cardTitle: 'Agile Development Methodology',
        content: 'Following agile development methodologies to promote flexibility, collaboration, and timely delivery of high-quality Ember.js solutions.'
      }, {
        id: 4,
        imageSrc: '/assets/emberjs/design-excellence.svg',
        cardTitle: 'UI/UX Design Excellence',
        content: ' Integrating cutting-edge UI/UX design principles to create visually appealing, intuitive, and user-friendly Ember.js applications that drive engagement and satisfaction.'
      }, {
        id: 5,
        imageSrc: '/assets/emberjs/performance-optimization.svg',
        cardTitle: 'Scalability and Performance Optimization',
        content: 'Designing Ember.js applications with scalability and performance in mind, ensuring they can handle increased user loads and deliver exceptional speed and responsiveness.'
      }, {
        id: 6,
        imageSrc: '/assets/emberjs/security.svg',
        cardTitle: 'Security Implementation',
        content: 'Prioritizing robust security measures throughout the development process to protect against potential threats and vulnerabilities, safeguarding sensitive data and user privacy.'
      }, {
        id: 7,
        imageSrc: '/assets/emberjs/continuous-support.svg',
        cardTitle: 'Continuous Support and Maintenance',
        content: 'Providing ongoing support and maintenance services post-launch to address any issues, implement updates, and ensure the Ember.js application remains secure, functional, and up-to-date.'
      }, {
        id: 8,
        imageSrc: '/assets/emberjs/transparent-comunication.svg',
        cardTitle: 'Transparent Communication',
        content: 'Maintaining open and transparent communication channels with clients throughout the development process, providing regular updates and seeking feedback to ensure alignment with expectations.'
      }, {
        id: 9,
        imageSrc: '/assets/emberjs/quality-assurance.svg',
        cardTitle: 'Quality Assurance',
        content: 'Conducting rigorous testing and quality assurance procedures to identify and rectify any issues or bugs, ensuring the Ember.js application meets the highest standards of performance, reliability, and functionality.'
      }]
    },
    emberJsJourney: {
      heading: 'Let us take your project on a journey!',
      items: ['You share your idea with us', 'We create a strategy for your project', 'Our Ember.js Developer starts building it', 'We deliver on time ']
    },
    keyBenefitsOfEmberJSDevelopment: {
      title: 'Key Benefits of Ember.Js Development Included in Our Services',
      cardData: [{
        id: '1',
        cardTitle: 'Rapid Development',
        content: "Using Ember.js's robust conventions and powerful tools to accelerate the development process, resulting in quicker time-to-market for your web or mobile application."
      }, {
        id: '2',
        cardTitle: 'Scalability',
        content: 'Building Ember.js applications that are inherently scalable, allowing them to seamlessly accommodate growing user bases and evolving business needs without compromising performance.'
      }, {
        id: '3',
        cardTitle: 'Rich UI/UX',
        content: "Using Ember.js's extensive ecosystem of UI components and libraries to create visually stunning and highly interactive user interfaces, enhancing user engagement and satisfaction."
      }, {
        id: '4',
        cardTitle: 'Maintainability',
        content: "Utilizing Ember.js's opinionated structure and conventions to streamline code organization and maintainability, making it easier to manage and extend the application over time."
      }, {
        id: '5',
        cardTitle: 'Consistency',
        content: "Ensuring consistency across different parts of the application through Ember.js's conventions, reducing development overhead and improving code maintainability."
      }, {
        id: '6',
        cardTitle: 'Productivity',
        content: "Empowering developers to focus on building features rather than boilerplate code through Ember.js's built-in conventions, tools, and efficient development workflow."
      }]
    },
    lookingToEmberJsDevelopment: {
      title: 'Looking to Develop Ember.js Mobile or Web Applications?',
      description: 'Hire skilled developers and create highly productive, user-friendly applications at competitive rates.',
      ctaText: 'Let’s Talk',
      ctaRoute: 'contact'
    },
    faqs: {
      title: 'FAQs',
      cardData: [{
        id: 1,
        question: 'What is Ember.js, and why should I choose it for my project?',
        answer: ['Ember.js is a powerful JavaScript framework used for building scalable web applications. It offers a robust set of tools and conventions that streamline development and ensure maintainability. You should choose Ember.js for your project because it provides a structured approach to development, accelerates time-to-market, and offers a rich ecosystem of add-ons and community support.']
      }, {
        id: 2,
        question: 'What types of applications can be developed using Ember.js?',
        answer: ['Ember.js is versatile and can be used to develop various types of applications, including single-page applications (SPAs), web applications, and mobile applications. Its flexibility and scalability make it suitable for projects of all sizes, from small startups to large enterprises.']
      }, {
        id: 3,
        question: 'How does Ember.js handle data management and state management?',
        answer: ['Ember.js comes with built-in features for handling data management and state management. It utilizes a powerful data layer called Ember Data, which simplifies interactions with APIs and databases. Additionally, Ember.js employs a robust routing system and Ember Object model to manage application state efficiently.']
      }, {
        id: 4,
        question: 'Is Ember.js suitable for SEO-friendly applications?',
        answer: ['Yes, Ember.js is well-suited for building SEO-friendly applications. It supports server-side rendering (SSR) through tools like FastBoot, which allows search engines to crawl and index the content of your application effectively. Additionally, Ember.js provides meta tag management and other SEO optimization features out of the box.']
      }, {
        id: 5,
        question: 'What kind of support and maintenance services do Ember.js development companies offer?',
        answer: ['Ember.js development companies typically offer a range of support and maintenance services to ensure the long-term success of your application. This may include bug fixes, performance optimization, security updates, feature enhancements, and ongoing technical support. Additionally, they may provide training and consulting services to help your team leverage Ember.js effectively.']
      }]
    }
  };
  _exports.EMBER = EMBER;
});