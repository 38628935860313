define("primathon-ember-web/components/chatgpt/devlopment-integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="chatgpt-devlopement-integration-sectionContainer">
      <h2>Custom <span>ChatGPT</span> Development and Integration</h2>
      <p>
          {{@content.content}}
      </p>
  </div>
  */
  {
    "id": "hqPi+MBD",
    "block": "[[[10,0],[14,0,\"chatgpt-devlopement-integration-sectionContainer\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"Custom \"],[10,1],[12],[1,\"ChatGPT\"],[13],[1,\" Development and Integration\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n        \"],[1,[30,1,[\"content\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@content\"],false,[]]",
    "moduleName": "primathon-ember-web/components/chatgpt/devlopment-integration.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});