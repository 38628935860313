define("primathon-ember-web/components/career/joinus/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='join-us'>
    <div class='career-poster-ring'>
      <Common::AppImage @src='/assets/images/career-red-ring.png' alt='red ellipse' />
    </div>
    <div class='describe mobile'>
      <span>
        We are building our team and our team is building the
      </span>
      <span class='green'>
        technology of tomorrow.
      </span>
    </div>
    <div class='joinUsImg'>
      <Common::AppImage @src='/assets/images/joinUs.png' alt='join-us' />
    </div>
    <div style='flex: 1;'>
      <div class='describe'>
        <span class='desktop'>
          We are building our team and our team is building the
        </span>
        <span class='green desktop'>
          technology of tomorrow.
        </span>
        <div class='open-job-button'>
          <LinkTo @route='open-position' class='menu-home'>
            <button>
              Find open Positions
            </button>
          </LinkTo>
        </div>
      </div>
    </div>
  
  </div>
  */
  {
    "id": "KzO4ircz",
    "block": "[[[10,0],[14,0,\"join-us\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"career-poster-ring\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"alt\",\"red ellipse\"]],[[\"@src\"],[\"/assets/images/career-red-ring.png\"]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"describe mobile\"],[12],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      We are building our team and our team is building the\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"green\"],[12],[1,\"\\n      technology of tomorrow.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"joinUsImg\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"alt\",\"join-us\"]],[[\"@src\"],[\"/assets/images/joinUs.png\"]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,5,\"flex: 1;\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"describe\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"desktop\"],[12],[1,\"\\n        We are building our team and our team is building the\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"green desktop\"],[12],[1,\"\\n        technology of tomorrow.\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"open-job-button\"],[12],[1,\"\\n        \"],[8,[39,1],[[24,0,\"menu-home\"]],[[\"@route\"],[\"open-position\"]],[[\"default\"],[[[[1,\"\\n          \"],[10,\"button\"],[12],[1,\"\\n            Find open Positions\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[13]],[],false,[\"common/app-image\",\"link-to\"]]",
    "moduleName": "primathon-ember-web/components/career/joinus/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});