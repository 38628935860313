define("primathon-ember-web/components/homepage/blog", ["exports", "@glimmer/component", "primathon-ember-web/constants/event-name"], function (_exports, _component, _eventName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="container-fluid blog-categories">
    <div class="container">
      <h2 class="center">
        {{@title}}
      </h2>
      <p class="section-sub center">
        {{@description}}
      </p>
      <div class="grid blog-box">
        {{#each @data as |data|}}
          <div class="blog-card">
            <a
              href={{data.link}}
              rel="noopener noreferrer"
              {{on 'click' (fn this.onBlogCardClick data.title data.link)}}
            >
              <Common::AppImage
                @src={{if data.blogImageUrl data.blogImageUrl '/assets/images/blog.png'}}
                loading="lazy"
                alt={{data.title}}
                title={{data.title}}
              />
              <div class="blog-category">
                <h3>
                  {{data.title}}
                </h3>
                <div class="trim-line-3">
                  {{! template-lint-disable no-triple-curlies }}
                  {{{data.descriptionHtml}}}
                </div>
                <span>
                  Read More »
                </span>
              </div>
            </a>
          </div>
        {{/each}}
      </div>
      <div class="view-more">
        <a href="/blog"  rel="noopener noreferrer" {{on 'click' this.onViewMoreBlog}}>
          View More
        </a>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "OyvufWhA",
    "block": "[[[10,0],[14,0,\"container-fluid blog-categories\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"center\"],[12],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"section-sub center\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"grid blog-box\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,3]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"blog-card\"],[12],[1,\"\\n          \"],[11,3],[16,6,[30,4,[\"link\"]]],[24,\"rel\",\"noopener noreferrer\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"onBlogCardClick\"]],[30,4,[\"title\"]],[30,4,[\"link\"]]],null]],null],[12],[1,\"\\n            \"],[8,[39,4],[[24,\"loading\",\"lazy\"],[16,\"alt\",[30,4,[\"title\"]]],[16,\"title\",[30,4,[\"title\"]]]],[[\"@src\"],[[52,[30,4,[\"blogImageUrl\"]],[30,4,[\"blogImageUrl\"]],\"/assets/images/blog.png\"]]],null],[1,\"\\n            \"],[10,0],[14,0,\"blog-category\"],[12],[1,\"\\n              \"],[10,\"h3\"],[12],[1,\"\\n                \"],[1,[30,4,[\"title\"]]],[1,\"\\n              \"],[13],[1,\"\\n              \"],[10,0],[14,0,\"trim-line-3\"],[12],[1,\"\\n\"],[1,\"                \"],[2,[30,4,[\"descriptionHtml\"]]],[1,\"\\n              \"],[13],[1,\"\\n              \"],[10,1],[12],[1,\"\\n                Read More »\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[4]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"view-more\"],[12],[1,\"\\n      \"],[11,3],[24,6,\"/blog\"],[24,\"rel\",\"noopener noreferrer\"],[4,[38,2],[\"click\",[30,0,[\"onViewMoreBlog\"]]],null],[12],[1,\"\\n        View More\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@title\",\"@description\",\"@data\",\"data\"],false,[\"each\",\"-track-array\",\"on\",\"fn\",\"common/app-image\",\"if\"]]",
    "moduleName": "primathon-ember-web/components/homepage/blog.hbs",
    "isStrictMode": false
  });

  let HomepageBlogComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class HomepageBlogComponent extends _component.default {
    onBlogCardClick(blogTitle, blogLink) {
      if (window.gtag) {
        window.gtag('event', _eventName.HOME_BLOG_CARD_CLICK, {
          blogTitle,
          blogLink
        });
      }
    }

    onViewMoreBlog() {
      if (window.gtag) {
        window.gtag('event', _eventName.HOME_VIEW_MORE_BLOG_CLICK);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onBlogCardClick", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onBlogCardClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onViewMoreBlog", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onViewMoreBlog"), _class.prototype)), _class));
  _exports.default = HomepageBlogComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, HomepageBlogComponent);
});