define("primathon-ember-web/components/validated-form", ["exports", "ember-validated-form/components/validated-form", "primathon-ember-web/config/environment"], function (_exports, _validatedForm, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _validatedForm.default.extend({
    config: _environment.default["ember-validated-form"]
  });

  _exports.default = _default;
});