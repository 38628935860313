define("primathon-ember-web/templates/technologies/devops", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7FeRGPIA",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@heroSection\",\"@heroSectionImgStyle\",\"@heroSectionClass\",\"@services\",\"@benefits\",\"@features\",\"@bulletsListImgStyle\",\"@ctaSectionDevops\",\"@whyChoosePrimathon\",\"@faqs\"],[[30,1],[30,1,[\"data\",\"heroSection\"]],\"w-[560px] h-[500px] xl:w-[550px] xl:h-[530px]\",\"bg-[#1A284D] lg:pr-0\",[30,1,[\"data\",\"tailoredNodeJsSolution\"]],[30,1,[\"data\",\"devopsImplementation\"]],[30,1,[\"data\",\"clientChoosingUs\"]],\"w-[350px] h-[350px] xl:w-[420px] xl:h-[380px]\",[30,1,[\"data\",\"boostYourDevOpsDevelopment\"]],[30,1,[\"data\",\"whyChoosePrimathon\"]],[30,1,[\"data\",\"faqs\"]]]],null]],[\"@model\"],false,[\"pages/solutions\"]]",
    "moduleName": "primathon-ember-web/templates/technologies/devops.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});