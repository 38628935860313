define("primathon-ember-web/controllers/services/staff-augmentation/uiux-designers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServicesStaffAugmentationUiuxDesignersController extends Ember.Controller {
    get uiUxtDesignersTitle() {
      return this.model.uiuxDesignersData.title;
    }

    get uiUxDesignersSubHeading() {
      return this.model.uiuxDesignersData.sub_heading;
    }

    get uiUxDesignersAbout() {
      return this.model.uiuxDesignersData.about;
    }

    get uiUxDesignerBg() {
      return this.model.uiuxDesignersData.bg;
    }

    get uiUxDesignerCircularList() {
      return this.model.uiuxDesignersData.circular_list;
    }

    get faqTitle() {
      return this.model.faqData.title;
    }

    get faqData() {
      return this.model.faqData.data;
    }

  }

  _exports.default = ServicesStaffAugmentationUiuxDesignersController;
  ;
});