define("primathon-ember-web/templates/solutions/ai-stock-predictor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1bSlURXn",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@heroSection\",\"@heroSectionImgStyle\",\"@heroSectionClass\",\"@services\",\"@ctaSection1\",\"@whyChoose\",\"@useCases\",\"@bulletsList\",\"@bulletsListImgStyle\",\"@bulletsList2\",\"@bulletsListImgStyle2\",\"@ctaSection2\",\"@features\"],[[30,1],[30,1,[\"data\",\"heroSection\"]],\"w-[560px] h-[530px] xl:w-[550px] xl:h-[550px]\",\"bg-[#1A284D] lg:pr-0\",[30,1,[\"data\",\"aiStockPredictorDevelopmentServices\"]],[30,1,[\"data\",\"ctaSection1\"]],[30,1,[\"data\",\"whyChoose\"]],[30,1,[\"data\",\"howAiStockPredictorWorks\"]],[30,1,[\"data\",\"aiStockPredictorProcessing\"]],\"w-[420px] h-[320px] xl:w-[400px] xl:h-[310px]\",[30,1,[\"data\",\"getReady\"]],\"w-[400px] h-[270px] xl:w-[440px] xl:h-[260px]\",[30,1,[\"data\",\"ctaSection2\"]],[30,1,[\"data\",\"featuresOfAiStockPredictor\"]]]],null]],[\"@model\"],false,[\"pages/solutions\"]]",
    "moduleName": "primathon-ember-web/templates/solutions/ai-stock-predictor.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});