define("primathon-ember-web/constants/services/developer-skills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEVELOPER_SKILLS_DATA = void 0;
  const DEVELOPER_SKILLS_DATA = {
    title: 'Our Dedicated Developers can help you with',
    data: [{
      imgUrl: '/assets/images/dashboard.png',
      title: 'Dashboard and Data visualization',
      content: 'Dashboard and data visualization is a tool that monitors, analyses, and displays key performance indicators (KPIs), metrics, and key data points.'
    }, {
      imgUrl: '/assets/images/shopify.png',
      title: 'Shopify Stores',
      content: 'Shopify is an e-commerce platform that allows you to create online shopping websites and start selling your products without any hassle.'
    }, {
      imgUrl: '/assets/images/book-platform.png',
      title: 'Booking Platform',
      content: 'An online booking system is a software solution that allows potential guests to self-book and pay through your website and other channels.'
    }, {
      imgUrl: '/assets/images/cms.png',
      title: 'Admin Panels and CMS',
      content: "It's a tool that gives you access to common administrative operations, server information, project updates. It gives you the access to monitor and update your website."
    }]
  };
  _exports.DEVELOPER_SKILLS_DATA = DEVELOPER_SKILLS_DATA;
});