define("primathon-ember-web/constants/services/faq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FAQ_DATA = void 0;
  const FAQ_DATA = {
    title: 'Frequently Asked Questions',
    data: [{
      question: 'Does Primathon build Custom Software?',
      answer: 'Yes, Primathon can provide a complete customized solution for your business challenges. We understand your problem, analyze it, brainstorm ideas with our expert team, and evaluate every possibility to provide you with the best solution.'
    }, {
      question: 'Does Primathon provide Software Developers on a contract basis?',
      answer: 'Yes. You can hire our trained Software developers on a full-time contract basis. They will work dedicatedly on your project as your team extension.'
    }, {
      question: 'How is Primathon different from other Software development companies?',
      answer: 'Founded by the Alumni of IIT-Delhi, Primathon is positioned as a premium software development company. We have seen businesses struggling with outsourced products built by service companies. After working in the industry, we found that there is one key issue – quality of the code & the software. It looks and works all good from the outside but making changes later becomes a headache. It becomes really painful to scale them. So, at Primathon, we make high quality solutions with user friendly documentation.'
    }]
  };
  _exports.FAQ_DATA = FAQ_DATA;
});