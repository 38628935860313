define("primathon-ember-web/routes/technologies/react-native", ["exports", "primathon-ember-web/constants/technologies/react-native", "primathon-ember-web/constants/homepage/testimonial"], function (_exports, _reactNative, _testimonial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NodeJsRoute = (_dec = Ember.inject.service, (_class = class NodeJsRoute extends Ember.Route {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "headData", _descriptor, this);
    }

    async model() {
      const ReactNativeData = _reactNative.REACT_NATIVE;
      const testinomialData = _testimonial.TESTIMONIAL_DATA;
      return {
        ReactNativeData,
        testinomialData
      };
    }

    beforeModel() {
      const title = 'Expert React Native Development Service Company | Primathon';
      const description = 'Discover top React Native development services with Primathon. As a leading React Native app development company in India, we provide custom mobile and software solutions tailored to your needs.';
      const keywords = 'top software development services india custom software application development services web application development services india top web application development company delhi best companies for software engineers gurgaon software application development company noida';
      this.headData.setData({
        title,
        description,
        keywords
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "headData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = NodeJsRoute;
});