define("primathon-ember-web/templates/services/end-to-end-products/smart-contract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WuFXVYhW",
    "block": "[[[8,[39,0],null,[[\"@about\",\"@title\",\"@description\",\"@imgUrl\",\"@bg\",\"@circular_list\",\"@arrow_list\",\"@linear_list\"],[[30,0,[\"smartContractAbout\"]],[30,0,[\"smartContractTitle\"]],[30,0,[\"smartContractDescription\"]],[30,0,[\"smartContractImg\"]],[30,0,[\"smartContractBg\"]],[30,0,[\"smartContractCircularList\"]],[30,0,[\"smartContractArrowList\"]],[30,0,[\"smartContractLinearList\"]]]],null],[1,\"\\n\"]],[],false,[\"pages/services/end-to-end-product-services\"]]",
    "moduleName": "primathon-ember-web/templates/services/end-to-end-products/smart-contract.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});