define("primathon-ember-web/components/ai-development/service-provided", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='ai-services-section-Container'>
    <div class='headingContainer'>
      <h2 class='heading'>{{@services.title}}</h2>
    </div>
    
    <div class='cardContainer'>
      <div class='cards'>
        {{#each @services.cardData as |item|}}
          <div class='card'>
            <div class='backgroundimgContainer' style='background-image:url({{item.bgImg}})'>
              <h3 class='backgroundHeading'>{{item.heading}}</h3>
            </div>
            <div class='contentContainer'>
              <h2>{{item.subHeading}}</h2>
              <ul class='ulContainer'>
                {{#each item.listItem as |liData|}}
                  <li>{{liData.data}}</li>
                {{/each}}
              </ul>
            </div>
          </div>
        {{/each}}
      </div>
    </div>
  </div>
  */
  {
    "id": "G1EJA4wq",
    "block": "[[[10,0],[14,0,\"ai-services-section-Container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"headingContainer\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"heading\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \\n  \"],[10,0],[14,0,\"cardContainer\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"cards\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"cardData\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"card\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"backgroundimgContainer\"],[15,5,[29,[\"background-image:url(\",[30,2,[\"bgImg\"]],\")\"]]],[12],[1,\"\\n            \"],[10,\"h3\"],[14,0,\"backgroundHeading\"],[12],[1,[30,2,[\"heading\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"contentContainer\"],[12],[1,\"\\n            \"],[10,\"h2\"],[12],[1,[30,2,[\"subHeading\"]]],[13],[1,\"\\n            \"],[10,\"ul\"],[14,0,\"ulContainer\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2,[\"listItem\"]]],null]],null],null,[[[1,\"                \"],[10,\"li\"],[12],[1,[30,3,[\"data\"]]],[13],[1,\"\\n\"]],[3]],null],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@services\",\"item\",\"liData\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "primathon-ember-web/components/ai-development/service-provided.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});