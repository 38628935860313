define("primathon-ember-web/components/case-study-blog/about-bloom/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section
    class='flex flex-col md:flex-row justify-center gap-14 md:gap-28 px-6 py-14 sm:px-10 sm:py-24 lg:p-24 xl:py-36 xl:px-64'
  >
    <div>
      <h3 class='font-medium text-xl md:text-3xl mb-5 md:mb-11'>{{@data.heading}}</h3>
      <div class='max-w-[640px] xl:max-w-[800px]'>
        <ul class='list-disc pl-8'>
          {{#each @data.bulletList as |list|}}
            <li class='text-gray-500 text-base mb-2'>{{list}}</li>
          {{/each}}
        </ul>
      </div>
    </div>
    <div class='flex flex-col gap-y-7 md:gap-y-10'>
      {{#each @data.about as |item|}}
        <div key={{item.id}} class='flex items-center gap-x-5 md:gap-x-8'>
          <div class='p-4 w-[56px] h-[56px] md:p-5 md:w-[64px] md:h-[64px] rounded-full bg-[#2487E8] items-center'>
            <Common::AppImage @src={{item.imageSrc}} alt='icon' title='icon' />
          </div>
          <div>
            <p class='text-lg md:text-xl font-medium mb-0'>{{item.title}}</p>
            <p class='text-sm md:text-base text-[#0092FF] mb-0'>{{item.detail}}</p>
          </div>
        </div>
      {{/each}}
    </div>
  </section>
  */
  {
    "id": "cc9+NGKK",
    "block": "[[[10,\"section\"],[14,0,\"flex flex-col md:flex-row justify-center gap-14 md:gap-28 px-6 py-14 sm:px-10 sm:py-24 lg:p-24 xl:py-36 xl:px-64\"],[12],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,\"h3\"],[14,0,\"font-medium text-xl md:text-3xl mb-5 md:mb-11\"],[12],[1,[30,1,[\"heading\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"max-w-[640px] xl:max-w-[800px]\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"list-disc pl-8\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"bulletList\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[14,0,\"text-gray-500 text-base mb-2\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col gap-y-7 md:gap-y-10\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"about\"]]],null]],null],null,[[[1,\"      \"],[10,0],[15,\"key\",[30,3,[\"id\"]]],[14,0,\"flex items-center gap-x-5 md:gap-x-8\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"p-4 w-[56px] h-[56px] md:p-5 md:w-[64px] md:h-[64px] rounded-full bg-[#2487E8] items-center\"],[12],[1,\"\\n          \"],[8,[39,2],[[24,\"alt\",\"icon\"],[24,\"title\",\"icon\"]],[[\"@src\"],[[30,3,[\"imageSrc\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[12],[1,\"\\n          \"],[10,2],[14,0,\"text-lg md:text-xl font-medium mb-0\"],[12],[1,[30,3,[\"title\"]]],[13],[1,\"\\n          \"],[10,2],[14,0,\"text-sm md:text-base text-[#0092FF] mb-0\"],[12],[1,[30,3,[\"detail\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[3]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@data\",\"list\",\"item\"],false,[\"each\",\"-track-array\",\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/case-study-blog/about-bloom/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});