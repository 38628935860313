define("primathon-ember-web/components/services/elevate-your-project/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='py-16 px-6 sm:px-16 xl:p-24'>
    <div class='flex flex-col lg:flex-row justify-center items-center gap-10'>
      <Common::AppImage @src={{@data.imageSrc}} class='{{@imgStyle}} block' alt={{@data.imageAlt}} title={{@data.imageAlt}} />
      <div class='max-w-[550px]'>
        <h2 class='text-2xl sm:text-3xl font-semibold mb-12'>
          {{@data.title}}
        </h2>
        <p class='mt-8 mb-10 sm:mt-12 sm:mb-16'>
          {{@data.description}}
        </p>
        <a href={{@data.redirect}}>
          <button
            {{on 'click' (if @data.gtagEventName (fired-gtag-event @data.gtagEventName) (fn (noop)))}}
            class='rounded border-2 border-[#24B768] border-solid font-medium text-white py-4 px-8 bg-[#24B768]'
          >
            {{@data.ctaText}}
          </button>
        </a>
      </div>
    </div>
  </section>
  */
  {
    "id": "qtG7+MwR",
    "block": "[[[10,\"section\"],[14,0,\"py-16 px-6 sm:px-16 xl:p-24\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col lg:flex-row justify-center items-center gap-10\"],[12],[1,\"\\n    \"],[8,[39,0],[[16,0,[29,[[30,1],\" block\"]]],[16,\"alt\",[30,2,[\"imageAlt\"]]],[16,\"title\",[30,2,[\"imageAlt\"]]]],[[\"@src\"],[[30,2,[\"imageSrc\"]]]],null],[1,\"\\n    \"],[10,0],[14,0,\"max-w-[550px]\"],[12],[1,\"\\n      \"],[10,\"h2\"],[14,0,\"text-2xl sm:text-3xl font-semibold mb-12\"],[12],[1,\"\\n        \"],[1,[30,2,[\"title\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,0,\"mt-8 mb-10 sm:mt-12 sm:mb-16\"],[12],[1,\"\\n        \"],[1,[30,2,[\"description\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,3],[15,6,[30,2,[\"redirect\"]]],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"rounded border-2 border-[#24B768] border-solid font-medium text-white py-4 px-8 bg-[#24B768]\"],[4,[38,1],[\"click\",[52,[30,2,[\"gtagEventName\"]],[28,[37,3],[[30,2,[\"gtagEventName\"]]],null],[28,[37,4],[[28,[37,5],null,null]],null]]],null],[12],[1,\"\\n          \"],[1,[30,2,[\"ctaText\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@imgStyle\",\"@data\"],false,[\"common/app-image\",\"on\",\"if\",\"fired-gtag-event\",\"fn\",\"noop\"]]",
    "moduleName": "primathon-ember-web/components/services/elevate-your-project/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});