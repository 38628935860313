define("primathon-ember-web/controllers/services/staff-augmentation/quality-analysts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServicesStaffAugmentationQualityAnalystsController extends Ember.Controller {
    get qualityAnalystsTitle() {
      return this.model.qualityAnalystsData.title;
    }

    get qualityAnalystsSubHeading() {
      return this.model.qualityAnalystsData.sub_heading;
    }

    get qualityAnalystsData() {
      return this.model.qualityAnalystsData.data;
    }

    get qualityAnalystsAbout() {
      return this.model.qualityAnalystsData.about;
    }

    get qualityAnalystsBg() {
      return this.model.qualityAnalystsData.bg;
    }

    get qualityAnalystsCircularList() {
      return this.model.qualityAnalystsData.circular_list;
    }

    get faqTitle() {
      return this.model.faqData.title;
    }

    get faqData() {
      return this.model.faqData.data;
    }

  }

  _exports.default = ServicesStaffAugmentationQualityAnalystsController;
  ;
});