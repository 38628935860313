define("primathon-ember-web/routes/case-studies/case-study", ["exports", "fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CaseStudiesCaseStudyRoute = (_dec = Ember.inject.service, (_class = class CaseStudiesCaseStudyRoute extends Ember.Route {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "headData", _descriptor, this);
    }

    beforeModel(props) {
      const title = 'Top Custom Software Development Services | Primathon';
      const description = 'Looking for the best custom software service in India? Look no further than Primathon - the premier web application development company in Delhi.';
      const keywords = 'top software development services india custom software application development services web application development services india top web application development company delhi best companies for software engineers gurgaon software application development company noida ';
      this.headData.setData({
        title,
        description,
        keywords
      });
    }

    async model(obj) {
      const post = obj.post_id;
      const json = await (0, _fetch.default)(`/json-data/casestudies/${post}Page.json`);
      const data = await json.json();
      return data;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "headData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CaseStudiesCaseStudyRoute;
});