define("primathon-ember-web/components/pages/services/staff-augmentation-new-services/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Header />
  <div class='app-container'>
    <Ai-development::Hero-section class='service-heroSectionContainer {{@bgImg}}' @heroSection={{@heroSection}} />
    {{#if @serviceByPrimathon}}
      <Ai-development::Introduction @intro={{@serviceByPrimathon}} @imgStyle='w-[350px] h-[250px]' />
    {{/if}}
    <Chatgpt::Getting-started @gettingStarted={{@customizedDevelopment}} />
    <Chatgpt::Benefits @benefits={{@whyChoose}} />
    <Ai-development::Introduction @intro={{@primathonApproach}} @imgStyle='w-[350px] h-[250px]' />
    <Technologies::CtaSection @ctaSection={{@nextLevelDevelopment}} />
    <Homepage::Testimonial
      @title={{@data.testinomialData.title}}
      @description={{@data.testinomialData.description}}
      @data={{@data.testinomialData.data}}
    />
    <Chatgpt::Getting-started @gettingStarted={{@advantagesOfDevelopment}} />
    <Technologies::CtaSection @ctaSection={{@commenceYourJourney}} />
    <Ai-development::Introduction @intro={{@driveInnovation}} @imgStyle='w-[350px] h-[250px]' />
    <Footer />
  </div>
  */
  {
    "id": "21hjwxzk",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,1],[[16,0,[29,[\"service-heroSectionContainer \",[30,1]]]]],[[\"@heroSection\"],[[30,2]]],null],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[8,[39,3],null,[[\"@intro\",\"@imgStyle\"],[[30,3],\"w-[350px] h-[250px]\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[8,[39,4],null,[[\"@gettingStarted\"],[[30,4]]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@benefits\"],[[30,5]]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@intro\",\"@imgStyle\"],[[30,6],\"w-[350px] h-[250px]\"]],null],[1,\"\\n  \"],[8,[39,6],null,[[\"@ctaSection\"],[[30,7]]],null],[1,\"\\n  \"],[8,[39,7],null,[[\"@title\",\"@description\",\"@data\"],[[30,8,[\"testinomialData\",\"title\"]],[30,8,[\"testinomialData\",\"description\"]],[30,8,[\"testinomialData\",\"data\"]]]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@gettingStarted\"],[[30,9]]],null],[1,\"\\n  \"],[8,[39,6],null,[[\"@ctaSection\"],[[30,10]]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@intro\",\"@imgStyle\"],[[30,11],\"w-[350px] h-[250px]\"]],null],[1,\"\\n  \"],[8,[39,8],null,null,null],[1,\"\\n\"],[13]],[\"@bgImg\",\"@heroSection\",\"@serviceByPrimathon\",\"@customizedDevelopment\",\"@whyChoose\",\"@primathonApproach\",\"@nextLevelDevelopment\",\"@data\",\"@advantagesOfDevelopment\",\"@commenceYourJourney\",\"@driveInnovation\"],false,[\"header\",\"ai-development/hero-section\",\"if\",\"ai-development/introduction\",\"chatgpt/getting-started\",\"chatgpt/benefits\",\"technologies/cta-section\",\"homepage/testimonial\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/services/staff-augmentation-new-services/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});