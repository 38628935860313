define("primathon-ember-web/templates/solutions/customer-behavior-analysis-tool", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Typ/8D9X",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@heroSection\",\"@heroSectionImgStyle\",\"@heroSectionClass\",\"@services\",\"@journey\",\"@bulletsList\",\"@bulletsListImgStyle\",\"@bulletsList2\",\"@bulletsListImgStyle2\",\"@bulletsList3\",\"@bulletsListImgStyle3\",\"@whyHire\",\"@features\",\"@faqs\"],[[30,1],[30,1,[\"data\",\"heroSection\"]],\"w-[525px] h-[480px] xl:w-[610px] xl:h-[500px]\",\"bg-[#1A284D] lg:pr-0\",[30,1,[\"data\",\"aiDrivenToolDevelopmentServices\"]],[30,1,[\"data\",\"journey\"]],[30,1,[\"data\",\"whyTrust\"]],\"w-[400px] h-[300px] xl:w-[440px] xl:h-[320px]\",[30,1,[\"data\",\"whyThisTool\"]],\"w-[400px] h-[230px] xl:w-[420px] xl:h-[230px]\",[30,1,[\"data\",\"unlockingAIFeatures\"]],\"w-[400px] h-[320px] xl:w-[430px] xl:h-[350px]\",[30,1,[\"data\",\"whyHire\"]],[30,1,[\"data\",\"whyChoose\"]],[30,1,[\"data\",\"faqs\"]]]],null]],[\"@model\"],false,[\"pages/solutions\"]]",
    "moduleName": "primathon-ember-web/templates/solutions/customer-behavior-analysis-tool.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});