define("primathon-ember-web/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "clpG7h2z",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[8,[39,2],null,null,null],[1,\"\\n\"],[1,\"  \"],[8,[39,3],null,null,null],[1,\"\\n\\n  \"],[8,[39,4],null,[[\"@title\",\"@description\",\"@data\"],[[30,0,[\"serviceTitle\"]],[30,0,[\"serviceDescription\"]],[30,0,[\"serviceData\"]]]],null],[1,\"\\n  \"],[8,[39,5],null,null,null],[1,\"\\n  \"],[8,[39,6],null,[[\"@title\",\"@description\",\"@data\"],[[30,0,[\"coreValueTitle\"]],[30,0,[\"coreValueDescription\"]],[30,0,[\"coreValueData\"]]]],null],[1,\"\\n  \"],[8,[39,7],null,[[\"@data\"],[[30,0,[\"ctaSection\"]]]],null],[1,\"\\n  \"],[8,[39,8],null,null,null],[1,\"\\n  \"],[8,[39,9],null,null,null],[1,\"\\n  \"],[8,[39,10],null,[[\"@title\",\"@description\",\"@data\"],[[30,0,[\"testimonialTitle\"]],[30,0,[\"testimonialDescription\"]],[30,0,[\"testimonialData\"]]]],null],[1,\"\\n  \"],[8,[39,11],null,[[\"@title\",\"@description\",\"@data\"],[[30,0,[\"blogTitle\"]],[30,0,[\"blogDescription\"]],[30,0,[\"blogData\"]]]],null],[1,\"\\n  \"],[8,[39,12],null,[[\"@gtagEventName\"],[[36,13]]],null],[1,\"\\n  \"],[8,[39,14],null,null,null],[1,\"\\n\"],[13]],[],false,[\"header\",\"homepage/hero-section\",\"homepage/partners-section\",\"contact/primathon-performance\",\"homepage/services-slider\",\"homepage/primathon-in-news\",\"homepage/core-value\",\"services/cta-section\",\"homepage/tools\",\"homepage/clients-section\",\"homepage/testimonial\",\"homepage/blog\",\"homepage/hire-us\",\"HIRE_REACT_JS_BUTTON_CLICK\",\"footer\"]]",
    "moduleName": "primathon-ember-web/templates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});