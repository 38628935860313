define("primathon-ember-web/components/chatgpt/services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="chatgpt-serviceContainer">
      <div class="chatgpt-serviceHeadingContainer">
          <h2>Services provided by Primathon with <span>ChatGPT</span></h2>
      </div>
  
      <div class="chatgpt-serviceCardContainer">
          {{#each @services.cardData as |service|}}
          <div class="chatgpt-serviceCard">
              <Common::AppImage @src={{service.imageSrc}} />
              <p>{{service.content}}</p>
          </div>
          {{/each}}
      </div>
  </div>
  */
  {
    "id": "toyTPQ4d",
    "block": "[[[10,0],[14,0,\"chatgpt-serviceContainer\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"chatgpt-serviceHeadingContainer\"],[12],[1,\"\\n        \"],[10,\"h2\"],[12],[1,\"Services provided by Primathon with \"],[10,1],[12],[1,\"ChatGPT\"],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"chatgpt-serviceCardContainer\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"cardData\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"chatgpt-serviceCard\"],[12],[1,\"\\n            \"],[8,[39,2],null,[[\"@src\"],[[30,2,[\"imageSrc\"]]]],null],[1,\"\\n            \"],[10,2],[12],[1,[30,2,[\"content\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"],[13]],[\"@services\",\"service\"],false,[\"each\",\"-track-array\",\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/chatgpt/services.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});