define("primathon-ember-web/controllers/services/staff-augmentation/android-developers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServicesStaffAugmentationAndroidDevelopersController extends Ember.Controller {
    get androidDevelopersTitle() {
      return this.model.androidDevelopersData.title;
    }

    get androidDeveloperSubHeading() {
      return this.model.androidDevelopersData.sub_heading;
    }

    get androidDevelopersData() {
      return this.model.androidDevelopersData.data;
    }

    get androidDeveloperAbout() {
      return this.model.androidDevelopersData.about;
    }

    get androidDeveloperBg() {
      return this.model.androidDevelopersData.bg;
    }

    get androidDeveloperCircularList() {
      return this.model.androidDevelopersData.circular_list;
    }

  }

  _exports.default = ServicesStaffAugmentationAndroidDevelopersController;
  ;
});