define("primathon-ember-web/components/ai-development/hero-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='aiDevelopment-heroSectionContainer' ...attributes>
    <h1>{{@heroSection.title}}</h1>
    <p>{{@heroSection.subTitle}}</p>
    {{#if @heroSection.buttonData}}
      <a href={{@heroSection.redirect}}>
        <button {{on 'click' (if @data.gtagEventName (fired-gtag-event @data.gtagEventName) (fn (noop)))}}>
          {{@heroSection.buttonData}}
        </button>
      </a>
    {{/if}}
  </div>
  */
  {
    "id": "2bl77UaJ",
    "block": "[[[11,0],[24,0,\"aiDevelopment-heroSectionContainer\"],[17,1],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[30,2,[\"title\"]]],[13],[1,\"\\n  \"],[10,2],[12],[1,[30,2,[\"subTitle\"]]],[13],[1,\"\\n\"],[41,[30,2,[\"buttonData\"]],[[[1,\"    \"],[10,3],[15,6,[30,2,[\"redirect\"]]],[12],[1,\"\\n      \"],[11,\"button\"],[4,[38,1],[\"click\",[52,[30,3,[\"gtagEventName\"]],[28,[37,2],[[30,3,[\"gtagEventName\"]]],null],[28,[37,3],[[28,[37,4],null,null]],null]]],null],[12],[1,\"\\n        \"],[1,[30,2,[\"buttonData\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"@heroSection\",\"@data\"],false,[\"if\",\"on\",\"fired-gtag-event\",\"fn\",\"noop\"]]",
    "moduleName": "primathon-ember-web/components/ai-development/hero-section.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});