define("primathon-ember-web/constants/homepage/cta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CTA_SECTION_DATA = void 0;
  const CTA_SECTION_DATA = {
    title: 'Are you Confused About the Right AI Development Path?',
    description: 'Allow our team of AI Development Experts to lead your project, ensuring you make confident and informed decisions along the way.',
    ctaText1: 'Get Free Consultation',
    redirect1: '/contact'
  };
  _exports.CTA_SECTION_DATA = CTA_SECTION_DATA;
});