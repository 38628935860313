define("primathon-ember-web/components/pages/technologies/node-js/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Header />
  <div class='app-container'>
    <Ai-development::Hero-section class="node-js-heroSectionContainer" @heroSection={{@data.nodeJsData.heroSection}} />
    <Ai-development::Introduction @intro={{@data.nodeJsData.serviceByPrimathon}} @imgStyle='w-[500px] h-[400px]'/>
    <Chatgpt::Getting-started @gettingStarted={{@data.nodeJsData.tailoredNodeJsSolution}} />
    <Chatgpt::Benefits @benefits={{@data.nodeJsData.whyChoose}}/>
    <Ai-development::Introduction @intro={{@data.nodeJsData.approachToNodeJsDevelopment}} @imgStyle='w-[500px] h-[480px]'/>
    <Technologies::CtaSection @ctaSection={{@data.nodeJsData.elevateYourWebDevelopment}} />
    <Homepage::Testimonial
      @title={{@data.testinomialData.title}}
      @description={{@data.testinomialData.description}}
      @data={{@data.testinomialData.data}}
    />
    <Chatgpt::Getting-started @gettingStarted={{@data.nodeJsData.advantageOfNodeJsDevelopment}} />
    <Technologies::CtaSection @ctaSection={{@data.nodeJsData.startYourNodeJsProject}} />
    <Ai-development::Introduction @intro={{@data.nodeJsData.driveInnovation}} @imgStyle='w-[500px] h-[400px]'/>
    <Footer />
  </div>
  */
  {
    "id": "dNgI7kT5",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"node-js-heroSectionContainer\"]],[[\"@heroSection\"],[[30,1,[\"nodeJsData\",\"heroSection\"]]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@intro\",\"@imgStyle\"],[[30,1,[\"nodeJsData\",\"serviceByPrimathon\"]],\"w-[500px] h-[400px]\"]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@gettingStarted\"],[[30,1,[\"nodeJsData\",\"tailoredNodeJsSolution\"]]]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@benefits\"],[[30,1,[\"nodeJsData\",\"whyChoose\"]]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@intro\",\"@imgStyle\"],[[30,1,[\"nodeJsData\",\"approachToNodeJsDevelopment\"]],\"w-[500px] h-[480px]\"]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@ctaSection\"],[[30,1,[\"nodeJsData\",\"elevateYourWebDevelopment\"]]]],null],[1,\"\\n  \"],[8,[39,6],null,[[\"@title\",\"@description\",\"@data\"],[[30,1,[\"testinomialData\",\"title\"]],[30,1,[\"testinomialData\",\"description\"]],[30,1,[\"testinomialData\",\"data\"]]]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@gettingStarted\"],[[30,1,[\"nodeJsData\",\"advantageOfNodeJsDevelopment\"]]]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@ctaSection\"],[[30,1,[\"nodeJsData\",\"startYourNodeJsProject\"]]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@intro\",\"@imgStyle\"],[[30,1,[\"nodeJsData\",\"driveInnovation\"]],\"w-[500px] h-[400px]\"]],null],[1,\"\\n  \"],[8,[39,7],null,null,null],[1,\"\\n\"],[13]],[\"@data\"],false,[\"header\",\"ai-development/hero-section\",\"ai-development/introduction\",\"chatgpt/getting-started\",\"chatgpt/benefits\",\"technologies/cta-section\",\"homepage/testimonial\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/technologies/node-js/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});