define("primathon-ember-web/components/about-us/hero-section/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="about-us-hero">
    <div class="filter">
      <div class="responsive-container">
        <h1>
          A team of tech admirers with Leadership of IIT Delhi helping your business grow astronomically!
        </h1>
        <p>
          We help hundreds of companies build and scale their engineering teams, and deliver web application development services with the help of our tech experts.
        </p>
      </div>
    </div>
    <div class="about-us-yellow-circle"></div>
  </section>
  */
  {
    "id": "UbiWljWK",
    "block": "[[[10,\"section\"],[14,0,\"about-us-hero\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"filter\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"responsive-container\"],[12],[1,\"\\n      \"],[10,\"h1\"],[12],[1,\"\\n        A team of tech admirers with Leadership of IIT Delhi helping your business grow astronomically!\\n      \"],[13],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        We help hundreds of companies build and scale their engineering teams, and deliver web application development services with the help of our tech experts.\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"about-us-yellow-circle\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "primathon-ember-web/components/about-us/hero-section/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});