define("primathon-ember-web/constants/technologies/devops/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEVOPS_ENGINEER = void 0;
  const DEVOPS_ENGINEER = {
    heroSection: {
      title1: 'DevOps',
      gradientTitle: 'Consulting and Development',
      title2: 'Service',
      subtitle: 'Enhance your software development process with elite DevOps consulting services.',
      description: '',
      listItems: [{
        id: 1,
        text: '55% overall workflow boost'
      }, {
        id: 2,
        text: '21% time to market reduction'
      }, {
        id: 3,
        text: 'Quick Onboarding Procedure'
      }, {
        id: 4,
        text: 'Competitive pricing models with flexible contracts'
      }, {
        id: 5,
        text: 'Clear and Easy Communication'
      }],
      ctaText1: 'Book a Call',
      ctaText2: 'Get Free Consultation',
      redirect: '/contact',
      imageSrc: '/assets/devops/devops-technology.png',
      imageAlt: 'Hire Devops'
    },
    tailoredNodeJsSolution: {
      title: 'Comprehensive DevOps Consulting Services Provided by Primathon',
      // subTitle: 'Node.js Solutions',
      cardData: [{
        id: '1',
        cardTitle: 'DevOps Scope Evaluation',
        imageSrc: '/assets/node-js/development.svg',
        content: 'For automation reasons, we create an evaluation report with action points. The very first step of our DevOps consulting services includes evaluating your DevOps procedures, audit your current infrastructure, examine the development pipeline, make a list of unnecessary chores, and determine which tools are best for you.'
      }, {
        id: '2',
        cardTitle: 'DevOps Consultation for Implementation',
        imageSrc: '/assets/node-js/architecture.svg',
        content: 'Throughout the lifecycle of your application, our DevOps consulting services are intended to assist you in maximizing the potential of connected and automated development, testing, and deployment. In order to do this, we deploy Infrastructure as Code (IaC) practices, containerize app modules, establish reliable CI/CD pipelines, add test automation, and automate application monitoring.'
      }, {
        id: '3',
        cardTitle: 'Tech Consulting for DevOps',
        imageSrc: '/assets/node-js/app-development.svg',
        content: 'Work with our specialists to navigate the complicated world of DevOps tools. Our DevOps consulting services help you select, set up, and configure the appropriate technology stack for a productive DevOps workspace. Additionally, we make sure your staff has the know-how to properly utilize these technologies.'
      }, {
        id: '4',
        cardTitle: 'Consulting for DevOps Project Recovery',
        imageSrc: '/assets/node-js/api-integration.svg',
        content: 'If you face any glitches during the implementation process, our skilled recovery team steps in to fix technical problems (such as CI/CD configuration failures), improve automated testing coverage, close knowledge gaps in DevOps tools and processes, and manage conflicts between the development, testing, and operations teams.'
      }, {
        id: '5',
        cardTitle: 'Comprehensive DevOps Solutions',
        imageSrc: '/assets/node-js/microservices.svg',
        content: ' Being a leading DevOps development company, we offer CI & CD as well as a continuous delivery pipeline. Our DevOps consultants and service team take care of management, continuous deployment, replica environments, new server setup, change management, and continual performance optimization.'
      }]
    },
    whyChoosePrimathon: {
      title: 'Why Choose Primathon as Your DevOps Development Company?',
      description: 'At Primathon, our DevOps developers excel in both expertise and dedication, boasting over 7 years of industry experience. Our DevOps consulting services can take your project on an innovative journey. Connect with our DevOps consultants today as our team ensures seamless development along with high-performing applications with flawless implementation.',
      listItems: [{
        id: 1,
        text: 'Experience 1.5X Faster Delivery'
      }, {
        id: 2,
        text: 'Work at Your Time Zone'
      }, {
        id: 3,
        text: 'Hassle-free communication'
      }, {
        id: 4,
        text: '24/7 Support'
      }],
      service1: {
        number: '6+',
        text: 'Years of business'
      },
      service2: {
        number: '100+',
        text: 'Software Developers'
      },
      service3: {
        number: '150+',
        text: 'Project Delivered'
      },
      service4: {
        number: '100+',
        text: 'Happy Client'
      }
    },
    devopsImplementation: {
      title: 'DevOps Implementation Steps Followed by Primathon’s DevOps Consultants',
      subTitle: 'DevOps Solutions',
      cardData: [{
        id: '1',
        imageSrc: '/assets/search-gpt-solution/document-processing.svg',
        cardTitle: 'Assessment & Planning',
        cardData: 'Our DevOps consultants are experts in developing customized DevOps solutions, utilizing reliable planning tools, and establishing quantifiable KPIs. For your startup application, we envision a clear road map that will enable quick onboarding and smooth integration with your current setup.'
      }, {
        id: '2',
        cardTitle: 'Establishment of a Pilot Framework',
        imageSrc: '/assets/search-gpt-solution/deep-learning.svg',
        cardData: 'In order to build a robust tool stack, we integrate and enhance already available solutions. This improves software delivery agility, maximizes operational performance, and optimizes cloud infrastructure.'
      }, {
        id: '3',
        cardTitle: 'Process Implementation',
        imageSrc: '/assets/search-gpt-solution/language-processing-capabilities.svg',
        cardData: 'Primathon’s DevOps services include careful planning, precise analysis, effective building, and smooth automation. DevOps principles are implemented more quickly as a result, guaranteeing quicker deployment and better operational procedures.'
      }, {
        id: '4',
        cardTitle: 'CI/CD Pipeline',
        imageSrc: '/assets/search-gpt-solution/data-parcing.svg',
        cardData: 'To effectively bridge the DevOps divide, we use automated deployment methodologies in conjunction with continuous development, integration, and quality assurance testing. This guarantees quick delivery of excellent software with less downtime.'
      }, {
        id: '5',
        cardTitle: 'Process Automation',
        imageSrc: '/assets/search-gpt-solution/continuous-learning.svg',
        cardData: 'Modern automation tools are used by our DevOps consultants to optimize the whole development pipeline. We improve efficiency and dependability at every stage of your development lifecycle, from automated builds to thorough quality assurance and testing.'
      }, {
        id: '6',
        cardTitle: 'Security Integration',
        imageSrc: '/assets/search-gpt-solution/data-parcing.svg',
        cardData: 'It is critical to provide end-to-end security integration. We implement cutting-edge security solutions to defend your entire DevOps environment, shielding your data and apps from harm.'
      }]
    },
    clientChoosingUs: {
      title: 'Why are Clients Choosing Primathon’s DevOps Consulting Services?',
      // subTitle: 'DevOps Solutions',
      cardData: [{
        id: '1',
        cardTitle: 'Customized Technical Expertise',
        content: 'Being a leading DevOps development company, we, at Primathon, have qualified and certified senior DevOps engineers who boast years of experience. With the right tech stack, our team will offer premium DevOps consulting services, and the right development path to every client.'
      }, {
        id: '2',
        cardTitle: 'Expertise in Cutting-Edge DevOps Services',
        content: 'Our DevOps consultants guarantee excellent DevOps services that satisfy contemporary business needs since they have in-depth knowledge and practical experience of the newest tools and technologies.'
      }, {
        id: '3',
        cardTitle: 'Tailored DevOps Consulting Services',
        content: 'Being recognised as the top-notch DevOps development company, we provide customized DevOps consultation that aligns with your specific business goals, ensuring that our solutions are tailored to your unique requirements.'
      }, {
        id: '4',
        cardTitle: 'Proven Track Record',
        content: 'Primathon is a top DevOps development company with a track record of successful implementation of DevOps services, assisting clients in achieving quicker time-to-market and higher-quality release cycles.'
      }, {
        id: '5',
        cardTitle: 'Comprehensive DevOps Solutions',
        content: 'From strategy and assessment to implementation and ongoing improvement, our DevOps consulting services cover the entire spectrum of the DevOps lifecycle.'
      }, {
        id: '6',
        cardTitle: 'Collaborative Approach',
        content: 'Our DevOps consultants tightly collaborate with your team to improve communication and guarantee the seamless incorporation of DevOps principles. '
      }, {
        id: '7',
        cardTitle: 'Scalable and Resilient Infrastructure',
        content: 'As a leading DevOps development company, we create and deploy scalable and robust infrastructure solutions that guarantee the stability and growth of your systems.'
      }, {
        id: '8',
        cardTitle: 'Client-Centric Approach',
        content: 'Primathon puts the needs of its clients first, providing adaptable and customer-focused DevOps consulting services that drive business success.'
      }]
    },
    boostYourDevOpsDevelopment: {
      title: 'Enhance Your Project with Our DevOps Consulting Services',
      description: 'With years of expertise and a commitment to innovation, we deliver bespoke solutions tailored to your unique requirements. Partner with us and experience the difference.',
      ctaText: 'Get DevOps Consultation Today',
      ctaRoute: 'services.staff-augmentation.hire-react-js-developers'
    },
    faqs: {
      title: 'FAQs',
      cardData: [{
        id: 1,
        question: 'What is the need of DevOps consulting services?',
        answer: ['In order to integrate and optimize development and operations processes, DevOps consulting services provide knowledgeable direction and assistance. They accelerate the process of software delivery and increase product quality by streamlining workflows, automating tasks, and fostering better team cooperation.']
      }, {
        id: 2,
        question: 'What are the major benefits of DevOps consulting services?',
        answer: ['DevOps services can help your company by boosting teamwork, decreasing time to market, increasing scalability, improving dependability, and promoting an innovative and continuous development culture.']
      }, {
        id: 3,
        question: 'Are there any important factors to consider when choosing a DevOps consulting company?',
        answer: ['Consider their track record of successful implementations, their understanding of your unique business requirements, their approach to collaboration and communication, and their dedication to ongoing learning and improvement.']
      }, {
        id: 4,
        question: 'What challenges DevOps consulting services can help address?',
        answer: ['DevOps consulting services can help address challenges such as inefficient release cycles, manual and error-prone deployment processes, lack of collaboration between development and operations teams, difficulty in scaling infrastructure, and security vulnerabilities in the DevOps pipeline.']
      }, {
        id: 5,
        question: 'How do DevOps consultants approach implementation in my organization?',
        answer: ['DevOps experts start by carefully evaluating your present infrastructure and procedures. After that, they design a personalized implementation strategy that could incorporate workflow automation, Infrastructure as Code (IaC) adoption, CI/CD pipeline setup, monitoring and logging solutions, and security measure integration. They collaborate closely with your teams to guarantee a seamless adoption and offer continuing assistance and training as required.']
      }]
    }
  };
  _exports.DEVOPS_ENGINEER = DEVOPS_ENGINEER;
});