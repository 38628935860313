define("primathon-ember-web/services/api", ["exports", "fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ApiService extends Ember.Service {
    constructor() {
      super(...arguments);

      _defineProperty(this, "_API_HOST", 'https://ks389g4yk0.execute-api.us-east-1.amazonaws.com/dev');
    }

    async request(url, data) {
      const res = await (0, _fetch.default)(`${this._API_HOST}${url}`, data);

      if (!res.ok) {
        throw res;
      }

      return await res.json();
    }

    requestOptionDefaults() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let headers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...headers
        },
        body: JSON.stringify(data)
      };
    }

    async sendContactMail(payload) {
      return await this.request(`/contact`, this.requestOptionDefaults(payload));
    }

    async sendCareerMail(payload) {
      return await this.request(`/career`, this.requestOptionDefaults(payload));
    }

  }

  _exports.default = ApiService;
});