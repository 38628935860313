define("primathon-ember-web/components/container/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="container-fluid" ...attributes>
    <section class="inner-hero hero-wrap">
      <div class="container">
        {{yield}}
      </div>
    </section>
  </div>
  */
  {
    "id": "yzB78dGd",
    "block": "[[[11,0],[24,0,\"container-fluid\"],[17,1],[12],[1,\"\\n  \"],[10,\"section\"],[14,0,\"inner-hero hero-wrap\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "primathon-ember-web/components/container/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});