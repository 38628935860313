define("primathon-ember-web/components/services/bullets-list/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section
    class='max-w-[1400px] px-6 py-10 sm:p-10 md:p-20 mx-auto flex flex-col-reverse md:flex-row items-center justify-between gap-x-6 gap-y-12'
  >
    <div class='md:max-w-[450px] lg:max-w-[750px]'>
      <h2 class='text-2xl font-medium max-w-[750px] text-center md:text-left'>{{@data.title}}</h2>
      {{#if @data.subtitle}}
        <p class='text-lg font-medium text-[#198cca] mt-4 text-center md:text-left'>{{@data.subtitle}}</p>
      {{/if}}
      {{#if @data.description}}
        <p class='text-gray-500 mt-4 text-justify'>{{@data.description}}</p>
      {{/if}}
      <div>
        <ul class='bullet-list-ul mt-6'>
          {{#each @data.listItems as |item|}}
            <li key={{item.id}}>
              <p> {{item.text}}</p>
            </li>
          {{/each}}
        </ul>
      </div>
    </div>
    <div class='{{if @isImgShownInMobile "block" "hidden"}} md:block' style='flex-shrink: 0;'>
      <Common::AppImage @src={{@data.imageSrc}}
        class='{{if @imgStyle @imgStyle "w-[350px] h-[350px]"}}'
        alt={{@data.alt}}
        title={{@data.alt}}
      />
    </div>
  </section>
  */
  {
    "id": "NGQeh4SI",
    "block": "[[[10,\"section\"],[14,0,\"max-w-[1400px] px-6 py-10 sm:p-10 md:p-20 mx-auto flex flex-col-reverse md:flex-row items-center justify-between gap-x-6 gap-y-12\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"md:max-w-[450px] lg:max-w-[750px]\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"text-2xl font-medium max-w-[750px] text-center md:text-left\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"subtitle\"]],[[[1,\"      \"],[10,2],[14,0,\"text-lg font-medium text-[#198cca] mt-4 text-center md:text-left\"],[12],[1,[30,1,[\"subtitle\"]]],[13],[1,\"\\n\"]],[]],null],[41,[30,1,[\"description\"]],[[[1,\"      \"],[10,2],[14,0,\"text-gray-500 mt-4 text-justify\"],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"bullet-list-ul mt-6\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"listItems\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[15,\"key\",[30,2,[\"id\"]]],[12],[1,\"\\n            \"],[10,2],[12],[1,\" \"],[1,[30,2,[\"text\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[52,[30,3],\"block\",\"hidden\"],\" md:block\"]]],[14,5,\"flex-shrink: 0;\"],[12],[1,\"\\n    \"],[8,[39,3],[[16,0,[29,[[52,[30,4],[30,4],\"w-[350px] h-[350px]\"]]]],[16,\"alt\",[30,1,[\"alt\"]]],[16,\"title\",[30,1,[\"alt\"]]]],[[\"@src\"],[[30,1,[\"imageSrc\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@data\",\"item\",\"@isImgShownInMobile\",\"@imgStyle\"],false,[\"if\",\"each\",\"-track-array\",\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/services/bullets-list/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});