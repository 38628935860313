define("primathon-ember-web/components/case-study-blog/colored-arrow-list/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='mt-16 md:mt-32 mb-12 px-6 sm:px-12'>
    <p class='text-center text-3xl md:text-4xl font-medium mb-10 md:mb-16'>{{@data.title}}</p>
    <div class='flex flex-wrap justify-center gap-10 flex-row mt-4 md:pl-10'>
      {{#each @data.data as |item|}}
        <div class='w-[280px]'>
          <div class='relative'>
            <Common::AppImage @src='{{item.image}}' alt='arrow-image' />
            <h3
              class='text-xl font-semibold absolute bottom-[44px] left-[10px] md:text-lg text-white'
            >{{item.subTitle}}</h3>
          </div>
          <p class='text-gray-500 md:mt-5 mt-2.5 text-sm md:text-base'>{{item.description}}</p>
        </div>
      {{/each}}
    </div>
  </section>
  */
  {
    "id": "MssOfLB9",
    "block": "[[[10,\"section\"],[14,0,\"mt-16 md:mt-32 mb-12 px-6 sm:px-12\"],[12],[1,\"\\n  \"],[10,2],[14,0,\"text-center text-3xl md:text-4xl font-medium mb-10 md:mb-16\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-wrap justify-center gap-10 flex-row mt-4 md:pl-10\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"data\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"w-[280px]\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"relative\"],[12],[1,\"\\n          \"],[8,[39,2],[[24,\"alt\",\"arrow-image\"]],[[\"@src\"],[[29,[[30,2,[\"image\"]]]]]],null],[1,\"\\n          \"],[10,\"h3\"],[14,0,\"text-xl font-semibold absolute bottom-[44px] left-[10px] md:text-lg text-white\"],[12],[1,[30,2,[\"subTitle\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,2],[14,0,\"text-gray-500 md:mt-5 mt-2.5 text-sm md:text-base\"],[12],[1,[30,2,[\"description\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@data\",\"item\"],false,[\"each\",\"-track-array\",\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/case-study-blog/colored-arrow-list/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});