define("primathon-ember-web/modifiers/timeout-callback", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function timeoutCallback(element, _ref) {
    let [callback, time] = _ref;
    let timeOut = null;

    if (element && callback) {
      timeOut = setTimeout(() => callback(element), time);
    }

    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  });

  _exports.default = _default;
});