define("primathon-ember-web/components/case-studies/post/card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <LinkTo @route='case-studies.case-study' @model={{@data.url}}>
    <div class='flex flex-col gap-y-10 items-center max-w-[370px]'>
      <Common::AppImage @src={{@data.poster}} loading='lazy' alt={{@data.title}} title={{@data.title}} />
      <div class=''>
        <h3 class='text-2xl text-white'>
          {{@data.title}}
        </h3>
        <p class='text-sm text-white my-5'>
          {{@data.description}}
        </p>
        <div class='flex flex-wrap gap-4'>
          {{#each @data.tags as |tag|}}
            <div class='py-1 px-2 border border-solid rounded border-[#31426F] bg-[#31426F] text-white text-xs'>
              {{tag}}
            </div>
          {{/each}}
        </div>
      </div>
    </div>
  </LinkTo>
  */
  {
    "id": "sy7Mlm5h",
    "block": "[[[8,[39,0],null,[[\"@route\",\"@model\"],[\"case-studies.case-study\",[30,1,[\"url\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col gap-y-10 items-center max-w-[370px]\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,\"loading\",\"lazy\"],[16,\"alt\",[30,1,[\"title\"]]],[16,\"title\",[30,1,[\"title\"]]]],[[\"@src\"],[[30,1,[\"poster\"]]]],null],[1,\"\\n    \"],[10,0],[14,0,\"\"],[12],[1,\"\\n      \"],[10,\"h3\"],[14,0,\"text-2xl text-white\"],[12],[1,\"\\n        \"],[1,[30,1,[\"title\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,0,\"text-sm text-white my-5\"],[12],[1,\"\\n        \"],[1,[30,1,[\"description\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"flex flex-wrap gap-4\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1,[\"tags\"]]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"py-1 px-2 border border-solid rounded border-[#31426F] bg-[#31426F] text-white text-xs\"],[12],[1,\"\\n            \"],[1,[30,2]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@data\",\"tag\"],false,[\"link-to\",\"common/app-image\",\"each\",\"-track-array\"]]",
    "moduleName": "primathon-ember-web/components/case-studies/post/card.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});