define("primathon-ember-web/constants/event-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TECHNOLOGIES = _exports.SOLUTIONS = _exports.REACT_JS_TECH_BUTTON_CLICK = _exports.MENU_SERVICES_CLICK = _exports.MENU_HIRE_US_CLICK = _exports.MENU_CONTACT_CLICK = _exports.MENU_CASE_STUDIES_CLICK = _exports.MENU_CAREERS_CLICK = _exports.MENU_BLOG_CLICK = _exports.MENU_ABOUT_CLICK = _exports.HOME_VISITED = _exports.HOME_VIEW_MORE_BLOG_CLICK = _exports.HOME_SERVICE_CARD_CLICK = _exports.HOME_POSTER_PLAY_VIDEO = _exports.HOME_POSTER_GET_IN_TOUCH = _exports.HOME_BLOG_CARD_CLICK = _exports.HIRE_REACT_JS_BUTTON_CLICK = _exports.HIRE_DEVOPS_BUTTON_CLICK = _exports.FOOTER_GET_IN_TOUCH_CLICK = _exports.CONTACT_SEND_MESSAGE_CLICK = _exports.CONTACT_SCHEDULE_MEETING_CLICK = _exports.CONTACT_FORM_PAYLOAD_VALID = _exports.CONTACT_FORM_PAYLOAD_INVALID = _exports.CONTACT_CAREER_CLICK = _exports.CONTACT_API_SUCCESS = _exports.CONTACT_API_ERROR = _exports.CAREER_SEND_MESSAGE_CLICK = _exports.CAREER_FORM_PAYLOAD_VALID = _exports.CAREER_FORM_PAYLOAD_INVALID = _exports.CAREER_API_SUCCESS = _exports.CAREER_API_ERROR = void 0;
  // Menu Event
  const MENU_BLOG_CLICK = 'menu_blog_click';
  _exports.MENU_BLOG_CLICK = MENU_BLOG_CLICK;
  const MENU_CONTACT_CLICK = 'menu_contact_click';
  _exports.MENU_CONTACT_CLICK = MENU_CONTACT_CLICK;
  const MENU_HIRE_US_CLICK = 'menu_hire_us_click';
  _exports.MENU_HIRE_US_CLICK = MENU_HIRE_US_CLICK;
  const MENU_ABOUT_CLICK = 'menu_about_click';
  _exports.MENU_ABOUT_CLICK = MENU_ABOUT_CLICK;
  const MENU_SERVICES_CLICK = 'menu_services_click';
  _exports.MENU_SERVICES_CLICK = MENU_SERVICES_CLICK;
  const MENU_CASE_STUDIES_CLICK = 'menu_case_studies_click';
  _exports.MENU_CASE_STUDIES_CLICK = MENU_CASE_STUDIES_CLICK;
  const MENU_CAREERS_CLICK = 'menu_careers_click';
  _exports.MENU_CAREERS_CLICK = MENU_CAREERS_CLICK;
  const TECHNOLOGIES = 'menu_technologies_click';
  _exports.TECHNOLOGIES = TECHNOLOGIES;
  const SOLUTIONS = 'menu_solutions_click'; // Homepage Event

  _exports.SOLUTIONS = SOLUTIONS;
  const HOME_VISITED = 'home_visited';
  _exports.HOME_VISITED = HOME_VISITED;
  const HOME_POSTER_GET_IN_TOUCH = 'home_poster_get_in_touch';
  _exports.HOME_POSTER_GET_IN_TOUCH = HOME_POSTER_GET_IN_TOUCH;
  const HOME_POSTER_PLAY_VIDEO = 'home_poster_play_video';
  _exports.HOME_POSTER_PLAY_VIDEO = HOME_POSTER_PLAY_VIDEO;
  const HOME_SERVICE_CARD_CLICK = 'home_service_card_click';
  _exports.HOME_SERVICE_CARD_CLICK = HOME_SERVICE_CARD_CLICK;
  const HOME_BLOG_CARD_CLICK = 'home_blog_card_click';
  _exports.HOME_BLOG_CARD_CLICK = HOME_BLOG_CARD_CLICK;
  const HOME_VIEW_MORE_BLOG_CLICK = 'home_view_more_blog_click'; // Footer Event

  _exports.HOME_VIEW_MORE_BLOG_CLICK = HOME_VIEW_MORE_BLOG_CLICK;
  const FOOTER_GET_IN_TOUCH_CLICK = 'footer_get_in_touch_click'; // Contact Page

  _exports.FOOTER_GET_IN_TOUCH_CLICK = FOOTER_GET_IN_TOUCH_CLICK;
  const CONTACT_SEND_MESSAGE_CLICK = 'contact_send_message_click';
  _exports.CONTACT_SEND_MESSAGE_CLICK = CONTACT_SEND_MESSAGE_CLICK;
  const CONTACT_FORM_PAYLOAD_VALID = 'contact_form_payload_valid';
  _exports.CONTACT_FORM_PAYLOAD_VALID = CONTACT_FORM_PAYLOAD_VALID;
  const CONTACT_FORM_PAYLOAD_INVALID = 'contact_form_payload_invalid';
  _exports.CONTACT_FORM_PAYLOAD_INVALID = CONTACT_FORM_PAYLOAD_INVALID;
  const CONTACT_API_SUCCESS = 'contact_api_success';
  _exports.CONTACT_API_SUCCESS = CONTACT_API_SUCCESS;
  const CONTACT_API_ERROR = 'contact_api_error';
  _exports.CONTACT_API_ERROR = CONTACT_API_ERROR;
  const CONTACT_CAREER_CLICK = 'contact_career_click';
  _exports.CONTACT_CAREER_CLICK = CONTACT_CAREER_CLICK;
  const CONTACT_SCHEDULE_MEETING_CLICK = 'contact_schedule_meeting_click'; // Career Page

  _exports.CONTACT_SCHEDULE_MEETING_CLICK = CONTACT_SCHEDULE_MEETING_CLICK;
  const CAREER_SEND_MESSAGE_CLICK = 'CAREER_send_message_click';
  _exports.CAREER_SEND_MESSAGE_CLICK = CAREER_SEND_MESSAGE_CLICK;
  const CAREER_FORM_PAYLOAD_VALID = 'CAREER_form_payload_valid';
  _exports.CAREER_FORM_PAYLOAD_VALID = CAREER_FORM_PAYLOAD_VALID;
  const CAREER_FORM_PAYLOAD_INVALID = 'CAREER_form_payload_invalid';
  _exports.CAREER_FORM_PAYLOAD_INVALID = CAREER_FORM_PAYLOAD_INVALID;
  const CAREER_API_SUCCESS = 'CAREER_api_success';
  _exports.CAREER_API_SUCCESS = CAREER_API_SUCCESS;
  const CAREER_API_ERROR = 'CAREER_api_error'; // Hire React js developer

  _exports.CAREER_API_ERROR = CAREER_API_ERROR;
  const HIRE_REACT_JS_BUTTON_CLICK = 'hire_react_js_button_click'; //Hire DevOps developer

  _exports.HIRE_REACT_JS_BUTTON_CLICK = HIRE_REACT_JS_BUTTON_CLICK;
  const HIRE_DEVOPS_BUTTON_CLICK = 'hire_devops_button_click'; //React js technology

  _exports.HIRE_DEVOPS_BUTTON_CLICK = HIRE_DEVOPS_BUTTON_CLICK;
  const REACT_JS_TECH_BUTTON_CLICK = 'react_js_button_click';
  _exports.REACT_JS_TECH_BUTTON_CLICK = REACT_JS_TECH_BUTTON_CLICK;
});