define("primathon-ember-web/components/contact/primathon-performance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class='px-6 sm:px-12 md:px-20 py-12 max-w-[1600px] text-[#1A284D] flex justify-between gap-x-8 mx-auto overflow-x-auto'
  >
    <div class='min-w-[120px]'>
      <p class='text-5xl font-semibold'>40+</p>
      <p class='font-medium text-lg mb-0'>Presence in countries</p>
    </div>
    <div>
      <p class='text-5xl font-semibold'>100+</p>
      <p class='font-medium text-lg mb-0'>Software Developers</p>
    </div>
    <div>
      <p class='text-5xl font-semibold'>150+</p>
      <p class='font-medium text-lg mb-0'>Projects Delivered</p>
    </div>
    <div>
      <p class='text-5xl font-semibold'>100+</p>
      <p class='font-medium text-lg mb-0'>Happy Clients</p>
    </div>
  </div>
  */
  {
    "id": "l5EoVSYl",
    "block": "[[[10,0],[14,0,\"px-6 sm:px-12 md:px-20 py-12 max-w-[1600px] text-[#1A284D] flex justify-between gap-x-8 mx-auto overflow-x-auto\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"min-w-[120px]\"],[12],[1,\"\\n    \"],[10,2],[14,0,\"text-5xl font-semibold\"],[12],[1,\"40+\"],[13],[1,\"\\n    \"],[10,2],[14,0,\"font-medium text-lg mb-0\"],[12],[1,\"Presence in countries\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,2],[14,0,\"text-5xl font-semibold\"],[12],[1,\"100+\"],[13],[1,\"\\n    \"],[10,2],[14,0,\"font-medium text-lg mb-0\"],[12],[1,\"Software Developers\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,2],[14,0,\"text-5xl font-semibold\"],[12],[1,\"150+\"],[13],[1,\"\\n    \"],[10,2],[14,0,\"font-medium text-lg mb-0\"],[12],[1,\"Projects Delivered\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,2],[14,0,\"text-5xl font-semibold\"],[12],[1,\"100+\"],[13],[1,\"\\n    \"],[10,2],[14,0,\"font-medium text-lg mb-0\"],[12],[1,\"Happy Clients\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "primathon-ember-web/components/contact/primathon-performance.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});