define("primathon-ember-web/components/case-study-blog/hero-section-company/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="blogHeroImage" style={{@heroData.backgroundStyle}}>
    <div  class='hero-section-csblog' style={{@heroData.maskStyle}}>
      <Common::AppImage @src={{@heroData.companyLogo}} alt='company Logo' />
      <p class='companyDescription'>
        {{@heroData.description}}
      </p>
    </div>
  </section>
  */
  {
    "id": "lUj5TuUj",
    "block": "[[[10,\"section\"],[14,0,\"blogHeroImage\"],[15,5,[30,1,[\"backgroundStyle\"]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"hero-section-csblog\"],[15,5,[30,1,[\"maskStyle\"]]],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"alt\",\"company Logo\"]],[[\"@src\"],[[30,1,[\"companyLogo\"]]]],null],[1,\"\\n    \"],[10,2],[14,0,\"companyDescription\"],[12],[1,\"\\n      \"],[1,[30,1,[\"description\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@heroData\"],false,[\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/case-study-blog/hero-section-company/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});