define("primathon-ember-web/components/case-study-blog/hero-section/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section
    class='bg-center bg-cover bg-no-repeat'
    style="background-image: url('/assets/bloom-case-study/case-study-hero-img.png');"
  >
    <div class='pb-28 pt-40 md:pb-40 md:pt-44 px-6 sm:px-12'>
      <h1 class='font-semibold text-6xl sm:text-8xl text-white text-center mb-10 sm:mb-16'>
        bloom
      </h1>
      <p class='text-white text-lg sm:text-xl max-w-[710px] mx-auto text-center'>
        Developed a High-Performance Comprehensive Booking Engine for the Top Hospitality Chain in India
      </p>
    </div>
  </section>
  */
  {
    "id": "6qok4Iwg",
    "block": "[[[10,\"section\"],[14,0,\"bg-center bg-cover bg-no-repeat\"],[14,5,\"background-image: url('/assets/bloom-case-study/case-study-hero-img.png');\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"pb-28 pt-40 md:pb-40 md:pt-44 px-6 sm:px-12\"],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"font-semibold text-6xl sm:text-8xl text-white text-center mb-10 sm:mb-16\"],[12],[1,\"\\n      bloom\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"text-white text-lg sm:text-xl max-w-[710px] mx-auto text-center\"],[12],[1,\"\\n      Developed a High-Performance Comprehensive Booking Engine for the Top Hospitality Chain in India\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "primathon-ember-web/components/case-study-blog/hero-section/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});