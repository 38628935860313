define("primathon-ember-web/components/homepage/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <footer class='site-footer large-footer footer-wrapper'>
    <section class='container-fluid post-footer-wrap'>
      <div class='row'>
        <div class='container'>
          <div class='row copy-links'>
            <div class='col-md-6 col-sm-6 col-xs-7 copyright-footer'>
              <ul>
                <li>© Copyright 2018 - 2022 Primathon</li>
                {{! <li><a href='https://primathon.com/privacy'>Privacy Policy</a></li>
                <li><a href='https://primathon.com/tos'>Website Terms</a></li> }}
              </ul>
            </div>
            <div class='col-md-6 col-sm-6 col-xs-5 social-footer'>
              <ul class='social-list'>
                {{! <li>
                  <a href='#' target='_blank' rel='noopener noreferrer' aria-label='facebook'><i
                      class='icon-facebook'
                      aria-hidden='true'
                    ></i></a>
                </li> }}
                {{! <li>
                  <a href='#' target='_blank' rel='noopener noreferrer' aria-label='instagram'><i
                      class='icon-instagram'
                      aria-hidden='true'
                    ></i></a>
                </li> }}
                {{! <li>
                  <a href='#' target='_blank' rel='noopener noreferrer' aria-label='youtube'><i
                      class='icon-youtube'
                      aria-hidden='true'
                    ></i></a>
                </li> }}
                <li>
                  <a
                    href='https://twitter.com/primathontech'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label='twitter'
                  ><i class='icon-twitter' aria-hidden='true'></i></a>
                </li>
                <li>
                  <a
                    href='https://www.linkedin.com/company/primathon'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label='linkedin'
                  ><i class='icon-linkedin' aria-hidden='true'></i></a>
                </li>
                {{! <li>
                  <a href='#' target='_blank' rel='noopener noreferrer' aria-label='github'><i
                      class='icon-github'
                      aria-hidden='true'
                    ></i></a>
                </li> }}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
  */
  {
    "id": "1QNk4FMy",
    "block": "[[[10,\"footer\"],[14,0,\"site-footer large-footer footer-wrapper\"],[12],[1,\"\\n  \"],[10,\"section\"],[14,0,\"container-fluid post-footer-wrap\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"row copy-links\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"col-md-6 col-sm-6 col-xs-7 copyright-footer\"],[12],[1,\"\\n            \"],[10,\"ul\"],[12],[1,\"\\n              \"],[10,\"li\"],[12],[1,\"© Copyright 2018 - 2022 Primathon\"],[13],[1,\"\\n\"],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"col-md-6 col-sm-6 col-xs-5 social-footer\"],[12],[1,\"\\n            \"],[10,\"ul\"],[14,0,\"social-list\"],[12],[1,\"\\n\"],[1,\"              \"],[10,\"li\"],[12],[1,\"\\n                \"],[10,3],[14,6,\"https://twitter.com/primathontech\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,\"aria-label\",\"twitter\"],[12],[10,\"i\"],[14,0,\"icon-twitter\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[1,\"\\n              \"],[13],[1,\"\\n              \"],[10,\"li\"],[12],[1,\"\\n                \"],[10,3],[14,6,\"https://www.linkedin.com/company/primathon\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,\"aria-label\",\"linkedin\"],[12],[10,\"i\"],[14,0,\"icon-linkedin\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[1,\"\\n              \"],[13],[1,\"\\n\"],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "primathon-ember-web/components/homepage/footer.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});