define("primathon-ember-web/components/services/hero-build-and-manage/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Services::HeroSection>
    <div class="services-staff-augmentation">
      <h1>
        Build and Manage
      </h1>
      <h2>
        Get the best
        <b>
          management
        </b>
        and
        <b>
          value driven technology
        </b>
        aiming at digitally transforming your business!
      </h2>
    </div>
  </Services::HeroSection>
  */
  {
    "id": "rfWxoV70",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"services-staff-augmentation\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      Build and Manage\\n    \"],[13],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"\\n      Get the best\\n      \"],[10,\"b\"],[12],[1,\"\\n        management\\n      \"],[13],[1,\"\\n      and\\n      \"],[10,\"b\"],[12],[1,\"\\n        value driven technology\\n      \"],[13],[1,\"\\n      aiming at digitally transforming your business!\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"services/hero-section\"]]",
    "moduleName": "primathon-ember-web/components/services/hero-build-and-manage/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});