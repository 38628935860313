define("primathon-ember-web/components/pages/emberjs/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Header />
  <div class='app-container'>
    <Emberjs::EmberHeroSection />
    <Services::BulletsList @data={{@data.emberData.whyChooseEmber}} @imgStyle='w-[425px] h-[410px]' />
    <Technologies::CtaSection @ctaSection={{@data.emberData.lookingToEmberJsDevelopment}} />
    <Chatgpt::Chatgptuse @useCases={{@data.emberData.emberJsDevelopmentServices}} />
    <Services::ArrowList @content={{@data.emberData.emberJsJourney}} @style='chatGpt-arrowList' />
    <Chatgpt::Chatgptuse @useCases={{@data.emberData.keySpecifications}} />
    <Chatgpt::Benefits @benefits={{@data.emberData.whyChoose}} />
    <Chatgpt::Getting-started @gettingStarted={{@data.emberData.customizedEmberJsServices}} />
    <Services::WhyHireSection @data={{@data.emberData.whyHire}} />
    <Homepage::Testimonial
      @title={{@data.testinomialData.title}}
      @description={{@data.testinomialData.description}}
      @data={{@data.testinomialData.data}}
    />
    <Chatgpt::Getting-started @gettingStarted={{@data.emberData.keyBenefitsOfEmberJSDevelopment}} />
    <Services::FaqList @title={{@data.emberData.faqs.title}} @data={{@data.emberData.faqs.cardData}} />
    <Footer />
  </div>
  */
  {
    "id": "Bx8Wka+x",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[8,[39,2],null,[[\"@data\",\"@imgStyle\"],[[30,1,[\"emberData\",\"whyChooseEmber\"]],\"w-[425px] h-[410px]\"]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@ctaSection\"],[[30,1,[\"emberData\",\"lookingToEmberJsDevelopment\"]]]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@useCases\"],[[30,1,[\"emberData\",\"emberJsDevelopmentServices\"]]]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@content\",\"@style\"],[[30,1,[\"emberData\",\"emberJsJourney\"]],\"chatGpt-arrowList\"]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@useCases\"],[[30,1,[\"emberData\",\"keySpecifications\"]]]],null],[1,\"\\n  \"],[8,[39,6],null,[[\"@benefits\"],[[30,1,[\"emberData\",\"whyChoose\"]]]],null],[1,\"\\n  \"],[8,[39,7],null,[[\"@gettingStarted\"],[[30,1,[\"emberData\",\"customizedEmberJsServices\"]]]],null],[1,\"\\n  \"],[8,[39,8],null,[[\"@data\"],[[30,1,[\"emberData\",\"whyHire\"]]]],null],[1,\"\\n  \"],[8,[39,9],null,[[\"@title\",\"@description\",\"@data\"],[[30,1,[\"testinomialData\",\"title\"]],[30,1,[\"testinomialData\",\"description\"]],[30,1,[\"testinomialData\",\"data\"]]]],null],[1,\"\\n  \"],[8,[39,7],null,[[\"@gettingStarted\"],[[30,1,[\"emberData\",\"keyBenefitsOfEmberJSDevelopment\"]]]],null],[1,\"\\n  \"],[8,[39,10],null,[[\"@title\",\"@data\"],[[30,1,[\"emberData\",\"faqs\",\"title\"]],[30,1,[\"emberData\",\"faqs\",\"cardData\"]]]],null],[1,\"\\n  \"],[8,[39,11],null,null,null],[1,\"\\n\"],[13]],[\"@data\"],false,[\"header\",\"emberjs/ember-hero-section\",\"services/bullets-list\",\"technologies/cta-section\",\"chatgpt/chatgptuse\",\"services/arrow-list\",\"chatgpt/benefits\",\"chatgpt/getting-started\",\"services/why-hire-section\",\"homepage/testimonial\",\"services/faq-list\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/emberjs/index.hbs",
    "isStrictMode": false
  });

  class PagesEmberjsIndexComponent extends _component.default {}

  _exports.default = PagesEmberjsIndexComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PagesEmberjsIndexComponent);
});