define("primathon-ember-web/components/pages/not-found/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Header @onHireUs={{this.onHireUs}} @type="3" />
  <div class="app-container">
    <section class="not-found">
      <Common::AppImage @src="/assets/svgs/404.svg" alt="page not found" />
      <header class="page-header">
        <h1 class="page-title">
          Oops! We can't find that page...
        </h1>
      </header>
      <div class="page-content">
        <h4>
          But we can totally find you the best developers in the world.
        </h4>
      </div>
    </section>
    <div class="footer-holder">
      <Footer />
    </div>
  </div>
  */
  {
    "id": "hmotQz5G",
    "block": "[[[8,[39,0],null,[[\"@onHireUs\",\"@type\"],[[30,0,[\"onHireUs\"]],\"3\"]],null],[1,\"\\n\"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[10,\"section\"],[14,0,\"not-found\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,\"alt\",\"page not found\"]],[[\"@src\"],[\"/assets/svgs/404.svg\"]],null],[1,\"\\n    \"],[10,\"header\"],[14,0,\"page-header\"],[12],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"page-title\"],[12],[1,\"\\n        Oops! We can't find that page...\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"page-content\"],[12],[1,\"\\n      \"],[10,\"h4\"],[12],[1,\"\\n        But we can totally find you the best developers in the world.\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"footer-holder\"],[12],[1,\"\\n    \"],[8,[39,2],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"header\",\"common/app-image\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/not-found/index.hbs",
    "isStrictMode": false
  });

  let PagesNotFoundIndexComponent = (_dec = Ember._action, (_class = class PagesNotFoundIndexComponent extends _component.default {
    onHireUs() {
      const elm = document.getElementById('contact-hero');

      if (elm) {
        elm.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onHireUs", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onHireUs"), _class.prototype)), _class));
  _exports.default = PagesNotFoundIndexComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PagesNotFoundIndexComponent);
});