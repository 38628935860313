define("primathon-ember-web/components/services/template-hero-section-services/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Services::HeroSection @bg={{@bg}}>
    <div class='services-staff-augmentation'>
      <h1>
        {{@title}}
      </h1>
  
      {{#if @subHeading}}
        <h2> {{{@subHeading}}}</h2>
      {{else}}
        <h2>We are there every step of the way, from your idea to your <b>product launch!</b></h2>
      {{/if}}
      <p>
        {{{@heading}}}
      </p>
    </div>
  </Services::HeroSection>
  */
  {
    "id": "/6RU2YxY",
    "block": "[[[8,[39,0],null,[[\"@bg\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"services-staff-augmentation\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"      \"],[10,\"h2\"],[12],[1,\" \"],[2,[30,3]],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"h2\"],[12],[1,\"We are there every step of the way, from your idea to your \"],[10,\"b\"],[12],[1,\"product launch!\"],[13],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[10,2],[12],[1,\"\\n      \"],[2,[30,4]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@bg\",\"@title\",\"@subHeading\",\"@heading\"],false,[\"services/hero-section\",\"if\"]]",
    "moduleName": "primathon-ember-web/components/services/template-hero-section-services/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});