define("primathon-ember-web/components/homepage/video-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button type="button" class="video-button" {{on 'click' @onClick}}>
    <div class="button-icon">
      <Common::AppImage @src="/assets/svgs/play-button.svg" height="100%" width="100%" alt="button icon" />
    </div>
    Watch Video
  </button>
  */
  {
    "id": "iHBcNkb/",
    "block": "[[[11,\"button\"],[24,0,\"video-button\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,1]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"button-icon\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,\"height\",\"100%\"],[24,\"width\",\"100%\"],[24,\"alt\",\"button icon\"]],[[\"@src\"],[\"/assets/svgs/play-button.svg\"]],null],[1,\"\\n  \"],[13],[1,\"\\n  Watch Video\\n\"],[13]],[\"@onClick\"],false,[\"on\",\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/homepage/video-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});