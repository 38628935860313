define("primathon-ember-web/components/job-desc/poster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Container class='job-desc-poster'>
    <h3>Careers at Primathon</h3>
    <h2>{{@jobData.title}}</h2>
    <p>{{@jobData.department}} | {{@jobData.location}} | {{@jobData.experience}} + years of experience</p>
  </Container>
  */
  {
    "id": "5r6COilx",
    "block": "[[[8,[39,0],[[24,0,\"job-desc-poster\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h3\"],[12],[1,\"Careers at Primathon\"],[13],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n  \"],[10,2],[12],[1,[30,1,[\"department\"]]],[1,\" | \"],[1,[30,1,[\"location\"]]],[1,\" | \"],[1,[30,1,[\"experience\"]]],[1,\" + years of experience\"],[13],[1,\"\\n\"]],[]]]]]],[\"@jobData\"],false,[\"container\"]]",
    "moduleName": "primathon-ember-web/components/job-desc/poster.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});