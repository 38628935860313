define("primathon-ember-web/templates/solutions/ai-chatbot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j9Fizc9n",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@bgImg\",\"@heroSection\",\"@customizedDevelopment\",\"@whyChoose\",\"@useCases\",\"@advantagesOfDevelopment\",\"@areYouReady\",\"@faqs\",\"@bulletList\"],[[30,1],\"ai-chatbot-heroSectionContainer-bgImg\",[30,1,[\"aiChatbotData\",\"heroSection\"]],[30,1,[\"aiChatbotData\",\"featuresOfPrimathonAIConversationalBot\"]],[30,1,[\"aiChatbotData\",\"implementationAndOperation\"]],[30,1,[\"aiChatbotData\",\"aiConversationalBotUses\"]],[30,1,[\"aiChatbotData\",\"advantagesOfFrontendDevelopment\"]],[30,1,[\"aiChatbotData\",\"areYouReady\"]],[30,1,[\"aiChatbotData\",\"faqs\"]],[30,1,[\"aiChatbotData\",\"bulletList\"]]]],null]],[\"@model\"],false,[\"pages/services/ai-chatbot\"]]",
    "moduleName": "primathon-ember-web/templates/solutions/ai-chatbot.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});