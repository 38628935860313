define("primathon-ember-web/controllers/services/staff-augmentation/platform", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServicesStaffAugmentationPlatformController extends Ember.Controller {
    get platformsTitle() {
      return this.model.platformData.title;
    }

    get platformsSubHeading() {
      return this.model.platformData.sub_heading;
    }

    get platformsData() {
      return this.model.platformData.data;
    }

    get platformsAbout() {
      return this.model.platformData.about;
    }

    get platformsBg() {
      return this.model.platformData.bg;
    }

    get platformsCircularList() {
      return this.model.platformData.circular_list;
    }

  }

  _exports.default = ServicesStaffAugmentationPlatformController;
});