define("primathon-ember-web/components/pages/case-studies/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='app-container'>
    <CaseStudies::Poster />
    <CaseStudies::Post />
    <Services::ToolsOfTrades />
    <Services::WeAreTrusted />
    <Homepage::HireUs @isModal={{true}} />
    <Services::BrowseThroughServices @activeService={{0}} />
    <Footer />
  </div>
  */
  {
    "id": "2XgsNVOw",
    "block": "[[[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,null],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[8,[39,2],null,null,null],[1,\"\\n  \"],[8,[39,3],null,null,null],[1,\"\\n  \"],[8,[39,4],null,[[\"@isModal\"],[true]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@activeService\"],[0]],null],[1,\"\\n  \"],[8,[39,6],null,null,null],[1,\"\\n\"],[13]],[],false,[\"case-studies/poster\",\"case-studies/post\",\"services/tools-of-trades\",\"services/we-are-trusted\",\"homepage/hire-us\",\"services/browse-through-services\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/case-studies/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});