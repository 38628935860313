define("primathon-ember-web/templates/technologies/swift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1N76ZvQD",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@bgImg\",\"@heroSection\",\"@customizedDevelopment\",\"@whyChoose\",\"@primathonApproach\",\"@nextLevelDevelopment\",\"@advantagesOfDevelopment\",\"@commenceYourJourney\",\"@driveInnovation\"],[[30,1],\"swift-heroSectionContainer-bgImg\",[30,1,[\"swiftData\",\"heroSection\"]],[30,1,[\"swiftData\",\"customizedSwiftDevelopment\"]],[30,1,[\"swiftData\",\"whyChoose\"]],[30,1,[\"swiftData\",\"primathonApproach\"]],[30,1,[\"swiftData\",\"nextLevelWebDevelopment\"]],[30,1,[\"swiftData\",\"advantagesOfSwiftDevelopment\"]],[30,1,[\"swiftData\",\"commenceYourJourneyOfSwiftDevelopment\"]],[30,1,[\"swiftData\",\"driveInnovation\"]]]],null]],[\"@model\"],false,[\"pages/services/staff-augmentation-new-services\"]]",
    "moduleName": "primathon-ember-web/templates/technologies/swift.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});