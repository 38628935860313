define("primathon-ember-web/components/about-us/we-believe/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="our-story-section we-believe-section">
    <div class="responsive-container">
      <div>
        <p class="about-us-para1">
          We believe,
        </p>
        <h2 class="about-us-heading we-believe-heading">
          “The meaning of life is to find your gift.
          <br />
          The purpose of life is to give it away.”
        </h2>
        <div class="we-believe-cards">
          <figure class="who-we-are-figure2">
            <Common::AppImage class="we-believe-image" @src="/assets/images/girl.png" alt="girl" />
          </figure>
          <div class="we-believe-text-block-1">
            <h3 class="about-us-heading1">
              Active Philanthropy in Anubhuti Samiti
            </h3>
            <p class="about-us-para we-believe-para">
              Empowering 20,000 underprivileged children with equal educational rights
            </p>
          </div>
          {{! <figure class="who-we-are-figure3">
            <Common::AppImage class="we-believe-image" @src="/assets/images/girl.png" alt="girl" />
          </figure>
          <div class="we-believe-text-block-2">
            <h3 class="about-us-heading2">
              Ultrices gravida venenatis sollicitudin
            </h3>
            <p class="about-us-para we-believe-para">
              Impacted x number of lives via our xyz
            </p>
          </div> }}
        </div>
      </div>
    </div>
    <div class="we-believe-green-ring">
      <Common::AppImage @src="/assets/images/green-ellipse.png" alt="machine" />
    </div>
  </section>
  */
  {
    "id": "GN0EKNAT",
    "block": "[[[10,\"section\"],[14,0,\"our-story-section we-believe-section\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"responsive-container\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[10,2],[14,0,\"about-us-para1\"],[12],[1,\"\\n        We believe,\\n      \"],[13],[1,\"\\n      \"],[10,\"h2\"],[14,0,\"about-us-heading we-believe-heading\"],[12],[1,\"\\n        “The meaning of life is to find your gift.\\n        \"],[10,\"br\"],[12],[13],[1,\"\\n        The purpose of life is to give it away.”\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"we-believe-cards\"],[12],[1,\"\\n        \"],[10,\"figure\"],[14,0,\"who-we-are-figure2\"],[12],[1,\"\\n          \"],[8,[39,0],[[24,0,\"we-believe-image\"],[24,\"alt\",\"girl\"]],[[\"@src\"],[\"/assets/images/girl.png\"]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"we-believe-text-block-1\"],[12],[1,\"\\n          \"],[10,\"h3\"],[14,0,\"about-us-heading1\"],[12],[1,\"\\n            Active Philanthropy in Anubhuti Samiti\\n          \"],[13],[1,\"\\n          \"],[10,2],[14,0,\"about-us-para we-believe-para\"],[12],[1,\"\\n            Empowering 20,000 underprivileged children with equal educational rights\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"we-believe-green-ring\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"alt\",\"machine\"]],[[\"@src\"],[\"/assets/images/green-ellipse.png\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/about-us/we-believe/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});