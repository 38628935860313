define("primathon-ember-web/templates/services/ai-hub/generative-ai-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J4hTvvlA",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@heroSection\",\"@heroSectionImgStyle\",\"@heroSectionClass\",\"@services\",\"@ctaSection1\",\"@useCases\",\"@benefits\",\"@bulletsList2\",\"@bulletsListImgStyle2\",\"@bulletsList3\",\"@bulletsListImgStyle3\",\"@bulletsList\",\"@bulletsListImgStyle\",\"@blogData\",\"@journey\",\"@faqs\"],[[30,1],[30,1,[\"data\",\"heroSection\"]],\"w-[500px] h-[580px] xl:w-[550px] xl:h-[590px]\",\"bg-[#092030] lg:pr-0\",[30,1,[\"data\",\"exploreOurService\"]],[30,1,[\"data\",\"ctaSection\"]],[30,1,[\"data\",\"benefitsOfGeneratingAiService\"]],[30,1,[\"data\",\"whyChooseUs\"]],[30,1,[\"data\",\"aiMatters\"]],\"w-[400px] h-[330px] xl:w-[410px] xl:h-[300px]\",[30,1,[\"data\",\"unlockYourPotential\"]],\"w-[320px] h-[320px] xl:w-[350px] xl:h-[350px]\",[30,1,[\"data\",\"empoweringBusinesses\"]],\"w-[340px] h-[220px] xl:w-[390px] xl:h-[240px]\",[30,1,[\"blogData\"]],[30,1,[\"data\",\"aiGeneratingJourney\"]],[30,1,[\"data\",\"faqs\"]]]],null]],[\"@model\"],false,[\"pages/solutions\"]]",
    "moduleName": "primathon-ember-web/templates/services/ai-hub/generative-ai-services.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});