define("primathon-ember-web/routes/services/staff-augmentation/hire-react-js-developers", ["exports", "primathon-ember-web/constants/services/staff-augmentation", "primathon-ember-web/constants/homepage/blog", "fetch", "primathon-ember-web/constants/homepage/testimonial"], function (_exports, _staffAugmentation, _blog, _fetch, _testimonial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ServicesStaffAugmentationReactDevelopersRoute = (_dec = Ember.inject.service, (_class = class ServicesStaffAugmentationReactDevelopersRoute extends Ember.Route {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "headData", _descriptor, this);

      _defineProperty(this, "_HOSTNAME", '/blog');

      _defineProperty(this, "_CATEGORY_KEY", 158);

      _defineProperty(this, "_URL", `https://primathon.in/blog/wp-json/wp/v2/posts?_embed&categories=${this._CATEGORY_KEY}`);
    }

    getImageUrl(data) {
      if (data._embedded && data._embedded['wp:featuredmedia'] && data._embedded['wp:featuredmedia'][0] && data._embedded['wp:featuredmedia'][0].source_url) {
        return data._embedded['wp:featuredmedia']['0'].source_url;
      }
    }

    async model() {
      const data = _staffAugmentation.REACT_DEVELOPER;
      const testinomialData = _testimonial.TESTIMONIAL_DATA;
      const blogData = _blog.BLOG_DATA;

      try {
        const blogResponse = await (await (0, _fetch.default)(this._URL)).json();
        const slicedBlogResponse = blogResponse.slice(0, 4);
        blogData.data = slicedBlogResponse.map(item => ({
          title: item.title?.rendered,
          link: item.link,
          blogImageUrl: this.getImageUrl(item),
          descriptionHtml: item.excerpt?.rendered
        }));
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        blogData.data = [];
      }

      return {
        data,
        testinomialData,
        blogData
      };
    }

    beforeModel() {
      const title = 'Hire Certified Dedicated Senior React JS Developers | Primathon';
      const description = 'Partner with Primathon to hire expert React.js developers. Our certified and dedicated team ensures top-tier web solutions. Get remote React.js developers in India today!';
      this.headData.setData({
        title,
        description
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "headData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ServicesStaffAugmentationReactDevelopersRoute;
});