define("primathon-ember-web/components/pages/services/end-to-end-product-services/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Header />
  <div class='app-container'>
    <Services::TemplateHeroSectionServices
      @heading={{@about}}
      @subHeading={{@subHeading}}
      @title={{@title}}
      @bg={{@bg}}
    />
    <Services::CircularList @content={{@circular_list}} @color={{'orange'}} />
    <Services::LinearList @content={{this.linear_list_content}} />
    <Services::ArrowList @content={{@arrow_list}} />
    <Services::EndToEndServicesDetails
      @title={{@title}}
      @heading={{@about}}
      @description={{@description}}
      @imgUrl={{@imgUrl}}
    />
    <Services::ToolsOfTrades />
    <Services::WeAreTrusted />
    <Homepage::HireUs />
    <Services::BrowseThroughServices @activeService={{0}} />
    <Footer />
  </div>
  */
  {
    "id": "V5SU7tIu",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@heading\",\"@subHeading\",\"@title\",\"@bg\"],[[30,1],[30,2],[30,3],[30,4]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@content\",\"@color\"],[[30,5],\"orange\"]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@content\"],[[30,0,[\"linear_list_content\"]]]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@content\"],[[30,6]]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@title\",\"@heading\",\"@description\",\"@imgUrl\"],[[30,3],[30,1],[30,7],[30,8]]],null],[1,\"\\n  \"],[8,[39,6],null,null,null],[1,\"\\n  \"],[8,[39,7],null,null,null],[1,\"\\n  \"],[8,[39,8],null,null,null],[1,\"\\n  \"],[8,[39,9],null,[[\"@activeService\"],[0]],null],[1,\"\\n  \"],[8,[39,10],null,null,null],[1,\"\\n\"],[13]],[\"@about\",\"@subHeading\",\"@title\",\"@bg\",\"@circular_list\",\"@arrow_list\",\"@description\",\"@imgUrl\"],false,[\"header\",\"services/template-hero-section-services\",\"services/circular-list\",\"services/linear-list\",\"services/arrow-list\",\"services/end-to-end-services-details\",\"services/tools-of-trades\",\"services/we-are-trusted\",\"homepage/hire-us\",\"services/browse-through-services\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/services/end-to-end-product-services/index.hbs",
    "isStrictMode": false
  });

  class PagesServicesEndToEndProductServicesIndexComponent extends _component.default {
    get circular_list_content() {
      return {
        heading: 'Why choose us for your project?',
        items: ['Agile Methodology', 'On time delivery', 'User Centric Approach', 'Highly Qualified and Experienced Team']
      };
    }

    get linear_list_content() {
      return {
        heading: 'Why choose us?',
        items: ['Agile Methadology', 'User Centric Approach', 'On-time delivery', 'Highly qualified team']
      };
    }

    get arrow_list_content() {
      return {
        heading: 'Let us take your project on a journey!',
        items: ['You share your idea with us', 'We create a strategy for your project', 'Our team starts building it', 'Product gets launched to the market']
      };
    }

  }

  _exports.default = PagesServicesEndToEndProductServicesIndexComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PagesServicesEndToEndProductServicesIndexComponent);
});