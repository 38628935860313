define("primathon-ember-web/templates/services/staff-augmentation/hire-react-js-developers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3OGTslH7",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@heroSection\",\"@heroSectionImgStyle\",\"@heroSectionClass\",\"@elevateYourProject\",\"@elevateYourProjectImgStyle\",\"@ctaSection\",\"@useCases\",\"@whyChoose\",\"@hireDeveloper\",\"@journey\",\"@developmentStrategy\",\"@developmentStrategyImgStyle\",\"@benefits\",\"@table\",\"@caseStudies\",\"@blogData\",\"@faqs\"],[[30,1],[30,1,[\"data\",\"heroSection\"]],\"w-[540px] h-[620px] xl:w-[610px] xl:h-[650px]\",\"bg-[#1A284D] lg:pr-0\",[30,1,[\"data\",\"elevateYourProject\"]],\"w-[360px] h-[300px] sm:w-[525px] sm:h-[500px] xl:w-[698px] xl:h-[600px]\",[30,1,[\"data\",\"ctaSection\"]],[30,1,[\"data\",\"reactDevelopmentServices\"]],[30,1,[\"data\",\"whyChoose\"]],[30,1,[\"data\",\"hireDeveloper\"]],[30,1,[\"data\",\"reactJourney\"]],[30,1,[\"data\",\"reactDevelopmentStrategy\"]],\"w-[400px] h-[420px] xl:w-[500px] xl:h-[500px]\",[30,1,[\"data\",\"benefitsOfHiringReact\"]],[30,1,[\"data\",\"table\"]],[30,1,[\"data\",\"caseStudies\"]],[30,1,[\"blogData\"]],[30,1,[\"data\",\"faqs\"]]]],null]],[\"@model\"],false,[\"pages/services/staff-augmentation-services-new\"]]",
    "moduleName": "primathon-ember-web/templates/services/staff-augmentation/hire-react-js-developers.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});