define("primathon-ember-web/components/pages/careers/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Career::Poster />
  <Career::Joinus />
  <Career::Reason />
  <Career::Testimonials @data={{@data}} />
  <Career::Culture />
  <Career::Hireus />
  <Footer />
  */
  {
    "id": "d4CDVpZj",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[8,[39,1],null,null,null],[1,\"\\n\"],[8,[39,2],null,null,null],[1,\"\\n\"],[8,[39,3],null,[[\"@data\"],[[30,1]]],null],[1,\"\\n\"],[8,[39,4],null,null,null],[1,\"\\n\"],[8,[39,5],null,null,null],[1,\"\\n\"],[8,[39,6],null,null,null]],[\"@data\"],false,[\"career/poster\",\"career/joinus\",\"career/reason\",\"career/testimonials\",\"career/culture\",\"career/hireus\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/careers/index.hbs",
    "isStrictMode": false
  });

  class PagesCareersIndexComponent extends _component.default {}

  _exports.default = PagesCareersIndexComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PagesCareersIndexComponent);
});