define("primathon-ember-web/templates/solutions/ai-credit-scoring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UQHFjNw3",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@heroSection\",\"@heroSectionImgStyle\",\"@heroSectionClass\",\"@services\",\"@ctaSection1\",\"@useCases\",\"@bulletsList\",\"@bulletsListImgStyle\",\"@ctaSection2\",\"@whyChoose\",\"@features\"],[[30,1],[30,1,[\"data\",\"heroSection\"]],\"w-[540px] h-[540px] xl:w-[600px] xl:h-[560px]\",\"bg-[#1A284D] lg:pr-0\",[30,1,[\"data\",\"aiStCreditScoringDevelopmentServices\"]],[30,1,[\"data\",\"ctaSection1\"]],[30,1,[\"data\",\"benefitsOfAiCreditScoring\"]],[30,1,[\"data\",\"optForAiCreditScore\"]],\"w-[350px] h-[220px] xl:w-[420px] xl:h-[230px]\",[30,1,[\"data\",\"ctaSection2\"]],[30,1,[\"data\",\"whyChoose\"]],[30,1,[\"data\",\"featuresOfAiCreditScoring\"]]]],null]],[\"@model\"],false,[\"pages/solutions\"]]",
    "moduleName": "primathon-ember-web/templates/solutions/ai-credit-scoring.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});