define("primathon-ember-web/components/services/how-we-build/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="who-we-are-section">
    <div class="responsive-container">
      <h2 class="about-us-heading">
        How we build our team?
      </h2>
      <div class="who-we-are-qualities">
        <figure class="who-we-are-figure">
          <Common::AppImage class="who-we-are-img" @src="/assets/images/arrow.png" alt="arrow icon" />
          Identify the best talent
  with 2+ years of experience
        </figure>
        <figure class="who-we-are-figure">
          <Common::AppImage class="who-we-are-img" @src="/assets/images/train.png" alt="train icon" />
          We Train them by following
   on-going training methodology
        </figure>
        <figure class="who-we-are-figure">
          <Common::AppImage class="who-we-are-img" @src="/assets/images/desktop.png" alt="desktop icon" />
          Give them hands on expertise with our in-house projects
        </figure>
        <figure class="who-we-are-figure">
          <Common::AppImage class="who-we-are-img" @src="/assets/images/check.png" alt="check icon" />
          They share their talents and add value to your buisness
        </figure>
      </div>
    </div>
    {{! <div class="who-we-are-red-ring"></div> }}
  </section>
  */
  {
    "id": "CNV96ud9",
    "block": "[[[10,\"section\"],[14,0,\"who-we-are-section\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"responsive-container\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"about-us-heading\"],[12],[1,\"\\n      How we build our team?\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"who-we-are-qualities\"],[12],[1,\"\\n      \"],[10,\"figure\"],[14,0,\"who-we-are-figure\"],[12],[1,\"\\n        \"],[8,[39,0],[[24,0,\"who-we-are-img\"],[24,\"alt\",\"arrow icon\"]],[[\"@src\"],[\"/assets/images/arrow.png\"]],null],[1,\"\\n        Identify the best talent\\nwith 2+ years of experience\\n      \"],[13],[1,\"\\n      \"],[10,\"figure\"],[14,0,\"who-we-are-figure\"],[12],[1,\"\\n        \"],[8,[39,0],[[24,0,\"who-we-are-img\"],[24,\"alt\",\"train icon\"]],[[\"@src\"],[\"/assets/images/train.png\"]],null],[1,\"\\n        We Train them by following\\n on-going training methodology\\n      \"],[13],[1,\"\\n      \"],[10,\"figure\"],[14,0,\"who-we-are-figure\"],[12],[1,\"\\n        \"],[8,[39,0],[[24,0,\"who-we-are-img\"],[24,\"alt\",\"desktop icon\"]],[[\"@src\"],[\"/assets/images/desktop.png\"]],null],[1,\"\\n        Give them hands on expertise with our in-house projects\\n      \"],[13],[1,\"\\n      \"],[10,\"figure\"],[14,0,\"who-we-are-figure\"],[12],[1,\"\\n        \"],[8,[39,0],[[24,0,\"who-we-are-img\"],[24,\"alt\",\"check icon\"]],[[\"@src\"],[\"/assets/images/check.png\"]],null],[1,\"\\n        They share their talents and add value to your buisness\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/services/how-we-build/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});