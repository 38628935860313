define("primathon-ember-web/controllers/services/staff-augmentation/backend-developers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServicesStaffAugmentationBackendDevelopersController extends Ember.Controller {
    get backendDevelopersTitle() {
      return this.model.backendData.title;
    }

    get backendDeveloperSubHeading() {
      return this.model.backendData.sub_heading;
    }

    get backendDevelopersData() {
      return this.model.backendData.data;
    }

    get backendDeveloperAbout() {
      return this.model.backendData.about;
    }

    get backendDeveloperBg() {
      return this.model.backendData.bg;
    }

    get backendDeveloperCircularList() {
      return this.model.backendData.circular_list;
    }

    get faqTitle() {
      return this.model.faqData.title;
    }

    get faqData() {
      return this.model.faqData.data;
    }

  }

  _exports.default = ServicesStaffAugmentationBackendDevelopersController;
  ;
});