define("primathon-ember-web/components/open-position/job-row/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='jobRow'>
    <span>
      {{@title}}
    </span>
    <span>
      {{@department}}
    </span>
    <span>
      {{@experience}}
    </span>
    <span>
      {{@location}}
    </span>
    <span>
      {{@jobType}}
    </span>
    <LinkTo @route='job-desc' @query={{hash id=@id}} class='menu-home'>
      View More..
    </LinkTo>
  </div>
  
  <div class='jobCard'>
    <h5>{{@title}}</h5>
    <label>
      {{@department}}
      |
      {{@experience}}
      years of experience |
      {{@location}}
      |
      {{@jobType}}
    </label>
    <LinkTo @route='job-desc' @query={{hash id=@id}} class='menu-home'>
      View More..
    </LinkTo>
  </div>
  */
  {
    "id": "TNzrnBo4",
    "block": "[[[10,0],[14,0,\"jobRow\"],[12],[1,\"\\n  \"],[10,1],[12],[1,\"\\n    \"],[1,[30,1]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[12],[1,\"\\n    \"],[1,[30,3]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[12],[1,\"\\n    \"],[1,[30,4]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[12],[1,\"\\n    \"],[1,[30,5]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,0],[[24,0,\"menu-home\"]],[[\"@route\",\"@query\"],[\"job-desc\",[28,[37,1],null,[[\"id\"],[[30,6]]]]]],[[\"default\"],[[[[1,\"\\n    View More..\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"jobCard\"],[12],[1,\"\\n  \"],[10,\"h5\"],[12],[1,[30,1]],[13],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n    |\\n    \"],[1,[30,3]],[1,\"\\n    years of experience |\\n    \"],[1,[30,4]],[1,\"\\n    |\\n    \"],[1,[30,5]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,0],[[24,0,\"menu-home\"]],[[\"@route\",\"@query\"],[\"job-desc\",[28,[37,1],null,[[\"id\"],[[30,6]]]]]],[[\"default\"],[[[[1,\"\\n    View More..\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@title\",\"@department\",\"@experience\",\"@location\",\"@jobType\",\"@id\"],false,[\"link-to\",\"hash\"]]",
    "moduleName": "primathon-ember-web/components/open-position/job-row/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});