define("primathon-ember-web/components/chatgpt/thirdparty-integration", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='chatgpt-thirdpartyContainer'>
    <div class='headingContainer'>
      <h2>{{@thirdPartyIntegration.title}} </h2>
    </div>
    <div class='chatgptCarousalContainer' onmouseenter={{this.onMouseOver}} onmouseleave={{this.onMouseOut}} {{did-insert this.changeIndex}}>
      <div class='cardsContainer' style='transform:translateX({{this.scrollValue}});'>
        {{#each @thirdPartyIntegration.cardData as |item|}}
          <div class='thirdPartyIntegrationCard' style='width: calc({{this.width}} - 32px);'>
            <Common::AppImage @src={{item.imgSrc}} />
            <div class='cardContent'>
              <h3>{{item.cardTitle}}</h3>
              <p>
                {{item.content}}
              </p>
            </div>
          </div>
        {{/each}}
      </div>
      {{!-- <button type='button' {{on 'click' this.showNext}} class='industrySpecificNextButton'>
        <Common::AppImage @src='/assets/images/next.png' alt='customer obsession' />
      </button>
      <button type='button' {{on 'click' this.showPrevious}} class='industrySpecificPreviousButton'>
        <Common::AppImage @src='/assets/images/prev.png' alt='customer obsession' />
      </button> --}}
    </div>
  
    <div class='carousal-bottom-btn-container'>
      {{#each this.length as |item index|}}
        <div
          class='carousal-bottom-btn {{if (eq this.active index) "selected"}}'
          {{on 'click' (fn this.clickIndex index)}}
        >
        </div>
      {{/each}}
    </div>
  
  </div>
  */
  {
    "id": "pv0bmVeO",
    "block": "[[[10,0],[14,0,\"chatgpt-thirdpartyContainer\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"headingContainer\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,[30,1,[\"title\"]]],[1,\" \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[11,0],[24,0,\"chatgptCarousalContainer\"],[16,\"onmouseenter\",[30,0,[\"onMouseOver\"]]],[16,\"onmouseleave\",[30,0,[\"onMouseOut\"]]],[4,[38,0],[[30,0,[\"changeIndex\"]]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"cardsContainer\"],[15,5,[29,[\"transform:translateX(\",[30,0,[\"scrollValue\"]],\");\"]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"cardData\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"thirdPartyIntegrationCard\"],[15,5,[29,[\"width: calc(\",[30,0,[\"width\"]],\" - 32px);\"]]],[12],[1,\"\\n          \"],[8,[39,3],null,[[\"@src\"],[[30,2,[\"imgSrc\"]]]],null],[1,\"\\n          \"],[10,0],[14,0,\"cardContent\"],[12],[1,\"\\n            \"],[10,\"h3\"],[12],[1,[30,2,[\"cardTitle\"]]],[13],[1,\"\\n            \"],[10,2],[12],[1,\"\\n              \"],[1,[30,2,[\"content\"]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"carousal-bottom-btn-container\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"length\"]]],null]],null],null,[[[1,\"      \"],[11,0],[16,0,[29,[\"carousal-bottom-btn \",[52,[28,[37,5],[[30,0,[\"active\"]],[30,4]],null],\"selected\"]]]],[4,[38,6],[\"click\",[28,[37,7],[[30,0,[\"clickIndex\"]],[30,4]],null]],null],[12],[1,\"\\n      \"],[13],[1,\"\\n\"]],[3,4]],null],[1,\"  \"],[13],[1,\"\\n\\n\"],[13]],[\"@thirdPartyIntegration\",\"item\",\"item\",\"index\"],false,[\"did-insert\",\"each\",\"-track-array\",\"common/app-image\",\"if\",\"eq\",\"on\",\"fn\"]]",
    "moduleName": "primathon-ember-web/components/chatgpt/thirdparty-integration.hbs",
    "isStrictMode": false
  });

  let ChatgptThirdpartyIntegrationComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class ChatgptThirdpartyIntegrationComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "active", _descriptor, this);

      _initializerDefineProperty(this, "arr", _descriptor2, this);

      _initializerDefineProperty(this, "pointsLength", _descriptor3, this);

      _initializerDefineProperty(this, "length", _descriptor4, this);

      _initializerDefineProperty(this, "scrollValue", _descriptor5, this);

      _defineProperty(this, "width", window.innerWidth < 572 ? `${window.innerWidth - 60}px` : '510px');

      _initializerDefineProperty(this, "incrementor", _descriptor6, this);
    }

    changeIndex() {
      this.interval = setInterval(() => {
        if (this.active + 1 >= this.length.length) {
          this.active = 0;
        } else {
          this.active = this.active + this.incrementor;
        }

        this.changeScroll();
      }, 3000);
    }

    onMouseOver() {
      clearInterval(this.interval);
    }

    onMouseOut() {
      this.changeIndex();
    }

    clickIndex(index) {
      this.active = index;
      this.changeScroll();
    }

    showNext() {
      if (this.active + 1 > 6) {
        this.active = 6;
      } else {
        this.active = this.active + 1;
      }

      this.changeScroll();
    }

    showPrevious() {
      if (this.active - 1 < 0) {
        this.active = 0;
      } else {
        this.active = this.active - 1;
      }

      this.changeScroll();
    }

    changeScroll() {
      this.scrollValue = `calc(${this.width} * ${-this.active})`;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "active", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "arr", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.thirdPartyIntegration.cardData;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pointsLength", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return window.innerWidth < 768 ? 0 : window.innerWidth > 1440 ? 2 : 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "length", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.arr.slice(this.pointsLength);
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "scrollValue", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "incrementor", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "changeIndex", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "changeIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMouseOver", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onMouseOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMouseOut", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onMouseOut"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clickIndex", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "clickIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showNext", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "showNext"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showPrevious", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "showPrevious"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeScroll", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "changeScroll"), _class.prototype)), _class));
  _exports.default = ChatgptThirdpartyIntegrationComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ChatgptThirdpartyIntegrationComponent);
});