define("primathon-ember-web/components/pages/hire/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <iframe
    src='https://prima-marketing.primathontech.co.in/'
    width='100%'
    height='100%'
    frameborder='0'
    allowfullscreen
    class='prima-hire-iframe'
    title='Primathon Marketing Hire Page'
    X-Frame-Options='Cross-Origin'
    Content-Security-Policy='frame-ancestors https://prima-marketing.primathontech.co.in/ https://prima-marketing.primathontech.co.in/'
  >
  </iframe>
  
  */
  {
    "id": "SOcUwbUY",
    "block": "[[[10,\"iframe\"],[14,\"src\",\"https://prima-marketing.primathontech.co.in/\"],[14,\"width\",\"100%\"],[14,\"height\",\"100%\"],[14,\"frameborder\",\"0\"],[14,\"allowfullscreen\",\"\"],[14,0,\"prima-hire-iframe\"],[14,\"title\",\"Primathon Marketing Hire Page\"],[14,\"X-Frame-Options\",\"Cross-Origin\"],[14,\"Content-Security-Policy\",\"frame-ancestors https://prima-marketing.primathontech.co.in/ https://prima-marketing.primathontech.co.in/\"],[12],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "primathon-ember-web/components/pages/hire/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});