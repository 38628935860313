define("primathon-ember-web/constants/country-code/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.COUNTRY_CODE = void 0;
  const COUNTRY_CODE = [{
    id: 1,
    value: '+91',
    countryName: 'India'
  }, {
    id: 2,
    value: '+1',
    countryName: 'United States'
  }, {
    id: 3,
    value: '+44',
    countryName: 'United Kingdom'
  }, {
    id: 4,
    value: '+61',
    countryName: 'Australia'
  }, {
    id: 5,
    value: '+64',
    countryName: 'New Zealand'
  }, {
    id: 6,
    value: '+27',
    countryName: 'South Africa'
  }, {
    id: 7,
    value: '+234',
    countryName: 'Nigeria'
  }, {
    id: 8,
    value: '+52',
    countryName: 'Mexico'
  }, {
    id: 9,
    value: '+55',
    countryName: 'Brazil'
  }, {
    id: 10,
    value: '+54',
    countryName: 'Argentina'
  }, {
    id: 11,
    value: '+56',
    countryName: 'Chile'
  }, {
    id: 12,
    value: '+57',
    countryName: 'Colombia'
  }, {
    id: 13,
    value: '+51',
    countryName: 'Peru'
  }, {
    id: 14,
    value: '+58',
    countryName: 'Venezuela'
  }, {
    id: 15,
    value: '+49',
    countryName: 'Germany'
  }, {
    id: 16,
    value: '+33',
    countryName: 'France'
  }, {
    id: 17,
    value: '+34',
    countryName: 'Spain'
  }, {
    id: 18,
    value: '+39',
    countryName: 'Italy'
  }, {
    id: 19,
    value: '+31',
    countryName: 'Netherlands'
  }, {
    id: 20,
    value: '+46',
    countryName: 'Sweden'
  }, {
    id: 21,
    value: '+47',
    countryName: 'Norway'
  }, {
    id: 22,
    value: '+45',
    countryName: 'Denmark'
  }, {
    id: 23,
    value: '+358',
    countryName: 'Finland'
  }, {
    id: 24,
    value: '+41',
    countryName: 'Switzerland'
  }, {
    id: 25,
    value: '+43',
    countryName: 'Austria'
  }, {
    id: 26,
    value: '+30',
    countryName: 'Greece'
  }, {
    id: 27,
    value: '+48',
    countryName: 'Poland'
  }, {
    id: 28,
    value: '+351',
    countryName: 'Portugal'
  }, {
    id: 29,
    value: '+353',
    countryName: 'Ireland'
  }, {
    id: 30,
    value: '+420',
    countryName: 'Czech Republic'
  }, {
    id: 31,
    value: '+421',
    countryName: 'Slovakia'
  }, {
    id: 32,
    value: '+36',
    countryName: 'Hungary'
  }, {
    id: 33,
    value: '+40',
    countryName: 'Romania'
  }, {
    id: 34,
    value: '+359',
    countryName: 'Bulgaria'
  }, {
    id: 35,
    value: '+90',
    countryName: 'Turkey'
  }, {
    id: 36,
    value: '+7',
    countryName: 'Russia'
  }, {
    id: 37,
    value: '+380',
    countryName: 'Ukraine'
  }, {
    id: 38,
    value: '+375',
    countryName: 'Belarus'
  }, {
    id: 39,
    value: '+372',
    countryName: 'Estonia'
  }, {
    id: 40,
    value: '+371',
    countryName: 'Latvia'
  }, {
    id: 41,
    value: '+370',
    countryName: 'Lithuania'
  }, {
    id: 42,
    value: '+373',
    countryName: 'Moldova'
  }, {
    id: 43,
    value: '+995',
    countryName: 'Georgia'
  }, {
    id: 44,
    value: '+374',
    countryName: 'Armenia'
  }, {
    id: 45,
    value: '+7',
    countryName: 'Kazakhstan'
  }, {
    id: 46,
    value: '+998',
    countryName: 'Uzbekistan'
  }, {
    id: 47,
    value: '+993',
    countryName: 'Turkmenistan'
  }, {
    id: 48,
    value: '+996',
    countryName: 'Kyrgyzstan'
  }, {
    id: 49,
    value: '+994',
    countryName: 'Azerbaijan'
  }, {
    id: 50,
    value: '+98',
    countryName: 'Iran'
  }, {
    id: 51,
    value: '+964',
    countryName: 'Iraq'
  }, {
    id: 52,
    value: '+963',
    countryName: 'Syria'
  }, {
    id: 53,
    value: '+961',
    countryName: 'Lebanon'
  }, {
    id: 54,
    value: '+962',
    countryName: 'Jordan'
  }, {
    id: 55,
    value: '+966',
    countryName: 'Saudi Arabia'
  }, {
    id: 56,
    value: '+967',
    countryName: 'Yemen'
  }, {
    id: 57,
    value: '+968',
    countryName: 'Oman'
  }, {
    id: 58,
    value: '+971',
    countryName: 'United Arab Emirates'
  }, {
    id: 59,
    value: '+972',
    countryName: 'Israel'
  }, {
    id: 60,
    value: '+973',
    countryName: 'Bahrain'
  }, {
    id: 61,
    value: '+974',
    countryName: 'Qatar'
  }, {
    id: 62,
    value: '+965',
    countryName: 'Kuwait'
  }, {
    id: 63,
    value: '+86',
    countryName: 'China'
  }, {
    id: 64,
    value: '+81',
    countryName: 'Japan'
  }, {
    id: 65,
    value: '+82',
    countryName: 'South Korea'
  }, {
    id: 67,
    value: '+66',
    countryName: 'Thailand'
  }, {
    id: 68,
    value: '+60',
    countryName: 'Malaysia'
  }, {
    id: 69,
    value: '+65',
    countryName: 'Singapore'
  }, {
    id: 70,
    value: '+62',
    countryName: 'Indonesia'
  }, {
    id: 71,
    value: '+63',
    countryName: 'Philippines'
  }, {
    id: 72,
    value: '+852',
    countryName: 'Hong Kong'
  }, {
    id: 73,
    value: '+886',
    countryName: 'Taiwan'
  }, {
    id: 74,
    value: '+852',
    countryName: 'Pakistan'
  }, {
    id: 75,
    value: '+880',
    countryName: 'Bangladesh'
  }, {
    id: 76,
    value: '+852',
    countryName: 'Nepal'
  }, {
    id: 77,
    value: '+852',
    countryName: 'Maldives'
  }, {
    id: 78,
    value: '+95',
    countryName: 'Myanmar (Burma)'
  }, {
    id: 79,
    value: '+855',
    countryName: 'Cambodia'
  }, {
    id: 80,
    value: '+856',
    countryName: 'Laos'
  }, {
    id: 81,
    value: '+673',
    countryName: 'Brunei'
  }, {
    id: 82,
    value: '+670',
    countryName: 'Timor-Leste'
  }, {
    id: 83,
    value: '+679',
    countryName: 'Fiji'
  }, {
    id: 84,
    value: '+675',
    countryName: 'Papua New Guinea'
  }, {
    id: 85,
    value: '+677',
    countryName: 'Solomon Islands'
  }, {
    id: 86,
    value: '+678',
    countryName: 'Vanuatu'
  }, {
    id: 87,
    value: '+676',
    countryName: 'Tonga'
  }, {
    id: 88,
    value: '+685',
    countryName: 'Samoa'
  }, {
    id: 89,
    value: '+686',
    countryName: 'Kiribati'
  }, {
    id: 90,
    value: '+688',
    countryName: 'Tuvalu'
  }, {
    id: 91,
    value: '+674',
    countryName: 'Nauru'
  }, {
    id: 92,
    value: '+692',
    countryName: 'Marshall Islands'
  }, {
    id: 93,
    value: '+680',
    countryName: 'Palau'
  }, {
    id: 94,
    value: '+691',
    countryName: 'Micronesia'
  }, {
    id: 95,
    value: '+1-671',
    countryName: 'Guam'
  }, {
    id: 96,
    value: '+1-670',
    countryName: 'Northern Mariana Islands'
  }, {
    id: 97,
    value: '+1-684',
    countryName: 'American Samoa'
  }, {
    id: 98,
    value: '+852',
    countryName: 'ook Islands'
  }, {
    id: 99,
    value: '+683',
    countryName: 'Niue'
  }, {
    id: 100,
    value: '+690',
    countryName: 'Tokelau'
  }, {
    id: 101,
    value: '+6723',
    countryName: 'Norfolk Island'
  }, {
    id: 102,
    value: '+61',
    countryName: 'Christmas Island'
  }, {
    id: 103,
    value: '+61',
    countryName: 'Cocos Islands'
  }, {
    id: 104,
    value: '+64',
    countryName: 'Pitcairn Islands'
  }, {
    id: 105,
    value: '+56',
    countryName: 'Easter Island'
  }, {
    id: 106,
    value: '+247',
    countryName: 'Ascension Island'
  }, {
    id: 107,
    value: '+290',
    countryName: 'Tristan da Cunha'
  }, {
    id: 108,
    value: '+500',
    countryName: 'Falkland Islands'
  }, {
    id: 109,
    value: '+500',
    countryName: 'South Georgia and the South Sandwich Islands'
  }, {
    id: 110,
    value: '+290',
    countryName: 'Saint Helena, Ascension and Tristan da Cunha'
  }, {
    id: 111,
    value: '+246',
    countryName: 'British Indian Ocean Territory'
  }, {
    id: 112,
    value: '+590',
    countryName: 'Saint Martin'
  }, {
    id: 113,
    value: '+508',
    countryName: 'Saint Pierre and Miquelon'
  }, {
    id: 114,
    value: '+509',
    countryName: 'Haiti'
  }, {
    id: 115,
    value: '+53',
    countryName: 'Cuba'
  }, {
    id: 116,
    value: '+592',
    countryName: 'Guyana'
  }, {
    id: 117,
    value: '+597',
    countryName: 'Suriname'
  }, {
    id: 118,
    value: '+594',
    countryName: 'French Guiana'
  }, {
    id: 119,
    value: '+501',
    countryName: 'Belize'
  }, {
    id: 120,
    value: '+502',
    countryName: 'Guatemala'
  }, {
    id: 121,
    value: '+503',
    countryName: 'El Salvador'
  }, {
    id: 122,
    value: '+504',
    countryName: 'Honduras'
  }, {
    id: 123,
    value: '+505',
    countryName: 'Nicaragua'
  }, {
    id: 124,
    value: '+506',
    countryName: 'Costa Rica'
  }, {
    id: 125,
    value: '+507',
    countryName: 'Panama'
  }, {
    id: 126,
    value: '+57',
    countryName: 'Colombia'
  }, {
    id: 127,
    value: '+58',
    countryName: 'Venezuela'
  }, {
    id: 128,
    value: '+593',
    countryName: 'Ecuador'
  }, {
    id: 129,
    value: '+51',
    countryName: 'Peru'
  }, {
    id: 130,
    value: '+591',
    countryName: 'Bolivia'
  }, {
    id: 131,
    value: '+595',
    countryName: 'Paraguay'
  }, {
    id: 132,
    value: '+54',
    countryName: 'Argentina'
  }, {
    id: 133,
    value: '+598',
    countryName: 'Uruguay'
  }, {
    id: 134,
    value: '+56',
    countryName: 'Chile'
  }, {
    id: 135,
    value: '+55',
    countryName: 'Brazil'
  }];
  _exports.COUNTRY_CODE = COUNTRY_CODE;
});