define("primathon-ember-web/components/pages/services/ai-chatbot/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Header />
  <div class='app-container'>
    <Ai-development::Hero-section class='{{@bgImg}}' @heroSection={{@heroSection}} />
    <Chatgpt::Getting-started @gettingStarted={{@customizedDevelopment}} />
    <Services::BulletsList @data={{@bulletList}} />
    <Chatgpt::Benefits @benefits={{@whyChoose}} />
    <Chatgpt::Chatgptuse @useCases={{@useCases}} />
    <Homepage::Testimonial
      @title={{@data.testinomialData.title}}
      @description={{@data.testinomialData.description}}
      @data={{@data.testinomialData.data}}
    />
    <Services::FaqList @title={{@faqs.title}} @data={{@faqs.cardData}} />
    <Technologies::CtaSection @ctaSection={{@areYouReady}} />
    <Footer />
  </div>
  */
  {
    "id": "l991BqKW",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,1],[[16,0,[29,[[30,1]]]]],[[\"@heroSection\"],[[30,2]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@gettingStarted\"],[[30,3]]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@data\"],[[30,4]]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@benefits\"],[[30,5]]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@useCases\"],[[30,6]]],null],[1,\"\\n  \"],[8,[39,6],null,[[\"@title\",\"@description\",\"@data\"],[[30,7,[\"testinomialData\",\"title\"]],[30,7,[\"testinomialData\",\"description\"]],[30,7,[\"testinomialData\",\"data\"]]]],null],[1,\"\\n  \"],[8,[39,7],null,[[\"@title\",\"@data\"],[[30,8,[\"title\"]],[30,8,[\"cardData\"]]]],null],[1,\"\\n  \"],[8,[39,8],null,[[\"@ctaSection\"],[[30,9]]],null],[1,\"\\n  \"],[8,[39,9],null,null,null],[1,\"\\n\"],[13]],[\"@bgImg\",\"@heroSection\",\"@customizedDevelopment\",\"@bulletList\",\"@whyChoose\",\"@useCases\",\"@data\",\"@faqs\",\"@areYouReady\"],false,[\"header\",\"ai-development/hero-section\",\"chatgpt/getting-started\",\"services/bullets-list\",\"chatgpt/benefits\",\"chatgpt/chatgptuse\",\"homepage/testimonial\",\"services/faq-list\",\"technologies/cta-section\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/services/ai-chatbot/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});