define("primathon-ember-web/components/pages/about-us/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Header />
  <div class='app-container'>
    <AboutUs::HeroSection />
    <AboutUs::OurStory />
    <AboutUs::WhoWeAre />
    <AboutUs::MeetOurFounders />
    <AboutUs::WeBelieve />
    <Homepage::HireUs />
    <Footer />
  </div>
  */
  {
    "id": "t/4YyyMT",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[8,[39,2],null,null,null],[1,\"\\n  \"],[8,[39,3],null,null,null],[1,\"\\n  \"],[8,[39,4],null,null,null],[1,\"\\n  \"],[8,[39,5],null,null,null],[1,\"\\n  \"],[8,[39,6],null,null,null],[1,\"\\n  \"],[8,[39,7],null,null,null],[1,\"\\n\"],[13]],[],false,[\"header\",\"about-us/hero-section\",\"about-us/our-story\",\"about-us/who-we-are\",\"about-us/meet-our-founders\",\"about-us/we-believe\",\"homepage/hire-us\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/about-us/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});