define("primathon-ember-web/controllers/services/end-to-end-products/admin-panels-and-cms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServicesEndToEndProductsAdminPanelsAndCmsController extends Ember.Controller {
    get faqTitle() {
      return this.model.faqData.title;
    }

    get faqData() {
      return this.model.faqData.data;
    }

    get adminPanelAndCmsTitle() {
      return this.model.adminPanelAndCms.title;
    }

    get adminPanelAndCmsSubHeading() {
      return this.model.adminPanelAndCms.sub_heading;
    }

    get adminPanelsAndCmsAbout() {
      return this.model.adminPanelAndCms.about;
    }

    get adminPanelAndCmsDescription() {
      return this.model.adminPanelAndCms.description;
    }

    get adminPanelAndCmsImg() {
      return this.model.adminPanelAndCms.imgUrl;
    }

    get adminPanelAndCmsBG() {
      return this.model.adminPanelAndCms.bg;
    }

    get adminPanelAndCmsCircularList() {
      return this.model.adminPanelAndCms.circular_list;
    }

    get adminPanelAndCmsArrowList() {
      return this.model.adminPanelAndCms.arrow_list;
    }

    get adminPanelAndCmsLinearList() {
      return this.model.adminPanelAndCms.linear_list;
    }

  }

  _exports.default = ServicesEndToEndProductsAdminPanelsAndCmsController;
});