define("primathon-ember-web/components/services/hero-section/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="services-hero {{if @bg (concat 'bg-' @bg)}}">
    <div class="services-responsive-container">
      {{yield}}
    </div>
  </section>
  */
  {
    "id": "xl7ekY9Y",
    "block": "[[[10,\"section\"],[15,0,[29,[\"services-hero \",[52,[30,1],[28,[37,1],[\"bg-\",[30,1]],null]]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"services-responsive-container\"],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@bg\",\"&default\"],false,[\"if\",\"concat\",\"yield\"]]",
    "moduleName": "primathon-ember-web/components/services/hero-section/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});