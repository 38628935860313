define("primathon-ember-web/controllers/services/end-to-end-products/ai-ml-based-recommendation-engines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServicesEndToEndProductsAiMlBasedRecommendationEnginesController extends Ember.Controller {
    get faqTitle() {
      return this.model.faqData.title;
    }

    get faqData() {
      return this.model.faqData.data;
    }

    get aiMlBasedRecommendationEnginesAbout() {
      return this.model.aiMl.about;
    }

    get aiMlBasedRecommendationEnginesTitle() {
      return this.model.aiMl.title;
    }

    get aiMlBasedRecommendationEnginesDescription() {
      return this.model.aiMl.description;
    }

    get aiMlBasedRecommendationEnginesImg() {
      return this.model.aiMl.imgUrl;
    }

    get aiMlBasedRecommendationEnginesBg() {
      return this.model.aiMl.bg;
    }

    get aiMlBasedRecommendationEnginesCircularList() {
      return this.model.aiMl.circular_list;
    }

    get aiMlBasedRecommendationEnginesArrowList() {
      return this.model.aiMl.arrow_list;
    }

    get aiMlBasedRecommendationEnginesLinearList() {
      return this.model.aiMl.linear_list;
    }

  }

  _exports.default = ServicesEndToEndProductsAiMlBasedRecommendationEnginesController;
});