define("primathon-ember-web/templates/case-studies/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9gt+6Jrq",
    "block": "[[[8,[39,0],null,null,null],[8,[39,1],null,null,null]],[],false,[\"header\",\"pages/case-studies\"]]",
    "moduleName": "primathon-ember-web/templates/case-studies/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});