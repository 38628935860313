define("primathon-ember-web/templates/sitemap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3MoJahou",
    "block": "[[[8,[39,0],null,[[\"@headerLink\",\"@endToEndProducts\",\"@aiHub\",\"@solutions\",\"@staffAugmentation\",\"@technologies\"],[[30,1,[\"sitemapData\",\"headerLink\"]],[30,1,[\"sitemapData\",\"endToEndProducts\"]],[30,1,[\"sitemapData\",\"aiHub\"]],[30,1,[\"sitemapData\",\"solutions\"]],[30,1,[\"sitemapData\",\"staffAugmentation\"]],[30,1,[\"sitemapData\",\"technologies\"]]]],null]],[\"@model\"],false,[\"pages/sitemap\"]]",
    "moduleName": "primathon-ember-web/templates/sitemap.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});