define("primathon-ember-web/templates/case-studies/case-study", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "66yV4zw2",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[41,[30,1,[\"isNewCaseStudy\"]],[[[1,\"  \"],[8,[39,2],null,[[\"@data\"],[[30,1]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,3],null,[[\"@data\"],[[30,1]]],null],[1,\"\\n\"]],[]]]],[\"@model\"],false,[\"header\",\"if\",\"pages/new-case-study\",\"pages/case-study-blog\"]]",
    "moduleName": "primathon-ember-web/templates/case-studies/case-study.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});