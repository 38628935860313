define("primathon-ember-web/components/services/staff-augmentation-services-tech/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='staff-augmentation-services-tech'>
    {{#if @data}}
      <h2>{{@heading}}</h2>
      <div class='tech-logo-wrapper'>
        {{#each @data as |data|}}
          <div class='tech-logo'>
            <div class='logo-img-wrapper'>
              <Common::AppImage @src={{data.imgUrl}} alt={{data.title}} />
            </div>
            <figcaption class='tech-logo-caption'>{{data.title}}</figcaption>
          </div>
        {{/each}}
      </div>
    {{/if}}
    <LinkTo @route='contact'>
      <button type='button' class='what-are-you-looking-button'>
        <p class='what-are-you-looking-button-text'>
          SHARE YOUR REQUIREMENTS
        </p>
      </button>
    </LinkTo>
  </section>
  */
  {
    "id": "bg6MglzP",
    "block": "[[[10,\"section\"],[14,0,\"staff-augmentation-services-tech\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,\"h2\"],[12],[1,[30,2]],[13],[1,\"\\n    \"],[10,0],[14,0,\"tech-logo-wrapper\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"tech-logo\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"logo-img-wrapper\"],[12],[1,\"\\n            \"],[8,[39,3],[[16,\"alt\",[30,3,[\"title\"]]]],[[\"@src\"],[[30,3,[\"imgUrl\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"figcaption\"],[14,0,\"tech-logo-caption\"],[12],[1,[30,3,[\"title\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[8,[39,4],null,[[\"@route\"],[\"contact\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"button\"],[14,0,\"what-are-you-looking-button\"],[14,4,\"button\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"what-are-you-looking-button-text\"],[12],[1,\"\\n        SHARE YOUR REQUIREMENTS\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@data\",\"@heading\",\"data\"],false,[\"if\",\"each\",\"-track-array\",\"common/app-image\",\"link-to\"]]",
    "moduleName": "primathon-ember-web/components/services/staff-augmentation-services-tech/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});