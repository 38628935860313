define("primathon-ember-web/helpers/download-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const downloadFile = (url, fileName) => {
    let link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  var _default = downloadFile;
  _exports.default = _default;
});