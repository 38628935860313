define("primathon-ember-web/templates/services/staff-augmentation/quality-analysts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ESnmdj2",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@subHeading\",\"@about\",\"@imgData\",\"@bg\",\"@circular_list\"],[[30,0,[\"qualityAnalystsTitle\"]],[30,0,[\"qualityAnalystsSubHeading\"]],[30,0,[\"qualityAnalystsAbout\"]],[30,0,[\"qualityAnalystsData\"]],[30,0,[\"qualityAnalystsBg\"]],[30,0,[\"qualityAnalystsCircularList\"]]]],null],[1,\"\\n\"]],[],false,[\"pages/services/staff-augmentation-services\"]]",
    "moduleName": "primathon-ember-web/templates/services/staff-augmentation/quality-analysts.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});