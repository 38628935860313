define("primathon-ember-web/helpers/fired-gtag-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function firedGtagEvent(_ref) {
    let [eventName] = _ref;

    if (window.gtag) {
      return () => {
        window.gtag('event', eventName);
      };
    }

    return () => {};
  });

  _exports.default = _default;
});