define("primathon-ember-web/components/pages/services/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Header />
  <div class="app-container">
    <Services::HeroStaffAugmentation />
    <Services::CircularList @content={{this.circular_list_content}} />
    <Services::LinearList />
    <Services::TopNotchTalent />
    <Services::ArrowList @content={{this.arrow_list_content}} />
    <Services::ToolsOfTrades />
    <Services::WeAreTrusted />
    <Homepage::HireUs />
    <Services::BrowseThroughServices />
    <Footer />
  </div>
  */
  {
    "id": "u5QAqTC3",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[8,[39,2],null,[[\"@content\"],[[30,0,[\"circular_list_content\"]]]],null],[1,\"\\n  \"],[8,[39,3],null,null,null],[1,\"\\n  \"],[8,[39,4],null,null,null],[1,\"\\n  \"],[8,[39,5],null,[[\"@content\"],[[30,0,[\"arrow_list_content\"]]]],null],[1,\"\\n  \"],[8,[39,6],null,null,null],[1,\"\\n  \"],[8,[39,7],null,null,null],[1,\"\\n  \"],[8,[39,8],null,null,null],[1,\"\\n  \"],[8,[39,9],null,null,null],[1,\"\\n  \"],[8,[39,10],null,null,null],[1,\"\\n\"],[13]],[],false,[\"header\",\"services/hero-staff-augmentation\",\"services/circular-list\",\"services/linear-list\",\"services/top-notch-talent\",\"services/arrow-list\",\"services/tools-of-trades\",\"services/we-are-trusted\",\"homepage/hire-us\",\"services/browse-through-services\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/services/index.hbs",
    "isStrictMode": false
  });

  let PagesServicesIndexComponent = (_dec = Ember._tracked, (_class = class PagesServicesIndexComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "SERVICE_PAGES", {
        STAFF_AUGMENTATION: 'staff-augmentation',
        END_TO_END_PRODUCTS: 'end-to-end-products',
        BUILD_AND_MANAGE: 'build-and-manage'
      });

      _initializerDefineProperty(this, "_currentServicePage", _descriptor, this);
    }

    get circular_list_content() {
      if (this._currentServicePage === this.SERVICE_PAGES.END_TO_END_PRODUCTS) {
        return {
          heading: 'Why your business needs Staff Augmentation?',
          items: ['Agile Methadology', 'On time delivery', 'User Centric Approach', 'Highly Qualified and Experienced Team']
        };
      } else if (this._currentServicePage === this.SERVICE_PAGES.BUILD_AND_MANAGE) {
        return {
          heading: 'Why your business needs Staff Augmentation?',
          items: ['Agile Methadology', 'On time delivery', 'User Centric Approach', 'Highly Qualified and Experienced Team']
        };
      }

      return {
        heading: 'Why your business needs Staff Augmentation?',
        items: ['Cost Effective', 'No training required', 'Flexible', 'Saves time ']
      };
    }

    get arrow_list_content() {
      if (this._currentServicePage === this.SERVICE_PAGES.END_TO_END_PRODUCTS) {
        return {
          heading: 'Scale up your team with Primathon!',
          items: ['We get in touch with you', 'You share your requirements', 'We curate the best fit for your requirements', 'You shortlist and we start working instantly!']
        };
      } else if (this._currentServicePage === this.SERVICE_PAGES.BUILD_AND_MANAGE) {
        return {
          heading: 'Scale up your team with Primathon!',
          items: ['We get in touch with you', 'You share your requirements', 'We curate the best fit for your requirements', 'You shortlist and we start working instantly!']
        };
      }

      return {
        heading: 'Scale up your team with Primathon!',
        items: ['We get in touch with you', 'You share your requirements', 'We curate the best fit for your requirements', 'You shortlist and we start working instantly!']
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_currentServicePage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.pageType ? this.args.pageType : this.SERVICE_PAGES.STAFF_AUGMENTATION;
    }
  })), _class));
  _exports.default = PagesServicesIndexComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PagesServicesIndexComponent);
});