define("primathon-ember-web/templates/services/staff-augmentation/hire-ember-js-developers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pqtH5iLq",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@heroSection\",\"@heroSectionImgStyle\",\"@heroSectionClass\",\"@elevateYourProject\",\"@elevateYourProjectImgStyle\",\"@ctaSection\",\"@useCases\",\"@whyChoose\",\"@journey\",\"@developmentStrategy\",\"@developmentStrategyImgStyle\",\"@benefits\",\"@table\",\"@blogData\",\"@caseStudies\",\"@faqs\"],[[30,1],[30,1,[\"data\",\"heroSection\"]],\"w-[560px] xl:w-[620px]\",\"bg-[#1A284D] lg:pr-0\",[30,1,[\"data\",\"elevateYourProject\"]],\"w-[360px] h-[300px] sm:w-[525px] sm:h-[500px] xl:w-[698px] xl:h-[600px]\",[30,1,[\"data\",\"ctaSection\"]],[30,1,[\"data\",\"emberDevelopmentServices\"]],[30,1,[\"data\",\"whyChoose\"]],[30,1,[\"data\",\"emberJourney\"]],[30,1,[\"data\",\"emberDevelopmentStrategy\"]],\"w-[480px] h-[480px] xl:w-[560px] xl:h-[500px]\",[30,1,[\"data\",\"benefitsOfHiringEmber\"]],[30,1,[\"data\",\"table\"]],[30,1,[\"blogData\"]],[30,1,[\"data\",\"caseStudies\"]],[30,1,[\"data\",\"faqs\"]]]],null]],[\"@model\"],false,[\"pages/services/staff-augmentation-services-new\"]]",
    "moduleName": "primathon-ember-web/templates/services/staff-augmentation/hire-ember-js-developers.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});