define("primathon-ember-web/components/case-study-blog/our-strategy/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section
    class='flex flex-col md:flex-row gap-12 items-center justify-center px-6 md:px-16 max-w-[1600px] mx-auto mt-16 md:mt-28 mb-8'
  >
    <Common::AppImage
      @src='/assets/images/iPhone-15-pro.png'
      alt='freshworks'
      title='freshworks'
      class='w-[350px] h-[350px] lg:w-[380px] xl:w-[400px] lg:h-[350px]'
    />
    <div class='flex-1'>
      <div class='bullet-container'>
        <ul>
          {{#each @data.data as |item|}}
            <li class='mb-4'>
              <span class='!font-semibold'>{{item.title}}:</span>
              <span class=' text-gray-500'>{{item.description}}</span>
            </li>
          {{/each}}
        </ul>
      </div>
    </div>
  </section>
  */
  {
    "id": "vdrYZuBF",
    "block": "[[[10,\"section\"],[14,0,\"flex flex-col md:flex-row gap-12 items-center justify-center px-6 md:px-16 max-w-[1600px] mx-auto mt-16 md:mt-28 mb-8\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,\"alt\",\"freshworks\"],[24,\"title\",\"freshworks\"],[24,0,\"w-[350px] h-[350px] lg:w-[380px] xl:w-[400px] lg:h-[350px]\"]],[[\"@src\"],[\"/assets/images/iPhone-15-pro.png\"]],null],[1,\"\\n  \"],[10,0],[14,0,\"flex-1\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"bullet-container\"],[12],[1,\"\\n      \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"data\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[14,0,\"mb-4\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"!font-semibold\"],[12],[1,[30,2,[\"title\"]]],[1,\":\"],[13],[1,\"\\n            \"],[10,1],[14,0,\" text-gray-500\"],[12],[1,[30,2,[\"description\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@data\",\"item\"],false,[\"common/app-image\",\"each\",\"-track-array\"]]",
    "moduleName": "primathon-ember-web/components/case-study-blog/our-strategy/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});