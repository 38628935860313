define("primathon-ember-web/components/open-position/job-data/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='jobData'>
    <div class='jobRow head'>
      <span>
        Job Title
      </span>
      <span>
        Department
      </span>
      <span>
        Experience
      </span>
      <span>
        Location
      </span>
      <span>
        Job Type      
      </span>
    </div>
  
    <OpenPosition::Filter @data={{@data}} @query={{@query}} as |results|>
      {{#each results as |job|}}
        <OpenPosition::Job-Row
          @title={{job.jobtitle}}
          @department={{job.department}}
          @experience={{job.experience}}
          @location={{job.location}}
          @jobType={{job.job_type}}
          @id={{job.uid}}
        />
      {{/each}}
    </OpenPosition::Filter>
  </div>
  */
  {
    "id": "XyCo7f9p",
    "block": "[[[10,0],[14,0,\"jobData\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"jobRow head\"],[12],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      Job Title\\n    \"],[13],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      Department\\n    \"],[13],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      Experience\\n    \"],[13],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      Location\\n    \"],[13],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      Job Type      \\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@data\",\"@query\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,3]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@title\",\"@department\",\"@experience\",\"@location\",\"@jobType\",\"@id\"],[[30,4,[\"jobtitle\"]],[30,4,[\"department\"]],[30,4,[\"experience\"]],[30,4,[\"location\"]],[30,4,[\"job_type\"]],[30,4,[\"uid\"]]]],null],[1,\"\\n\"]],[4]],null],[1,\"  \"]],[3]]]]],[1,\"\\n\"],[13]],[\"@data\",\"@query\",\"results\",\"job\"],false,[\"open-position/filter\",\"each\",\"-track-array\",\"open-position/job-row\"]]",
    "moduleName": "primathon-ember-web/components/open-position/job-data/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});