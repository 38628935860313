define("primathon-ember-web/templates/services/end-to-end-products/shopify-stores", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vKXjueBT",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@heroSection\",\"@heroSectionImgStyle\",\"@heroSectionClass\",\"@services\",\"@benefits\",\"@useCases\",\"@bulletsListImgStyle\",\"@features3\",\"@faqs\",\"@whyChoosePrimathon\",\"@approachToShopifyDevelopment\"],[[30,1],[30,1,[\"data\",\"heroSection\"]],\"w-[590px] h-[520px] xl:w-[630px] xl:h-[550px]\",\"bg-[#1A284D] lg:pr-0\",[30,1,[\"data\",\"dataVisualizationDevelopmentServices\"]],[30,1,[\"data\",\"whyChoose\"]],[30,1,[\"data\",\"whatWeOffer\"]],\"w-[400px] h-[370px] xl:w-[420px] xl:h-[390px]\",[30,1,[\"data\",\"enhanceDecisionMaking\"]],[30,1,[\"data\",\"faqs\"]],[30,1,[\"data\",\"whyChoosePrimathon\"]],[30,1,[\"data\",\"approachToShopifyDevelopment\"]]]],null]],[\"@model\"],false,[\"pages/solutions\"]]",
    "moduleName": "primathon-ember-web/templates/services/end-to-end-products/shopify-stores.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});