define("primathon-ember-web/components/pages/services/staff-augmentation-services/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Header />
  <div class='app-container'>
    <Services::TemplateHeroSectionServices
      @heading={{@about}}
      @title={{@title}}
      @bg={{@bg}}
      @subHeading={{@subHeading}}
    />
  
    <Services::CircularList @content={{@circular_list}} />
    <Services::LinearList @content={{this.linear_list_content}} />
    <Services::TopNotchTalent />
    <Services::ArrowList @content={{this.arrow_list_content}} />
    <Services::StaffAugmentationServicesTech @heading={{@title}} @data={{@imgData}} />
    <Services::ToolsOfTrades />
    <Services::WeAreTrusted />
    <Homepage::HireUs />
    <Services::BrowseThroughServices @activeService={{1}} />
    <Footer />
  </div>
  */
  {
    "id": "YGnKWq0y",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@heading\",\"@title\",\"@bg\",\"@subHeading\"],[[30,1],[30,2],[30,3],[30,4]]],null],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@content\"],[[30,5]]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@content\"],[[30,0,[\"linear_list_content\"]]]],null],[1,\"\\n  \"],[8,[39,4],null,null,null],[1,\"\\n  \"],[8,[39,5],null,[[\"@content\"],[[30,0,[\"arrow_list_content\"]]]],null],[1,\"\\n  \"],[8,[39,6],null,[[\"@heading\",\"@data\"],[[30,2],[30,6]]],null],[1,\"\\n  \"],[8,[39,7],null,null,null],[1,\"\\n  \"],[8,[39,8],null,null,null],[1,\"\\n  \"],[8,[39,9],null,null,null],[1,\"\\n  \"],[8,[39,10],null,[[\"@activeService\"],[1]],null],[1,\"\\n  \"],[8,[39,11],null,null,null],[1,\"\\n\"],[13]],[\"@about\",\"@title\",\"@bg\",\"@subHeading\",\"@circular_list\",\"@imgData\"],false,[\"header\",\"services/template-hero-section-services\",\"services/circular-list\",\"services/linear-list\",\"services/top-notch-talent\",\"services/arrow-list\",\"services/staff-augmentation-services-tech\",\"services/tools-of-trades\",\"services/we-are-trusted\",\"homepage/hire-us\",\"services/browse-through-services\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/services/staff-augmentation-services/index.hbs",
    "isStrictMode": false
  });

  class PagesServicesStaffAugmentationServicesIndexComponent extends _component.default {
    get circular_list_content() {
      return {
        heading: 'Why choose us for your project?',
        items: ['Agile Methodology', 'On time delivery', 'User Centric Approach', 'Highly Qualified and Experienced Team']
      };
    }

    get linear_list_content() {
      return {
        heading: 'Why your buisness needs Staff Augmmentation?',
        items: ['Flexible', 'Cost Effective', 'Saves time', 'No training required']
      };
    }

    get arrow_list_content() {
      return {
        heading: 'Scale up your team with Primathon!',
        items: ['We get in touch with you', 'You share your requirements', 'We curate the best fit for your requirements', 'You shortlist and we start working instantly!']
      };
    }

    get serviceTitle() {
      return 'Browse through other services that we provide';
    }

  }

  _exports.default = PagesServicesStaffAugmentationServicesIndexComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PagesServicesStaffAugmentationServicesIndexComponent);
});