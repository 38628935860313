define("primathon-ember-web/components/services/hire-developer/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='px-6 py-10 sm:py-24 sm:px-16 xl:p-24'>
    <div class='max-w-[1400px] mx-auto'>
      <h2 class='text-2xl sm:text-3xl text-center font-semibold'>{{@data.title}}</h2>
      <p class='text-xl text-[#3F3F3F] text-center my-8 max-w-[1100px] mx-auto'>{{@data.description}}</p>
      <div class='flex flex-wrap gap-x-8 gap-y-12 justify-center'>
        {{#each @data.cardData as |data|}}
          <div key={{data.id}} class='p-8 border-solid border-2 border-slate-200 rounded-md shadow-xl'>
            <h3 class='text-xl mb-3 font-medium'>{{data.jobTitle}}</h3>
            <ul class='yellow-list-ul mb-6'>
              <li>{{data.experience}}</li>
              <li>{{data.about}}</li>
              <li>{{data.completeProject}}</li>
            </ul>
            <a href={{data.ctaRoute}}>
              <button
                {{on 'click' (if @data.gtagEventName (fired-gtag-event @data.gtagEventName) (fn (noop)))}}
                class='transition-colors rounded font-medium text-[#2878c8] py-3 px-9 bg-[#f1f4f8] hover:bg-[#ccd7e5] w-full'
              >
                {{data.ctaText}}
              </button>
            </a>
          </div>
        {{/each}}
      </div>
    </div>
  </section>
  */
  {
    "id": "+54aDDVy",
    "block": "[[[10,\"section\"],[14,0,\"px-6 py-10 sm:py-24 sm:px-16 xl:p-24\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"max-w-[1400px] mx-auto\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"text-2xl sm:text-3xl text-center font-semibold\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n    \"],[10,2],[14,0,\"text-xl text-[#3F3F3F] text-center my-8 max-w-[1100px] mx-auto\"],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-wrap gap-x-8 gap-y-12 justify-center\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"cardData\"]]],null]],null],null,[[[1,\"        \"],[10,0],[15,\"key\",[30,2,[\"id\"]]],[14,0,\"p-8 border-solid border-2 border-slate-200 rounded-md shadow-xl\"],[12],[1,\"\\n          \"],[10,\"h3\"],[14,0,\"text-xl mb-3 font-medium\"],[12],[1,[30,2,[\"jobTitle\"]]],[13],[1,\"\\n          \"],[10,\"ul\"],[14,0,\"yellow-list-ul mb-6\"],[12],[1,\"\\n            \"],[10,\"li\"],[12],[1,[30,2,[\"experience\"]]],[13],[1,\"\\n            \"],[10,\"li\"],[12],[1,[30,2,[\"about\"]]],[13],[1,\"\\n            \"],[10,\"li\"],[12],[1,[30,2,[\"completeProject\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,3],[15,6,[30,2,[\"ctaRoute\"]]],[12],[1,\"\\n            \"],[11,\"button\"],[24,0,\"transition-colors rounded font-medium text-[#2878c8] py-3 px-9 bg-[#f1f4f8] hover:bg-[#ccd7e5] w-full\"],[4,[38,2],[\"click\",[52,[30,1,[\"gtagEventName\"]],[28,[37,4],[[30,1,[\"gtagEventName\"]]],null],[28,[37,5],[[28,[37,6],null,null]],null]]],null],[12],[1,\"\\n              \"],[1,[30,2,[\"ctaText\"]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@data\",\"data\"],false,[\"each\",\"-track-array\",\"on\",\"if\",\"fired-gtag-event\",\"fn\",\"noop\"]]",
    "moduleName": "primathon-ember-web/components/services/hire-developer/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});