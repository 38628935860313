define("primathon-ember-web/templates/solutions/search-gpt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M/rEmZC0",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@heroSection\",\"@heroSectionImgStyle\",\"@heroSectionClass\",\"@services\",\"@benefits\",\"@useCases\",\"@bulletsList\",\"@bulletsListImgStyle\",\"@applications\",\"@caseStudies\",\"@features\",\"@faqs\"],[[30,1],[30,1,[\"data\",\"heroSection\"]],\"w-[560px] h-[500px] xl:w-[550px] xl:h-[530px]\",\"bg-[#1A284D] lg:pr-0\",[30,1,[\"data\",\"searchGptDevelopmentServices\"]],[30,1,[\"data\",\"howSearchGptWorks\"]],[30,1,[\"data\",\"specificationOfsearchGpt\"]],[30,1,[\"data\",\"aiDocumentProcessing\"]],\"w-[350px] h-[350px] xl:w-[420px] xl:h-[380px]\",[30,1,[\"data\",\"applicationsOfSearchGpt\"]],[30,1,[\"data\",\"caseStudies\"]],[30,1,[\"data\",\"featuresOfPrimathonSearchGpt\"]],[30,1,[\"data\",\"faqs\"]]]],null]],[\"@model\"],false,[\"pages/solutions\"]]",
    "moduleName": "primathon-ember-web/templates/solutions/search-gpt.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});