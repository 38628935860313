define("primathon-ember-web/templates/technologies/android", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GuTIk1kc",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@bgImg\",\"@heroSection\",\"@customizedDevelopment\",\"@whyChoose\",\"@primathonApproach\",\"@nextLevelDevelopment\",\"@advantagesOfDevelopment\",\"@commenceYourJourney\",\"@driveInnovation\"],[[30,1],\"android-heroSectionContainer-bgImg\",[30,1,[\"androidData\",\"heroSection\"]],[30,1,[\"androidData\",\"customizedAndriodDevelopment\"]],[30,1,[\"androidData\",\"whyChoose\"]],[30,1,[\"androidData\",\"primathonApproach\"]],[30,1,[\"androidData\",\"nextLevelWebDevelopment\"]],[30,1,[\"androidData\",\"advantagesOfAndroidDevelopment\"]],[30,1,[\"androidData\",\"commenceYourJourneyOfAndroidDevelopment\"]],[30,1,[\"androidData\",\"driveInnovation\"]]]],null]],[\"@model\"],false,[\"pages/services/staff-augmentation-new-services\"]]",
    "moduleName": "primathon-ember-web/templates/technologies/android.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});