define("primathon-ember-web/constants/technologies/react-native/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.REACT_NATIVE = void 0;
  const REACT_NATIVE = {
    heroSection: {
      title: 'Best React Native Development Services',
      subTitle: 'The Future Is Here! Help Your Business Reach the Sky With Custom React Native Software Development Company',
      buttonData: 'Get a React Native Developer',
      redirect: '/contact'
    },
    unleashExcellenceWithPrimathon: {
      heading: "Unleash Excellence with Primathon's React Native Services",
      description: ["Unlock the full potential of your app vision with Primathon's React Native Services Our seasoned team is dedicated to transforming your ideas into cutting-edge mobile solutions. With a focus on innovation and efficiency, we streamline the development process, delivering cross-platform solutions tailored for native-like experiences backed by our unwavering commitment to excellence."],
      buttonText: 'Contact Us',
      buttonLink: '/contact',
      imageSrc: '/assets/react-native/unleash-excellence.png'
    },
    tailoredReactNativeSolution: {
      title: 'Our Offerings - Tailored',
      subTitle: 'React Native Solutions',
      cardData: [{
        id: '1',
        cardTitle: 'Custom React Native App Development',
        content: "Tailored solutions to meet our client's unique requirements, leveraging the power of React Native for iOS and Android platforms."
      }, {
        id: '2',
        cardTitle: 'Cross-Platform Development',
        content: "Developing mobile applications that work seamlessly across multiple platforms, ensuring wider reach and accessibility for our clients' target audiences."
      }, {
        id: '3',
        cardTitle: 'Integration with Backend Systems',
        content: 'Integrating React Native applications with backend systems and databases, enabling real-time data synchronization and enhanced functionality.'
      }, {
        id: '4',
        cardTitle: 'Maintenance and Support',
        content: 'Providing ongoing maintenance and support services to ensure the smooth operation and performance optimization of React Native applications post-launch.'
      }, {
        id: '5',
        cardTitle: 'Migration Services',
        content: 'Assisting clients in migrating their existing mobile applications to React Native, ensuring a seamless transition and minimal disruption to their business operations.'
      }, {
        id: '6',
        cardTitle: 'Consultation and Strategy',
        content: 'Offering expert consultation and strategic guidance to clients at every stage of the development process, from concept ideation to deployment and beyond'
      }, {
        id: '7',
        cardTitle: 'Performance Optimization',
        content: 'Optimizing React Native applications for performance, scalability, and responsiveness, ensuring optimal user experience across devices and network conditions'
      }, {
        id: '8',
        cardTitle: 'Testing and Quality Assurance',
        content: 'Conducting comprehensive testing and quality assurance measures to identify and address any bugs or issues before deployment, ensuring the reliability and stability of React Native applications.'
      }]
    },
    whyChoose: {
      title: 'Why Partner with Primathon for React Native Development?',
      cardData: [{
        id: 1,
        imageSrc: '/assets/react-native/cutting-edge-solutions.png',
        cardTitle: 'Cutting-Edge Solutions',
        cardData: 'Our expertise provides cutting-edge solutions tailored to your specific requirements. To create cross-platform apps that meet your unique needs, our team prioritizes creativity and innovation.'
      }, {
        id: 2,
        imageSrc: '/assets/react-native/quality-assurance.png',
        cardTitle: 'Quality assurance',
        cardData: 'We conduct rigorous testing and quality assurance measures to identify and address any issues before deployment, ensuring the reliability and stability of your React Native applications.'
      }, {
        id: 3,
        imageSrc: '/assets/react-native/customer-centric-approach.png',
        cardTitle: 'Customer-Centric Approach',
        cardData: 'We prioritize customer satisfaction and strive to exceed your expectations at every turn. Our team is dedicated to delivering solutions that meet your unique business needs and objectives, ensuring a partnership built on trust and reliability. <a href="https://primathon.in/services/staff-augmentation/hire-react-js-developers" style="color:#ffffff">Hire React.js developers</a> from our team to craft and enhance the digital footprint of your web applications.'
      }]
    },
    elevateYourWebDevelopment: {
      title: 'Partner with Primathon for React Native development and experience excellence in every aspect of your project.',
      description: 'Contact us today to empower your app journey.',
      ctaText: 'Contact Us',
      ctaRoute: 'contact'
    },
    approachToReactNativeDevelopment: {
      heading: 'Primathon’s Approach to React Native Development',
      description: ['Primathon offers flexible solutions tailored to your React Native development needs. From augmenting your team to full project management, we adapt to your requirements. Our expert team ensures high-performance applications using cutting-edge technologies. With clear communication and a client-centric focus, we deliver exceptional results, driving your business forward.'],
      buttonText: 'Contact Us',
      buttonLink: '/contact',
      imageSrc: '/assets/react-native/approach-to-react-native.png'
    },
    advantageOfReactNativeDevelopment: {
      title: 'The Primathon Advantage in',
      subTitle: 'React Native Development',
      cardData: [{
        id: '1',
        cardTitle: 'Expertise',
        content: 'Our team boasts extensive expertise in React Native development, leveraging industry best practices and cutting-edge technologies to deliver exceptional results.'
      }, {
        id: '2',
        cardTitle: 'Commitment to Excellence',
        content: 'We are dedicated to excellence in every aspect of our work, ensuring that our React Native applications are of the highest quality and meet the most stringent standards.'
      }, {
        id: '3',
        cardTitle: 'Innovation',
        content: 'We embrace innovation and continuously explore new possibilities in React Native development, pushing the boundaries to deliver innovative solutions that drive business success.'
      }]
    },
    startYourReactNativeProject: {
      title: 'Trust Primathon to drive your React Native projects to success with precision and expertise.',
      description: "Get in Touch to learn more about how our services can enhance your application's performance and user experience.",
      ctaText: 'Get in Touch',
      ctaRoute: 'contact'
    },
    driveInnovation: {
      heading: "Drive Innovation with Primathon's React Native Services",
      description: ["Choose Primathon for your React Native development needs and embark on a journey of innovation, efficiency, and growth. Our unwavering commitment to excellence guarantees that your web applications are not only tailored for today's requirements but also future-proofed to tackle tomorrow's challenges head-on. With Primathon as your partner, you'll harness the power of cutting-edge technology and forward-thinking solutions to drive innovation and propel your business to new heights."],
      buttonText: 'Contact Us',
      buttonLink: '/contact',
      imageSrc: '/assets/react-native/drive-innovation.png'
    }
  };
  _exports.REACT_NATIVE = REACT_NATIVE;
});