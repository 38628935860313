define("primathon-ember-web/components/case-studies/post/tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button class="tag" type="button" ...attributes>
    {{@text}}
  </button>
  */
  {
    "id": "K49Ho5Zy",
    "block": "[[[11,\"button\"],[24,0,\"tag\"],[24,4,\"button\"],[17,1],[12],[1,\"\\n  \"],[1,[30,2]],[1,\"\\n\"],[13]],[\"&attrs\",\"@text\"],false,[]]",
    "moduleName": "primathon-ember-web/components/case-studies/post/tags.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});