define("primathon-ember-web/constants/services/ai-hub/ai-integration/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AI_INTEGRATION = void 0;
  const AI_INTEGRATION = {
    heroSection: {
      gradientTitle: 'Expert Seamless AI Integration Services',
      subtitle: 'Get Seamless AI Integration for Business Efficiency with Primathon',
      description: "In today's rapidly evolving digital landscape, businesses are constantly seeking innovative solutions to stay ahead of the curve and gain a competitive edge. At Primathon, we understand the transformative power of artificial intelligence (AI) and its ability to revolutionize industries across the board. That's why we offer comprehensive AI integration services designed to help so that businesses can use the full potential of this cutting-edge technology. At Primathon, we assist companies with a variety of AI integration solutions, including",
      listItems: [{
        id: 1,
        text: 'Strategic Planning and Assessment'
      }, {
        id: 2,
        text: 'Custom AI Model Development'
      }, {
        id: 3,
        text: 'Data Strategy and Preparation'
      }, {
        id: 4,
        text: 'Integration with Existing Systems'
      }, {
        id: 3,
        text: 'Training and Support'
      }, {
        id: 6,
        text: 'Continuous Improvement'
      }],
      ctaText1: 'Book a Call',
      ctaText2: 'Get Free Consultation',
      redirect: '/contact',
      imageSrc: '/assets/ai-integration/ai-integration-hero-image.png',
      imageAlt: 'Ai Integration'
    },
    whyAiIntegrationMatters: {
      title: 'Why Does AI Integration Matters?',
      description: "AI has emerged as a game-changer for businesses, offering a variety of opportunities to streamline operations, enhance decision-making processes, and deliver unparalleled customer experiences. From predictive analytics and machine learning algorithms to natural language processing and computer vision, AI technologies have the potential to drive innovation and fuel growth in virtually every industry. However, integrating AI into existing business processes can be a complex and challenging endeavor. Many organizations struggle with issues such as data silos, legacy systems, and lack of expertise, preventing them from fully realizing the benefits of AI. That's where Primathon comes in",
      imageSrc: '/assets/ai-integration/ai-Integration-matters.png',
      alt: 'What Makes AI Integration Matters'
    },
    ourApproach: {
      title: 'Our AI Integration Approach',
      description: 'At Primathon, we take a holistic approach to AI integration, focusing on understanding your unique business needs and objectives. Our team optimizes customer interactions through AI Integration by working closely with you to develop tailored solutions that align with your strategic goals and drive tangible results',
      imageSrc: '/assets/ai-integration/our-ai-Integration-approach.png',
      alt: 'What Makes AI Integration Matters'
    },
    aiIntegrationServices: {
      title: 'Offering Tailored AI Integration Services for Business Excellence',
      cardData: [{
        id: 1,
        imageSrc: '/assets/ai-integration/planning-and-consulting.svg',
        cardTitle: 'Strategic Planning and Consulting',
        content: 'We begin by conducting a thorough assessment of your current business processes, technology infrastructure, and AI readiness. Based on our findings, we develop a customized AI integration roadmap that outlines key objectives, milestones, and success metrics.'
      }, {
        id: 2,
        imageSrc: '/assets/ai-integration/strategy-and-preparation.svg',
        cardTitle: 'AI for Data Systems Strategy and Preparation',
        content: 'Data is the lifeblood of AI, and ensuring access to high-quality, relevant data is essential for successful integration. Our team helps you develop a robust data strategy, including data acquisition, cleansing, and preparation, to ensure that your AI systems have access to the information they need to deliver accurate and actionable insights.'
      }, {
        id: 3,
        imageSrc: '/assets/ai-integration/ai-model-development.svg',
        cardTitle: 'AI Model Development and Implementation',
        content: 'Leveraging state-of-the-art machine learning algorithms and AI frameworks, we develop custom AI models tailored to your specific business requirements. Whether you need predictive analytics for forecasting demand, image recognition for quality control, or virtual assistants for customer support, we have the expertise to bring your AI vision to life.'
      }, {
        id: 4,
        imageSrc: '/assets/ai-integration/integrating-ai.svg',
        cardTitle: 'Integrating AI with Existing IT',
        content: 'We understand that implementing AI solutions often requires integration with existing systems and workflows. Our team works seamlessly with your IT department to ensure smooth integration, minimizing disruption and maximizing ROI.'
      }, {
        id: 5,
        imageSrc: '/assets/ai-integration/training-and-support.svg',
        cardTitle: 'Training and Support',
        content: "Our commitment to your success doesn't end with implementation. We provide comprehensive training and ongoing support to ensure that your team has the knowledge and skills they need to leverage AI effectively. Whether you have questions, need troubleshooting assistance, or want to explore new use cases, our team is here to help every step of the way."
      }, {
        id: 6,
        imageSrc: '/assets/ai-integration/performance-monitoring-and-optimization.svg',
        cardTitle: 'Performance Monitoring and Optimization',
        content: 'After deployment, we continuously monitor the performance of your AI systems to ensure they are meeting your business objectives. Using advanced analytics and AI-driven insights, we identify areas for optimization and fine-tuning to maximize efficiency, accuracy, and ROI. Our proactive approach ensures that your AI solutions evolve with your business needs, maintaining peak performance over time.'
      }]
    },
    whyChoose: {
      title: 'Why Choose Primathon As Your AI Integration Service Partner?',
      cardData: [{
        id: '1',
        cardTitle: 'Expertise in AI Technologies',
        content: 'Primathon boasts a team of experts in artificial intelligence technologies. Their deep understanding and experience in machine learning, natural language processing, computer vision, and other AI domains ensure that they can deliver high-quality solutions tailored to your specific needs.'
      }, {
        id: '2',
        cardTitle: 'Customized Solutions',
        content: 'Primathon understands that one size does not fit all when it comes to AI integration. They work closely with clients to understand their unique challenges and requirements, crafting customized solutions that address their specific business objectives and deliver maximum value.'
      }, {
        id: '3',
        cardTitle: 'Proven Track Record',
        content: "With a proven track record of successful AI integration projects across various industries, Primathon demonstrates their ability to deliver results. Whether it's improving operational efficiency, enhancing customer experiences, or driving business growth, Primathon has a history of helping clients achieve their goals through AI."
      }, {
        id: '4',
        cardTitle: 'Scalability and Reliability',
        content: "Primathon's solutions are designed with scalability and reliability in mind. Whether you're a small startup or a large enterprise, Primathon's AI integration services can scale to meet your growing needs while ensuring robust performance and uptime."
      }, {
        id: '5',
        cardTitle: 'Focus on Ethical AI',
        content: 'Primathon prioritizes ethical considerations in AI development and integration. They adhere to best practices for data privacy, fairness, transparency, and accountability, ensuring that their solutions are not only effective but also responsible and trustworthy.'
      }, {
        id: '6',
        cardTitle: 'Comprehensive Support and Partnership',
        content: 'Beyond just delivering a solution, Primathon offers comprehensive support and partnership throughout the AI integration process and beyond. From initial consultation to ongoing maintenance and optimization, Primathon is committed to being a trusted advisor and partner on your AI journey.'
      }]
    },
    potentialBusiness: {
      title: 'Potential Business Outcomes That Comes with Our AI Integration Services',
      cardData: [{
        id: 1,
        imageSrc: '/assets/ai-integration/improved-efficiency-and-productivity.svg',
        cardTitle: 'Improved Efficiency and Productivity',
        cardData: "Primathon's AI integration services streamline workflows, automate repetitive tasks, and optimize processes, leading to significant improvements in efficiency and productivity. By automating mundane tasks, employees can focus on higher-value activities, leading to overall productivity gains."
      }, {
        id: 2,
        imageSrc: '/assets/ai-integration/enhanced-customer-experience.svg',
        cardTitle: 'Enhanced Customer Experience',
        cardData: 'Leveraging AI technologies, Primathon can personalize customer interactions, provide real-time support, and anticipate customer needs more effectively. This results in a superior customer experience, increased satisfaction, and higher retention rates.'
      }, {
        id: 3,
        imageSrc: '/assets/ai-integration/data-driven-insights.svg',
        cardTitle: 'Data-Driven Insights and Decision Making',
        cardData: "With Primathon's AI integration, businesses can unlock valuable insights hidden within their data. By analyzing large volumes of data quickly and accurately, AI empowers organizations to make data-driven decisions, identify trends, predict outcomes, and seize opportunities for growth."
      }, {
        id: 4,
        imageSrc: '/assets/ai-integration/cost-savings.svg',
        cardTitle: 'Cost Savings and Optimization',
        cardData: "By automating processes and improving operational efficiency, Primathon's AI integration services can help businesses reduce costs across various functions. Whether it's optimizing resource allocation, minimizing waste, or identifying areas for cost reduction, AI-driven solutions contribute to significant savings over time."
      }, {
        id: 5,
        imageSrc: '/assets/ai-integration/competitive-advantage.svg',
        cardTitle: 'Competitive Advantage and Innovation',
        cardData: "Embracing AI technologies positions businesses to stay ahead of the curve and outpace competitors. Primathon's AI integration services enable organizations to innovate faster, launch new products and services, and differentiate themselves in the market, giving them a competitive edge."
      }, {
        id: 6,
        imageSrc: '/assets/ai-integration/risk-mitigation.svg',
        cardTitle: 'Risk Mitigation and Compliance',
        cardData: "Through advanced analytics and predictive modeling, Primathon's AI integration services help businesses identify and mitigate risks more effectively. Whether it's detecting anomalies, predicting potential issues, or ensuring compliance with regulatory requirements, AI-powered solutions enhance risk management practices and safeguard the organization's reputation."
      }]
    },
    ourServicesInclude: {
      title: 'Primathon’s AI Integration Services Include',
      cardData: [{
        id: 1,
        imageSrc: '/assets/ai-integration/consulting-and-strategy.svg',
        cardTitle: 'Consulting and Strategy',
        content: 'Assessing your business goals and processes to identify opportunities for AI integration, and developing a strategic plan for implementation.'
      }, {
        id: 2,
        imageSrc: '/assets/ai-integration/data-management.svg',
        cardTitle: 'Enhancing Data Management with AI Integration',
        content: 'AI system integration with your existing data sources, such as databases, data warehouses, or third-party APIs, to ensure seamless data flow and accessibility for AI algorithms.'
      }, {
        id: 3,
        imageSrc: '/assets/ai-integration/development-and-training.svg',
        cardTitle: 'Model Development and Training',
        content: 'Building custom machine learning or deep learning models tailored to your specific business needs, and training them on relevant datasets.'
      }, {
        id: 4,
        imageSrc: '/assets/ai-integration/deployment-and-integration.svg',
        cardTitle: 'Deployment and Integration',
        content: "Implementing AI models into your existing systems or workflows, whether it's on-premises or in the cloud, and ensuring compatibility and smooth integration with other software components."
      }, {
        id: 5,
        imageSrc: '/assets/ai-integration/api-development.svg',
        cardTitle: 'API Development',
        content: 'Creating APIs (Application Programming Interfaces) to enable communication between your applications and AI services, allowing for real-time data processing and decision-making.'
      }]
    },
    ctaSection: {
      title: 'Let’s Get Started Today',
      description: 'Ready to unlock the full potential of AI for your business? Contact us today to learn more about our AI integration services and how we can help you drive innovation, efficiency, and growth. With Primathon by your side, the possibilities are endless.',
      ctaText: 'Contact Us',
      redirect: '/contact',
      bgColor: '#21315B'
    },
    aiIntegrationJourney: {
      heading: 'Let us take your project on a journey!',
      items: ['You share your idea with us', 'We Explore AI Potential Analysis', 'Our Team Craft a Strategic AI Roadmap', 'we deliver on time']
    },
    bulletData: {
      title: "Get in Touch with Us to Unlock Your Business's Potential with our AI Consultancy Expertise",
      description: 'During this call, you can expect',
      imageSrc: '/assets/ai-generating/unlock-your-business.png',
      alt: 'Ai Integration',
      listItems: [{
        id: 1,
        text: "Attaining in-depth understanding of Primathon's AI discovery methodology and its capabilities."
      }, {
        id: 2,
        text: 'Investigating how other retailers utilize the AI suite to improve product discovery and achieve their business goals.'
      }, {
        id: 3,
        text: 'Engaging in discussions tailored to address your specific requirements and obstacles.'
      }]
    },
    faqs: {
      title: 'FAQs',
      cardData: [{
        id: 1,
        question: 'What are AI integration services, and how can they benefit my business?',
        answer: ['AI integration services involve incorporating artificial intelligence capabilities into existing systems or processes to enhance performance and unlock new opportunities. These services can benefit your business by improving efficiency, automating tasks, personalizing customer experiences, and generating valuable insights from data.']
      }, {
        id: 2,
        question: 'How do I know if my business needs AI integration services?',
        answer: ['If your business faces challenges such as manual and repetitive tasks, inefficient processes, difficulty in analyzing large volumes of data, or the need for personalized customer experiences, AI integration services could be beneficial.']
      }, {
        id: 3,
        question: 'What factors should I consider when choosing an AI integration service provider?',
        answer: ['When selecting an AI integration service provider, consider factors such as their expertise in AI technologies, experience with similar projects, track record of success, ability to customize solutions to your specific needs, commitment to ethical AI practices, scalability of their solutions, and the level of support and partnership they offer throughout the integration process.']
      }, {
        id: 4,
        question: 'What are the potential challenges or risks associated with AI integration?',
        answer: ['Some potential challenges or risks associated with AI integration include data privacy and security concerns, the need for quality data for training AI models, potential biases in AI algorithms, regulatory compliance requirements, integration complexity, and the need for ongoing maintenance and optimization to ensure the continued effectiveness of AI solutions.']
      }, {
        id: 5,
        question: 'How long does it typically take to integrate AI into existing systems or processes?',
        answer: ['The time it takes to integrate AI into existing systems or processes can vary depending on factors such as the complexity of the project, the availability and quality of data, the specific AI technologies being implemented, and the level of customization required. While some AI integrations may be relatively straightforward and quick to deploy, others may require more time for development, testing, and refinement. Working closely with your AI integration service provider can help establish realistic timelines and expectations for the integration process.']
      }]
    }
  };
  _exports.AI_INTEGRATION = AI_INTEGRATION;
});