define("primathon-ember-web/components/pages/open-position/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <OpenPosition::Search-Form @setQuery={{this.setQuery}}/><OpenPosition::Job-Data @data={{@data}} @query={{this.query}} /><OpenPosition::Write-Us
  />
  <Footer />
  */
  {
    "id": "HamC2J+q",
    "block": "[[[8,[39,0],null,[[\"@setQuery\"],[[30,0,[\"setQuery\"]]]],null],[8,[39,1],null,[[\"@data\",\"@query\"],[[30,1],[30,0,[\"query\"]]]],null],[8,[39,2],null,null,null],[1,\"\\n\"],[8,[39,3],null,null,null]],[\"@data\"],false,[\"open-position/search-form\",\"open-position/job-data\",\"open-position/write-us\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/open-position/index.hbs",
    "isStrictMode": false
  });

  let PagesOpenPositionIndexComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class PagesOpenPositionIndexComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "query", _descriptor, this);
    }

    setQuery(query) {
      this.query = query;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "query", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setQuery", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setQuery"), _class.prototype)), _class));
  _exports.default = PagesOpenPositionIndexComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PagesOpenPositionIndexComponent);
});