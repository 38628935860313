define("primathon-ember-web/components/chatgpt/introduction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="chatgpt-introductionContainer">
      <div class="chatgpt-introductionTextContainer">
          <p>
              <span>{{@intro.heading}}</span>
              {{#each @intro.content as |item|}}
              {{item}}
              <br><br>
              {{/each}}
          </p>
          <a href={{@intro.buttonLink}}><button>{{@intro.buttonText}}</button></a>
      </div>
      <div class="chatgpt-introductionImageContainer">
          <Common::AppImage @src="/assets/chatgpt/introductionLaptop.png" height="100%" width="100%" />
      </div>
  </div>
  */
  {
    "id": "umXHmLpG",
    "block": "[[[10,0],[14,0,\"chatgpt-introductionContainer\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"chatgpt-introductionTextContainer\"],[12],[1,\"\\n        \"],[10,2],[12],[1,\"\\n            \"],[10,1],[12],[1,[30,1,[\"heading\"]]],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"content\"]]],null]],null],null,[[[1,\"            \"],[1,[30,2]],[1,\"\\n            \"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n        \"],[10,3],[15,6,[30,1,[\"buttonLink\"]]],[12],[10,\"button\"],[12],[1,[30,1,[\"buttonText\"]]],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"chatgpt-introductionImageContainer\"],[12],[1,\"\\n        \"],[8,[39,2],[[24,\"height\",\"100%\"],[24,\"width\",\"100%\"]],[[\"@src\"],[\"/assets/chatgpt/introductionLaptop.png\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@intro\",\"item\"],false,[\"each\",\"-track-array\",\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/chatgpt/introduction.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});