define("primathon-ember-web/templates/technologies/angular-js", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W7FodWrP",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@bgImg\",\"@heroSection\",\"@serviceByPrimathon\",\"@customizedDevelopment\",\"@whyChoose\",\"@primathonApproach\",\"@nextLevelDevelopment\",\"@advantagesOfDevelopment\",\"@commenceYourJourney\",\"@driveInnovation\"],[[30,1],\"angular-heroSectionContainer-bgImg\",[30,1,[\"angularData\",\"heroSection\"]],[30,1,[\"angularData\",\"serviceByPrimathon\"]],[30,1,[\"angularData\",\"customizedAngularDevelopment\"]],[30,1,[\"angularData\",\"whyChoose\"]],[30,1,[\"angularData\",\"primathonApproach\"]],[30,1,[\"angularData\",\"nextLevelWebDevelopment\"]],[30,1,[\"angularData\",\"advantagesOfAngularDevelopment\"]],[30,1,[\"angularData\",\"commenceYourJourneyOfAngularDevelopment\"]],[30,1,[\"angularData\",\"driveInnovation\"]]]],null]],[\"@model\"],false,[\"pages/services/staff-augmentation-new-services\"]]",
    "moduleName": "primathon-ember-web/templates/technologies/angular-js.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});