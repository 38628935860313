define("primathon-ember-web/components/services/end-to-end-services-details/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='end-to-end-services-details-section'>
    <h2>{{@title}}</h2>
    <Common::AppImage @src={{@imgUrl}} alt={{@title}} />
    <p class='sub-heading'>{{{@heading}}}</p>
    <p>
      {{{@description}}}
    </p>
  </section>
  */
  {
    "id": "7Jq8y9Gm",
    "block": "[[[10,\"section\"],[14,0,\"end-to-end-services-details-section\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[30,1]],[13],[1,\"\\n  \"],[8,[39,0],[[16,\"alt\",[30,1]]],[[\"@src\"],[[30,2]]],null],[1,\"\\n  \"],[10,2],[14,0,\"sub-heading\"],[12],[2,[30,3]],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    \"],[2,[30,4]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@title\",\"@imgUrl\",\"@heading\",\"@description\"],false,[\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/services/end-to-end-services-details/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});