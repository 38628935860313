define("primathon-ember-web/components/pages/contact/index", ["exports", "@glimmer/component", "primathon-ember-web/constants/event-name"], function (_exports, _component, _eventName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Header @onHireUs={{this.onHireUs}} @type='2' />
  <div class='app-container'>
    <div class='contact-us-herosection pb-12 pt-36 lg:pb-0 px-10 lg:px-16 lg:mb-[20px]'>
      <div
        class='flex flex-col lg:flex-row justify-center items-center lg:items-end gap-y-12 lg:gap-x-16 max-w-[1600px] mx-auto'
      >
        <Contact::HeroSection
          @trackEvent={{this.trackEvent}}
          @careerEventName={{this.CONTACT_CAREER_CLICK}}
          @scheduleEventName={{this.CONTACT_SCHEDULE_MEETING_CLICK}}
        />
        <div class='w-full max-w-[600px] lg:max-w-[500px]' id='contact-hero'>
          <Contact::ContactForm class='lg:relative bottom-[-20px]' />
        </div>
      </div>
    </div>
    <Homepage::PartnersSection />
    <Contact::PrimathonAroundGlobe />
    <Contact::ContactUs />
    <Contact::PrimathonPerformance />
    <CaseStudies::Post />
    <Contact::PrimathonNews />
    <Contact::ExploreOpenings @trackEvent={{this.trackEvent}} @eventName={{this.CONTACT_CAREER_CLICK}} />
    <Homepage::Testimonial
      @title={{@data.testimonialData.title}}
      @description={{@data.testimonialData.description}}
      @data={{@data.testimonialData.data}}
    />
  
    {{! <Contact::FormSection /> }}
    <Homepage::HireUs @onHireUs={{this.onHireUs}} />
    <Footer />
  </div>
  */
  {
    "id": "FmZIQOct",
    "block": "[[[8,[39,0],null,[[\"@onHireUs\",\"@type\"],[[30,0,[\"onHireUs\"]],\"2\"]],null],[1,\"\\n\"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"contact-us-herosection pb-12 pt-36 lg:pb-0 px-10 lg:px-16 lg:mb-[20px]\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-col lg:flex-row justify-center items-center lg:items-end gap-y-12 lg:gap-x-16 max-w-[1600px] mx-auto\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@trackEvent\",\"@careerEventName\",\"@scheduleEventName\"],[[30,0,[\"trackEvent\"]],[30,0,[\"CONTACT_CAREER_CLICK\"]],[30,0,[\"CONTACT_SCHEDULE_MEETING_CLICK\"]]]],null],[1,\"\\n      \"],[10,0],[14,0,\"w-full max-w-[600px] lg:max-w-[500px]\"],[14,1,\"contact-hero\"],[12],[1,\"\\n        \"],[8,[39,2],[[24,0,\"lg:relative bottom-[-20px]\"]],null,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,3],null,null,null],[1,\"\\n  \"],[8,[39,4],null,null,null],[1,\"\\n  \"],[8,[39,5],null,null,null],[1,\"\\n  \"],[8,[39,6],null,null,null],[1,\"\\n  \"],[8,[39,7],null,null,null],[1,\"\\n  \"],[8,[39,8],null,null,null],[1,\"\\n  \"],[8,[39,9],null,[[\"@trackEvent\",\"@eventName\"],[[30,0,[\"trackEvent\"]],[30,0,[\"CONTACT_CAREER_CLICK\"]]]],null],[1,\"\\n  \"],[8,[39,10],null,[[\"@title\",\"@description\",\"@data\"],[[30,1,[\"testimonialData\",\"title\"]],[30,1,[\"testimonialData\",\"description\"]],[30,1,[\"testimonialData\",\"data\"]]]],null],[1,\"\\n\\n\"],[1,\"  \"],[8,[39,11],null,[[\"@onHireUs\"],[[30,0,[\"onHireUs\"]]]],null],[1,\"\\n  \"],[8,[39,12],null,null,null],[1,\"\\n\"],[13]],[\"@data\"],false,[\"header\",\"contact/hero-section\",\"contact/contact-form\",\"homepage/partners-section\",\"contact/primathon-around-globe\",\"contact/contact-us\",\"contact/primathon-performance\",\"case-studies/post\",\"contact/primathon-news\",\"contact/explore-openings\",\"homepage/testimonial\",\"homepage/hire-us\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/contact/index.hbs",
    "isStrictMode": false
  });

  let PagesContactIndexComponent = (_dec = Ember._action, (_class = class PagesContactIndexComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "CONTACT_CAREER_CLICK", _eventName.CONTACT_CAREER_CLICK);

      _defineProperty(this, "CONTACT_SCHEDULE_MEETING_CLICK", _eventName.CONTACT_SCHEDULE_MEETING_CLICK);
    }

    onHireUs() {
      const elm = document.getElementById('contact-hero');

      if (elm) {
        elm.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }

    trackEvent(name) {
      if (window.gtag) {
        window.gtag('event', name);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onHireUs", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onHireUs"), _class.prototype)), _class));
  _exports.default = PagesContactIndexComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PagesContactIndexComponent);
});