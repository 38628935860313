define("primathon-ember-web/components/homepage/core-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="container-fluid hire-features">
    <div class="orange-bar"></div>
    <div class="container">
      <h2 class="center">
        {{@title}}
      </h2>
      <p class="section-sub center">
        {{@description}}
      </p>
      <div class="row hire-feature-boxes-wrap">
        <div class="grid hire-feature-boxes">
          {{#each @data as |data|}}
            <div class="hire-feature-box">
              <h3>
                {{data.title}}
              </h3>
              <p>
                {{data.description}}
              </p>
            </div>
          {{/each}}
        </div>
        <div class="main-image">
          <Common::AppImage
            @srcset="/assets/images/amazing-team.webp , /assets/images/amazing-team.png"
            @src="/assets/images/amazing-team.png" loading="lazy" alt="Amazing teams" width="100%" height="100%"/>
        </div>
      </div>
    </div>
  </div>
  */
  {
    "id": "+Sf46ixz",
    "block": "[[[10,0],[14,0,\"container-fluid hire-features\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"orange-bar\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"center\"],[12],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"section-sub center\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"row hire-feature-boxes-wrap\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"grid hire-feature-boxes\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,3]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"hire-feature-box\"],[12],[1,\"\\n            \"],[10,\"h3\"],[12],[1,\"\\n              \"],[1,[30,4,[\"title\"]]],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,2],[12],[1,\"\\n              \"],[1,[30,4,[\"description\"]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[4]],null],[1,\"      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"main-image\"],[12],[1,\"\\n        \"],[8,[39,2],[[24,\"loading\",\"lazy\"],[24,\"alt\",\"Amazing teams\"],[24,\"width\",\"100%\"],[24,\"height\",\"100%\"]],[[\"@srcset\",\"@src\"],[\"/assets/images/amazing-team.webp , /assets/images/amazing-team.png\",\"/assets/images/amazing-team.png\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@title\",\"@description\",\"@data\",\"data\"],false,[\"each\",\"-track-array\",\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/homepage/core-value.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});