define("primathon-ember-web/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LnwDUnXh",
    "block": "[[[10,\"meta\"],[14,3,\"robots\"],[15,\"content\",[30,0,[\"model\",\"robots\"]]],[12],[13],[1,\"\\n\\n\"],[10,\"title\"],[12],[1,\"\\n  \"],[1,[30,0,[\"model\",\"title\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"meta\"],[14,3,\"description\"],[15,\"content\",[30,0,[\"model\",\"description\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,3,\"keywords\"],[15,\"content\",[30,0,[\"model\",\"keywords\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,3,\"twitter:title\"],[15,\"content\",[30,0,[\"model\",\"title\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,3,\"twitter:description\"],[15,\"content\",[30,0,[\"model\",\"description\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,\"property\",\"og:title\"],[15,\"content\",[30,0,[\"model\",\"title\"]]],[12],[13],[1,\"\\n\"],[10,\"meta\"],[14,\"property\",\"og:description\"],[15,\"content\",[30,0,[\"model\",\"description\"]]],[12],[13],[1,\"\\n\"],[41,[30,0,[\"model\",\"canonical\"]],[[[1,\"  \"],[10,\"link\"],[14,\"rel\",\"canonical\"],[15,6,[30,0,[\"model\",\"canonical\"]]],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "primathon-ember-web/templates/head.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});