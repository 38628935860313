define("primathon-ember-web/components/case-studies/post/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='bg-[#1A284D] px-6 py-10 sm:px-10 sm:py-24 lg:px-16 xl:p-24' id="case-studies">
    <div class='max-w-[1210px] mx-auto'>
      <h2 class='text-[#F9FAFB] text-2xl sm:text-3xl text-center'>{{if
          @data.title
          @data.title
          'Our people build extraordinary products'
        }}</h2>
      {{#if @data.subtitle}}
        <p class='text-[#E3E7EE] text-center mb-0 mt-4'>{{@data.subtitle}}</p>
      {{/if}}
      {{!-- <div>
      {{#each this.tags as |tag|}}
        <CaseStudies::Post::Tags
          @text={{tag}}
          class={{if (array-contains this.selectedTag tag) 'selected'}}
          {{on 'click' (fn this.onSelect tag)}}
        />
      {{/each}}
    </div> --}}
      <div class='mt-16 flex flex-wrap justify-center md:justify-start gap-12'>
        {{#each this.postData as |data|}}
          <CaseStudies::Post::Card @data={{data}} />
        {{/each}}
      </div>
    </div>
  </section>
  */
  {
    "id": "3h1v85rg",
    "block": "[[[10,\"section\"],[14,0,\"bg-[#1A284D] px-6 py-10 sm:px-10 sm:py-24 lg:px-16 xl:p-24\"],[14,1,\"case-studies\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"max-w-[1210px] mx-auto\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"text-[#F9FAFB] text-2xl sm:text-3xl text-center\"],[12],[1,[52,[30,1,[\"title\"]],[30,1,[\"title\"]],\"Our people build extraordinary products\"]],[13],[1,\"\\n\"],[41,[30,1,[\"subtitle\"]],[[[1,\"      \"],[10,2],[14,0,\"text-[#E3E7EE] text-center mb-0 mt-4\"],[12],[1,[30,1,[\"subtitle\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[14,0,\"mt-16 flex flex-wrap justify-center md:justify-start gap-12\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"postData\"]]],null]],null],null,[[[1,\"        \"],[8,[39,3],null,[[\"@data\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@data\",\"data\"],false,[\"if\",\"each\",\"-track-array\",\"case-studies/post/card\"]]",
    "moduleName": "primathon-ember-web/components/case-studies/post/index.hbs",
    "isStrictMode": false
  });

  let CaseStudiesPostIndexComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class CaseStudiesPostIndexComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "selectedTag", _descriptor, this);
    }

    get tags() {
      return ['All', 'AI/ML', 'Booking Platforms', 'Admin Panel', 'Mobile Apps', 'Web Development', 'Fintech', 'Blockchain', 'ERC 20', 'SaaS', 'Data Migration', 'Smart Contract'];
    }

    get postData() {
      return [{
        url: 'bloom',
        poster: '/assets/caseStudy/bloom.png',
        title: 'Bloom Hotel: Building a high performance complete booking engine for one of the biggest hotel chains in India',
        description: 'Bloom Hotels is a renowned chain of hotels in India that offers affordability, comfort, and simplicity to a new generation of travelers who value collecting experiences.',
        tags: ['A/B Testing', ' Fully Dynamic CMS', 'Sentry for Analytics']
      }, {
        url: 'jar',
        poster: '/assets/caseStudy/jar.png',
        title: 'Creating a Disruptive Digital Gold Investment App: The Jar Story',
        description: 'In the age of consumerism and easy gratification that often leaves our souls and wallets both feeling empty, we want to help millions of Indians rediscover the undeniable, cumulative advantages of saving every single day. ',
        tags: ['Secure Infrastructure', 'Product Launch', 'Payment Gateway']
      }, {
        url: 'fi',
        poster: '/assets/caseStudy/fi.png',
        title: 'Developing the Best Digital Banking Platform for FI: A Success Story',
        description: 'Fi is a financial app that comes with an in-built savings account. Emboldened by cutting edge tech, Fi helps you know your money, grow your money and organise your funds.',
        tags: ['Developed Website', 'Waitlist onboarding workflow', 'Regular updates']
      }, {
        url: 'freshworks',
        poster: '/assets/caseStudy/freshworks.png',
        title: 'Freshworks: Build scalable solutions and applications for one of the biggest SAAS solution provider Freshworks',
        description: 'Freshworks builds tech that works for everyone, making it easy for IT, customer service, sales, marketers and HR to do their job and delight their customers.',
        tags: ['15 custom apps', 'Integrated agileCRM', 'Integrated Frontapp']
      }, {
        url: 'paxful',
        poster: '/assets/caseStudy/paxful.png',
        title: 'Paxful: Building a heavy transaction cryptocurrency exchange platform',
        description: 'Paxful is a global peer-to-peer platform building a financial system for the 100% — one #Bitcoin at a time. ',
        tags: ['Orderbook Implementation', 'Crypto Exchange', 'Comprehensive User interface']
      }];
    }

    onSelect(tag) {
      if (this.selectedTag.includes(tag)) {
        this.selectedTag = this.selectedTag.filter(e => e !== tag);
      } else {
        this.selectedTag = [...this.selectedTag, tag];
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedTag", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onSelect", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onSelect"), _class.prototype)), _class));
  _exports.default = CaseStudiesPostIndexComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CaseStudiesPostIndexComponent);
});