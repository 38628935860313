define("primathon-ember-web/components/career/reason/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='career-feature'>
    <div class='reason'>
      <h2>
        Why should you join Primathon?
      </h2>
      <div class='feature'>
        <div class='feature-image'>
          <Common::AppImage @src='/assets/images/innovation.png' alt='innovation' />
        </div>
        <p class='heading'>Innovation</p>
        <p class='content'>Work on technologies of the future, stay connected to everything that is happening and be a
          part of the modern tech-revolution</p>
  
        <div class='feature-image'>
          <Common::AppImage @src='/assets/images/growth.png' alt='growth' />
        </div>
        <p class='heading'>Growth</p>
        <p class='content'>Our culture is built on the philosophy of mutual growth and becoming the best version of
          yourself- as an employee and as a person</p>
  
        <div class='feature-image'>
          <Common::AppImage @src='/assets/images/balance.png' alt='balance' />
        </div>
        <p class='heading'>Balance</p>
        <p class='content'>We believe in balance. Working, Learning and Fun are equally important. We never compromise on
          our training sessions and fun times</p>
      </div>
    </div>
  </section>
  */
  {
    "id": "8Ce966Zj",
    "block": "[[[10,\"section\"],[14,0,\"career-feature\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"reason\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"\\n      Why should you join Primathon?\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"feature\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"feature-image\"],[12],[1,\"\\n        \"],[8,[39,0],[[24,\"alt\",\"innovation\"]],[[\"@src\"],[\"/assets/images/innovation.png\"]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,0,\"heading\"],[12],[1,\"Innovation\"],[13],[1,\"\\n      \"],[10,2],[14,0,\"content\"],[12],[1,\"Work on technologies of the future, stay connected to everything that is happening and be a\\n        part of the modern tech-revolution\"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"feature-image\"],[12],[1,\"\\n        \"],[8,[39,0],[[24,\"alt\",\"growth\"]],[[\"@src\"],[\"/assets/images/growth.png\"]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,0,\"heading\"],[12],[1,\"Growth\"],[13],[1,\"\\n      \"],[10,2],[14,0,\"content\"],[12],[1,\"Our culture is built on the philosophy of mutual growth and becoming the best version of\\n        yourself- as an employee and as a person\"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"feature-image\"],[12],[1,\"\\n        \"],[8,[39,0],[[24,\"alt\",\"balance\"]],[[\"@src\"],[\"/assets/images/balance.png\"]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,0,\"heading\"],[12],[1,\"Balance\"],[13],[1,\"\\n      \"],[10,2],[14,0,\"content\"],[12],[1,\"We believe in balance. Working, Learning and Fun are equally important. We never compromise on\\n        our training sessions and fun times\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/career/reason/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});