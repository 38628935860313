define("primathon-ember-web/components/services/circular-list/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="app-container circular-list-section">
    <div class="circular-list-container">
      <h2>
        {{@content.heading}}
      </h2>
      {{#if (eq @color 'orange')}}
        <div class="orange-semi-circle"></div>
      {{else if (eq @color 'green')}}
        <div class="green-semi-circle"></div>
      {{else}}
        <div class="red-semi-circle"></div>
      {{/if}}
      <ul>
        <li class="circular-list-container__list-item circular-list-container__top-left">
          <p>
            {{get @content.items 0}}
          </p>
          <div></div>
        </li>
        <li class="circular-list-container__list-item circular-list-container__top-right">
          <div></div>
          <p>
            {{get @content.items 1}}
          </p>
        </li>
        <li class="circular-list-container__list-item circular-list-container__bottom-left">
          <p>
            {{get @content.items 2}}
          </p>
          <div></div>
        </li>
        <li class="circular-list-container__list-item circular-list-container__bottom-right">
          <div></div>
          <p>
            {{get @content.items 3}}
          </p>
        </li>
      </ul>
    </div>
  </section>
  */
  {
    "id": "MYRQXTKI",
    "block": "[[[10,\"section\"],[14,0,\"app-container circular-list-section\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"circular-list-container\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"\\n      \"],[1,[30,1,[\"heading\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[28,[37,1],[[30,2],\"orange\"],null],[[[1,\"      \"],[10,0],[14,0,\"orange-semi-circle\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,2],\"green\"],null],[[[1,\"      \"],[10,0],[14,0,\"green-semi-circle\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"red-semi-circle\"],[12],[13],[1,\"\\n    \"]],[]]]],[]]],[1,\"    \"],[10,\"ul\"],[12],[1,\"\\n      \"],[10,\"li\"],[14,0,\"circular-list-container__list-item circular-list-container__top-left\"],[12],[1,\"\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[28,[35,2],[[30,1,[\"items\"]],0],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[14,0,\"circular-list-container__list-item circular-list-container__top-right\"],[12],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[28,[35,2],[[30,1,[\"items\"]],1],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[14,0,\"circular-list-container__list-item circular-list-container__bottom-left\"],[12],[1,\"\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[28,[35,2],[[30,1,[\"items\"]],2],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[14,0,\"circular-list-container__list-item circular-list-container__bottom-right\"],[12],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[28,[35,2],[[30,1,[\"items\"]],3],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@content\",\"@color\"],false,[\"if\",\"eq\",\"get\"]]",
    "moduleName": "primathon-ember-web/components/services/circular-list/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});