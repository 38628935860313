define("primathon-ember-web/constants/homepage/testimonial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TESTIMONIAL_DATA = void 0;
  const TESTIMONIAL_DATA = {
    title: 'Our Client Stories- You are in a Better Place!',
    description: 'Learn how brands from all over the world collaborated with Primathon to strengthen their relationships and grow their businesses.',
    data: [{
      profilePicUrl: '/assets/client-head/Vikas-Kapoor.png',
      fallbackProfilePicUrl: '/assets/client-head/Vikas-Kapoor.webp',
      name: 'Vikas Kapoor',
      title: 'Chairman & CEO at Mezocliq',
      comment: "Primathon's extensive knowledge swiftly tackles our tech challenges, making them the go-to for seamless solutions in our business."
    }, {
      profilePicUrl: '/assets/client-head/Michael-Dotsikas.png',
      fallbackProfilePicUrl: '/assets/client-head/Michael-Dotsikas.webp',
      name: 'Michael Dotsikas',
      title: 'Co-founder - Crypterns',
      comment: 'Choosing Primathon was a game-changer; their profound tech insights and expert solutions effortlessly resolve challenges, elevating our business efficiency'
    }, {
      profilePicUrl: '/assets/client-head/Ray-Youssef.png',
      fallbackProfilePicUrl: '/assets/client-head/Ray-Youssef.webp',
      name: 'Ray Youssef',
      title: 'CEO- Paxful',
      comment: 'Our decision to partner with Primathon stems from their exceptional proficiency in deciphering and resolving intricate technological challenges, enhancing our operational resilience.'
    }, {
      profilePicUrl: '/assets/client-head/Simon-Munch-Andersen.png',
      fallbackProfilePicUrl: ' /assets/client-head/Simon-Munch-Andersen.webp ',
      name: 'Simon Munch-Andersen, ',
      title: 'CTO- Ultimate AI',
      comment: "Primathon's unmatched expertise effortlessly overcomes our business tech hurdles, showcasing their invaluable understanding and effective solutions for sustained success"
    }]
  };
  _exports.TESTIMONIAL_DATA = TESTIMONIAL_DATA;
});