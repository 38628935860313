define("primathon-ember-web/components/pages/new-case-study/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='app-container'>
    <Header />
    <CaseStudyBlog::HeroSection />
    <CaseStudyBlog::AboutBloom @data={{@data.bulletPoints}} />
    <CaseStudyBlog::ProjectGoal @data={{@data.projectGoal}} />
    <CaseStudyBlog::CustomSolution @data={{@data.customSolution}} />
    <CaseStudyBlog::ColoredArrowList @data={{@data.arrowSection}} />
    <CaseStudyBlog::OurStrategy @data={{@data.teamEfforts}} />
    <Services::BulletsList
      @data={{@data.collaborativeTriumph}}
      @imgStyle='w-[350px] h-[320px]'
      @isImgShownInMobile={{true}}
    />
    <CaseStudyBlog::EmpowerVision />
  </div>
  */
  {
    "id": "W2p5BPlj",
    "block": "[[[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,null],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[8,[39,2],null,[[\"@data\"],[[30,1,[\"bulletPoints\"]]]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@data\"],[[30,1,[\"projectGoal\"]]]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@data\"],[[30,1,[\"customSolution\"]]]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@data\"],[[30,1,[\"arrowSection\"]]]],null],[1,\"\\n  \"],[8,[39,6],null,[[\"@data\"],[[30,1,[\"teamEfforts\"]]]],null],[1,\"\\n  \"],[8,[39,7],null,[[\"@data\",\"@imgStyle\",\"@isImgShownInMobile\"],[[30,1,[\"collaborativeTriumph\"]],\"w-[350px] h-[320px]\",true]],null],[1,\"\\n  \"],[8,[39,8],null,null,null],[1,\"\\n\"],[13]],[\"@data\"],false,[\"header\",\"case-study-blog/hero-section\",\"case-study-blog/about-bloom\",\"case-study-blog/project-goal\",\"case-study-blog/custom-solution\",\"case-study-blog/colored-arrow-list\",\"case-study-blog/our-strategy\",\"services/bullets-list\",\"case-study-blog/empower-vision\"]]",
    "moduleName": "primathon-ember-web/components/pages/new-case-study/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});