define("primathon-ember-web/components/pages/technologies/react-native/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Header />
  <div class='app-container'>
    <Ai-development::Hero-section class="react-native-heroSectionContainer" @heroSection={{@data.ReactNativeData.heroSection}} />
    <Chatgpt::Getting-started @gettingStarted={{@data.ReactNativeData.tailoredReactNativeSolution}} />
    <Ai-development::Introduction @intro={{@data.ReactNativeData.unleashExcellenceWithPrimathon}} @imgStyle='w-[500px] h-[400px]'/>
    <Chatgpt::Benefits @benefits={{@data.ReactNativeData.whyChoose}}/>
    {{!-- <Ai-development::Introduction @intro={{@data.ReactNativeData.approachToReactNativeDevelopment}} @imgStyle='w-[500px] h-[480px]'/> --}}
    <Technologies::CtaSection @ctaSection={{@data.ReactNativeData.elevateYourWebDevelopment}} />
    <Homepage::Testimonial
      @title={{@data.testinomialData.title}}
      @description={{@data.testinomialData.description}}
      @data={{@data.testinomialData.data}}
    />
    <Chatgpt::Getting-started @gettingStarted={{@data.ReactNativeData.advantageOfReactNativeDevelopment}} />
    <Technologies::CtaSection @ctaSection={{@data.ReactNativeData.startYourReactNativeProject}} />
    <Ai-development::Introduction @intro={{@data.ReactNativeData.driveInnovation}} @imgStyle='w-[500px] h-[400px]'/>
    <Footer />
  </div>
  */
  {
    "id": "4/9EAoOq",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"react-native-heroSectionContainer\"]],[[\"@heroSection\"],[[30,1,[\"ReactNativeData\",\"heroSection\"]]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@gettingStarted\"],[[30,1,[\"ReactNativeData\",\"tailoredReactNativeSolution\"]]]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@intro\",\"@imgStyle\"],[[30,1,[\"ReactNativeData\",\"unleashExcellenceWithPrimathon\"]],\"w-[500px] h-[400px]\"]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@benefits\"],[[30,1,[\"ReactNativeData\",\"whyChoose\"]]]],null],[1,\"\\n\"],[1,\"  \"],[8,[39,5],null,[[\"@ctaSection\"],[[30,1,[\"ReactNativeData\",\"elevateYourWebDevelopment\"]]]],null],[1,\"\\n  \"],[8,[39,6],null,[[\"@title\",\"@description\",\"@data\"],[[30,1,[\"testinomialData\",\"title\"]],[30,1,[\"testinomialData\",\"description\"]],[30,1,[\"testinomialData\",\"data\"]]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@gettingStarted\"],[[30,1,[\"ReactNativeData\",\"advantageOfReactNativeDevelopment\"]]]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@ctaSection\"],[[30,1,[\"ReactNativeData\",\"startYourReactNativeProject\"]]]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@intro\",\"@imgStyle\"],[[30,1,[\"ReactNativeData\",\"driveInnovation\"]],\"w-[500px] h-[400px]\"]],null],[1,\"\\n  \"],[8,[39,7],null,null,null],[1,\"\\n\"],[13]],[\"@data\"],false,[\"header\",\"ai-development/hero-section\",\"chatgpt/getting-started\",\"ai-development/introduction\",\"chatgpt/benefits\",\"technologies/cta-section\",\"homepage/testimonial\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/technologies/react-native/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});