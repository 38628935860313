define("primathon-ember-web/modifiers/on-window-scroll", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function onWindowScroll(element, _ref) {
    let [callback] = _ref;

    function handleClick(event) {
      if (!element.contains(event.target)) {
        callback(event);
      }
    }

    document.addEventListener('scroll', handleClick);
    return () => {
      document.removeEventListener('scroll', handleClick);
    };
  });

  _exports.default = _default;
});