define("primathon-ember-web/templates/services/staff-augmentation/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VNBscvre",
    "block": "[[[8,[39,0],null,[[\"@data\"],[[30,1,[\"data\"]]]],null]],[\"@model\"],false,[\"pages/services/staff-augmentation\"]]",
    "moduleName": "primathon-ember-web/templates/services/staff-augmentation/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});