define("primathon-ember-web/components/career/hireus/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='container-fluid pre-footer-wrap dark-blue-bg'>
    <div class='row'>
      <div class='container'>
        <div class='global-comm-wrap'>
          <h2>
            Want to be a part of our team?
          </h2>
          <p>
            Look at open positions and apply!
          </p>
        </div>
        <div class='global-comm-wrap buttons'>
          <LinkTo @route='open-position' class='btn btn-normal btn-hire'>
            FIND OPEN POSITIONS
          </LinkTo>
        </div>
      </div>
    </div>
  </section>
  */
  {
    "id": "QoDMzjAN",
    "block": "[[[10,\"section\"],[14,0,\"container-fluid pre-footer-wrap dark-blue-bg\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"global-comm-wrap\"],[12],[1,\"\\n        \"],[10,\"h2\"],[12],[1,\"\\n          Want to be a part of our team?\\n        \"],[13],[1,\"\\n        \"],[10,2],[12],[1,\"\\n          Look at open positions and apply!\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"global-comm-wrap buttons\"],[12],[1,\"\\n        \"],[8,[39,0],[[24,0,\"btn btn-normal btn-hire\"]],[[\"@route\"],[\"open-position\"]],[[\"default\"],[[[[1,\"\\n          FIND OPEN POSITIONS\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"link-to\"]]",
    "moduleName": "primathon-ember-web/components/career/hireus/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});