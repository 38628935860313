define("primathon-ember-web/templates/services/staff-augmentation/uiux-designers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qj69+vBy",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@subHeading\",\"@about\",\"@description\",\"@bg\",\"@circular_list\"],[[30,0,[\"uiUxtDesignersTitle\"]],[30,0,[\"uiUxDesignersSubHeading\"]],[30,0,[\"uiUxDesignersAbout\"]],[30,0,[\"uiUxDesignersDescription\"]],[30,0,[\"uiUxDesignerBg\"]],[30,0,[\"uiUxDesignerCircularList\"]]]],null],[1,\"\\n\"]],[],false,[\"pages/services/staff-augmentation-services\"]]",
    "moduleName": "primathon-ember-web/templates/services/staff-augmentation/uiux-designers.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});