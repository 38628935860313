define("primathon-ember-web/constants/homepage/services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SERVICE_DATA = void 0;
  const SERVICE_DATA = {
    title: 'Services',
    description: 'Where can we take your business? Well, everywhere! This is how we do it.',
    data: [{
      linkUrl: 'services.end-to-end-products',
      imageUrl: '/assets/svgs/right_fit.svg',
      title: 'End-to-end Products',
      subTitle: 'From Start to Finish- Your Project is now Ours!',
      description: 'From understanding your requirements to perfectly catering to them, from handling your E-commerce development to digitally transforming your business we have the most suitable technological solutions for you. ',
      infoPoint: [{
        text: 'Dashboards and Data visualization',
        url: '/services/end-to-end-products/dashboard-and-data-visualization'
      }, {
        text: 'Shopify Stores',
        url: '/services/end-to-end-products/shopify-stores'
      }, {
        text: 'Booking platforms',
        url: '/services/end-to-end-products/booking-platforms'
      }, {
        text: 'Admin panels and CMS',
        url: '/services/end-to-end-products/admin-panels-and-cms'
      }, {
        text: 'Web & Mobile App development',
        url: '/services/end-to-end-products/web-and-mobile-app-development'
      }, {
        text: 'ERC20 Tokens',
        url: '/services/end-to-end-products/erc20-token'
      }, {
        text: 'Smart Contracts',
        url: '/services/end-to-end-products/smart-contract'
      }, {
        text: 'ChatGPT Services',
        url: '/services/end-to-end-products/chatgpt'
      }]
    }, {
      linkUrl: 'services.staff-augmentation',
      imageUrl: '/assets/svgs/exceptional_engineers.svg',
      title: 'Staff Augmentation',
      subTitle: 'We find the best Talent for you!',
      description: 'Count on us if you need an extra pair of experienced hands. We provide the individual or a whole team of Software Developers at one place by identifying the cream of designers and developers.',
      infoPoint: [{
        text: 'Frontend Developers',
        url: '/services/staff-augmentation/hire-frontend-developers'
      }, {
        text: 'Backend developers',
        url: '/services/staff-augmentation/hire-backend-developers'
      }, {
        text: 'Product managers',
        url: '/services/staff-augmentation/product-managers'
      }, {
        text: 'Quality Analysts',
        url: '/services/staff-augmentation/quality-analysts'
      }, {
        text: 'Product Designers',
        url: '/services/staff-augmentation/uiux-designers'
      }, {
        text: 'Android developers',
        url: '/services/staff-augmentation/hire-android-developers'
      }, {
        text: 'Python developers',
        url: '/services/staff-augmentation/hire-python-developers'
      }, {
        text: 'Angular developers',
        url: '/services/staff-augmentation/hire-angular-js-developers'
      }, {
        text: 'JavaScript developers',
        url: '/services/staff-augmentation/hire-javaScript-developers'
      }, {
        text: 'React developers',
        url: '/services/staff-augmentation/hire-react-js-developers'
      }, {
        text: 'IOS developers',
        url: '/services/staff-augmentation/hire-ios-developers'
      }, {
        text: 'Swift developers',
        url: '/services/staff-augmentation/hire-swift-developers'
      }, {
        text: 'Vue developers',
        url: '/services/staff-augmentation/hire-vue-developers'
      }, {
        text: 'Ember.js developers',
        url: '/services/staff-augmentation/hire-ember-js-developers'
      }, {
        text: 'Node.js developers',
        url: '/services/staff-augmentation/hire-node-js-developers'
      }, {
        text: 'Devops developers',
        url: '/services/staff-augmentation/hire-devops-developers'
      }]
    }, {
      linkUrl: 'services.build-and-manage',
      imageUrl: '/assets/svgs/developers.svg',
      title: 'Build & Manage',
      subTitle: 'Not just Building, We also Manage your Products!',
      description: 'We deliver as we Guarantee. We assure the best management with Value-driven technology aiming at digitally transforming your business keeping intact the latest industry trends.'
    }, {
      linkUrl: 'services.ai-hub',
      imageUrl: '/assets/svgs/ai-hub.svg',
      title: 'AI Hub',
      subTitle: 'We bring amazing AI Discoveries and Innovations right at your fingertips!',
      description: '',
      infoPoint: [{
        text: 'AI Consulting Services',
        url: '/services/ai-hub/ai-consulting-services'
      }, {
        text: 'Custom AI Solutions',
        url: '/services/ai-hub/custom-ai-solutions'
      }, {
        text: 'AI Integration',
        url: '/services/ai-hub/ai-integration'
      }, {
        text: 'Generative AI Services',
        url: '/services/ai-hub/generative-ai-services'
      }, {
        text: 'AI Strategy',
        url: '/services/ai-hub/ai-strategy'
      }, {
        text: 'AI Software Development Services',
        url: '/services/ai-hub/ai-software-development-services'
      }]
    }]
  };
  _exports.SERVICE_DATA = SERVICE_DATA;
});