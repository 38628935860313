define("primathon-ember-web/templates/services/staff-augmentation/hire-python-developers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w/bMO+uJ",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@heroSection\",\"@heroSectionImgStyle\",\"@heroSectionClass\",\"@elevateYourProject\",\"@elevateYourProjectImgStyle\",\"@ctaSection\",\"@useCases\",\"@whyChoose\",\"@journey\",\"@hireCta\",\"@developmentStrategy\",\"@developmentStrategyImgStyle\",\"@benefits\",\"@table\",\"@blogData\",\"@caseStudies\",\"@faqs\"],[[30,1],[30,1,[\"data\",\"heroSection\"]],\"w-[525px] h-[500px] xl:w-[698px] xl:h-[600px]\",\"bg-[#1A284D] lg:pr-0\",[30,1,[\"data\",\"elevateYourProject\"]],\"w-[360px] h-[300px] sm:w-[525px] sm:h-[500px] xl:w-[698px] xl:h-[600px]\",[30,1,[\"data\",\"ctaSection\"]],[30,1,[\"data\",\"pythonDevelopmentServices\"]],[30,1,[\"data\",\"whyChoose\"]],[30,1,[\"data\",\"pythonJourney\"]],[30,1,[\"data\",\"hireDedicatedPythonDeveloper\"]],[30,1,[\"data\",\"pythonDevelopmentStrategy\"]],\"w-[400px] h-[400px] xl:w-[500px] xl:h-[460px]\",[30,1,[\"data\",\"benefitsOfHiringPython\"]],[30,1,[\"data\",\"table\"]],[30,1,[\"blogData\"]],[30,1,[\"data\",\"caseStudies\"]],[30,1,[\"data\",\"faqs\"]]]],null]],[\"@model\"],false,[\"pages/services/staff-augmentation-services-new\"]]",
    "moduleName": "primathon-ember-web/templates/services/staff-augmentation/hire-python-developers.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});