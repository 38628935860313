define("primathon-ember-web/components/pages/case-study-blog/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='app-container'>
    <CaseStudyBlog::HeroSectionCompany @heroData={{@data.heroSection}} />
    <CaseStudyBlog::ChallengesServicesSection @challenges={{@data.challenges_Services_Section}} />
    <CaseStudyBlog::ProcessSection @process={{@data.processSection}} />
    <CaseStudyBlog::ResultSection @result={{@data.resultSection}} />
    <Homepage::HireUs />
    <Services::BrowseThroughServices @activeService={{0}} />
    <Footer />
  </div>
  */
  {
    "id": "yzdIyjl1",
    "block": "[[[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@heroData\"],[[30,1,[\"heroSection\"]]]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@challenges\"],[[30,1,[\"challenges_Services_Section\"]]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@process\"],[[30,1,[\"processSection\"]]]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@result\"],[[30,1,[\"resultSection\"]]]],null],[1,\"\\n  \"],[8,[39,4],null,null,null],[1,\"\\n  \"],[8,[39,5],null,[[\"@activeService\"],[0]],null],[1,\"\\n  \"],[8,[39,6],null,null,null],[1,\"\\n\"],[13]],[\"@data\"],false,[\"case-study-blog/hero-section-company\",\"case-study-blog/challenges-services-section\",\"case-study-blog/process-section\",\"case-study-blog/result-section\",\"homepage/hire-us\",\"services/browse-through-services\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/case-study-blog/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});