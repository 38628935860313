define("primathon-ember-web/components/common/app-image", ["exports", "@glimmer/component", "primathon-ember-web/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <img 
      srcset={{this.srcset}}
      src={{this.src}}
      loading="lazy"
      ...attributes
  />
  */
  {
    "id": "49euMrU+",
    "block": "[[[11,\"img\"],[16,\"srcset\",[30,0,[\"srcset\"]]],[16,\"src\",[30,0,[\"src\"]]],[24,\"loading\",\"lazy\"],[17,1],[12],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "primathon-ember-web/components/common/app-image.hbs",
    "isStrictMode": false
  });

  class CommonAppImageComponent extends _component.default {
    isFullURL(url) {
      // Regular expression pattern to match a full URL
      const FULL_URL_PATTERN = /^(?:https?:\/\/)?(?:www\.)?\w+\.\w+/i;
      return FULL_URL_PATTERN.test(url);
    }

    get src() {
      if (this.isFullURL(this.args.src)) {
        return this.args.src;
      }

      return _environment.default.ASSETS_CDN_URL ? `${_environment.default.ASSETS_CDN_URL}${this.args.src}` : this.args.src;
    }

    get srcset() {
      try {
        const srcsetArray = this.args.srcset.trim().split(',').map(src => {
          if (this.isFullURL(this.args.src)) {
            return this.args.src;
          }

          return _environment.default.ASSETS_CDN_URL ? `${_environment.default.ASSETS_CDN_URL}${src}` : src;
        });
        return srcsetArray.join(',');
      } catch (e) {
        return this.src;
      }
    }

  }

  _exports.default = CommonAppImageComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CommonAppImageComponent);
});