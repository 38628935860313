define("primathon-ember-web/components/pages/job-desc/index", ["exports", "@glimmer/component", "primathon-ember-web/constants/event-name"], function (_exports, _component, _eventName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Header />
  <div class='app-container relative mb-36' {{did-insert (fn this.dataHandler @id)}}>
    <JobDesc::Poster @jobData={{this.data}} />
    <JobDesc::Post @jobData={{this.data.description}} />
    <JobDesc::DetailsForm
      @formData={{this.formData}}
      @formHandler={{this.formHandler}}
      @formSubmitHandler={{this.formSubmitHandler}}
      @validation={{this.validation}}
    />
    <Common::AppImage class='ellipse-img' @src='/assets/images/red-ellipse-2.svg' alt='red-ellipse' />
  </div>
  <Footer />
  */
  {
    "id": "bg0Znbr6",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[11,0],[24,0,\"app-container relative mb-36\"],[4,[38,1],[[28,[37,2],[[30,0,[\"dataHandler\"]],[30,1]],null]],null],[12],[1,\"\\n  \"],[8,[39,3],null,[[\"@jobData\"],[[30,0,[\"data\"]]]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@jobData\"],[[30,0,[\"data\",\"description\"]]]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@formData\",\"@formHandler\",\"@formSubmitHandler\",\"@validation\"],[[30,0,[\"formData\"]],[30,0,[\"formHandler\"]],[30,0,[\"formSubmitHandler\"]],[30,0,[\"validation\"]]]],null],[1,\"\\n  \"],[8,[39,6],[[24,0,\"ellipse-img\"],[24,\"alt\",\"red-ellipse\"]],[[\"@src\"],[\"/assets/images/red-ellipse-2.svg\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,7],null,null,null]],[\"@id\"],false,[\"header\",\"did-insert\",\"fn\",\"job-desc/poster\",\"job-desc/post\",\"job-desc/details-form\",\"common/app-image\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/job-desc/index.hbs",
    "isStrictMode": false
  });

  let JobDescComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class JobDescComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "submitted", _descriptor2, this);

      _initializerDefineProperty(this, "apiInProgress", _descriptor3, this);

      _initializerDefineProperty(this, "apiError", _descriptor4, this);

      _defineProperty(this, "_COUNTRY_CODE_REGEX", /^(\+?\d{1,3}|\d{1,4})$/);

      _initializerDefineProperty(this, "data", _descriptor5, this);

      _initializerDefineProperty(this, "formData", _descriptor6, this);

      _initializerDefineProperty(this, "validation", _descriptor7, this);

      _defineProperty(this, "headings", {
        job_objectives: 'About the position',
        qualifications: 'Requirements for this role',
        responsibilities: 'Your responsibilities'
      });
    }

    async dataHandler(_ref2) {
      let {
        id
      } = _ref2;
      const data = await (await fetch(`http://blog.primathon.in//wp-json/wp/v2/job_description/${id}`)).json();
      const jd = data;
      const span = document.createElement('span');
      const jdDescription = [];
      Object.keys(this.headings).forEach(k => {
        span.innerHTML = jd[k];
        const list = span.textContent.split('\n').filter(f => f);
        const idx = list.findIndex(l => l.includes('\t'));
        const newItem = {
          title: this.headings[k],
          description: null,
          list: []
        };

        if (idx == 2) {
          newItem.description = list[0];
        }

        const newList = [];
        let listItem = null;

        for (let i = idx - 1; i < list.length; i++) {
          const e = list[i];

          if (e.includes('\t')) {
            listItem.items.push(e);
          } else {
            if (listItem) newList.push(listItem);
            listItem = {
              items: [],
              title: null
            };
            listItem.title = e;
          }
        }

        newList.push(listItem);
        newItem.list = newList;
        jdDescription.push(newItem);
      });
      this.data = {
        title: jd.job_title,
        department: jd.department,
        location: jd.location,
        experience: jd.work_experience,
        description: jdDescription
      };
    }

    formHandler(e) {
      this.formData[e.target.name] = e.target.value;
    }

    async formSubmitHandler(e) {
      e.preventDefault();
      this.trackEvent(_eventName.CAREER_SEND_MESSAGE_CLICK);

      if (this.validate()) {
        this.trackEvent(_eventName.CAREER_FORM_PAYLOAD_VALID, this.userQuestion);
        this.apiInProgress = true;
        const res = await this.api.sendCareerMail(this.formData);

        if (res.status === 200) {
          this.submitted = true;
          this.apiInProgress = true;
          this.trackEvent(_eventName.CAREER_API_SUCCESS, this.userQuestion);
        } else {
          this.apiError = true;
          this.apiInProgress = true;
          this.trackEvent(_eventName.CAREER_API_ERROR, this.userQuestion);
        }
      } else {
        this.trackEvent(_eventName.CAREER_FORM_PAYLOAD_INVALID, this.userQuestion);
      }
    }

    validate() {
      let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      let validateObj = { ...this.validation
      };
      let formObj = {};
      let isValidated = true;

      for (let key in this.formData) {
        formObj[key] = this.formData[key] !== null ? this.formData[key].trim() : null;
      }

      if (formObj.name.length < 1) {
        validateObj.name = 'Please enter valid name';
        isValidated = false;
      }

      if (!emailRegex.test(formObj.email)) {
        validateObj.email = 'Please enter valid E-mail';
        isValidated = false;
      }

      if (formObj.cv == null) {
        validateObj.cv = 'Please attach valid CV';
        isValidated = false;
      }

      if (formObj.portfolio.length < 1) {
        validateObj.portfolio = 'Please add valid link';
        isValidated = false;
      }

      this.validation = validateObj;
      return isValidated;
    }

    trackEvent(name, payload) {
      if (window.gtag) {
        window.gtag('event', name, payload);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "submitted", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "apiInProgress", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "apiError", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "formData", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        name: '',
        email: '',
        portfolio: '',
        cv: null
      };
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "validation", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        name: '',
        email: '',
        portfolio: '',
        cv: null
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "dataHandler", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "dataHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formHandler", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "formHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formSubmitHandler", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "formSubmitHandler"), _class.prototype)), _class));
  _exports.default = JobDescComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, JobDescComponent);
});