define("primathon-ember-web/components/emberjs/ember-work/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='emberWorkContainer'>
    <div class='top'>
      <p>{{@work.description}}</p>
      <div class='global-comm-wrap buttons'>
        <a href='#emberForm' class='btn btn-normal btn-hire'>
          Lets Talk
        </a>
      </div>
    </div>
    <div class='list'>
      {{#each @work.works as |work|}}
        <div class="listItem">
          <div class="numDiv">{{work.id}}</div>
          <div class="description">{{work.value}}</div>
        </div>
      {{/each}}
    </div>
  </div>
  */
  {
    "id": "aHK7XYLN",
    "block": "[[[10,0],[14,0,\"emberWorkContainer\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"top\"],[12],[1,\"\\n    \"],[10,2],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"global-comm-wrap buttons\"],[12],[1,\"\\n      \"],[10,3],[14,6,\"#emberForm\"],[14,0,\"btn btn-normal btn-hire\"],[12],[1,\"\\n        Lets Talk\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"list\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"works\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"listItem\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"numDiv\"],[12],[1,[30,2,[\"id\"]]],[13],[1,\"\\n        \"],[10,0],[14,0,\"description\"],[12],[1,[30,2,[\"value\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@work\",\"work\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "primathon-ember-web/components/emberjs/ember-work/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});