define("primathon-ember-web/components/case-study-blog/project-goal/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section
    class='flex items-center justify-center gap-16 md:bg-[#FFC700] md:h-[400px] lg:h-[420px] mt-8 md:mt-16 flex-col md:flex-row md:p-24 xl:py-28 xl:px-44'
  >
    <div class='pl-4 pr-4 md:p-0 md:h-[500px] lg:h-[600px] md:relative top-0 lg:bottom-24' style='flex-shrink: 0;'>
      <Common::AppImage 
        @src={{@data.imgSrc}}
        alt='iPhone image'
        title='iPhone image'
        class='h-[400px] md:h-[100%] md:w-[400px] lg:w-[500px]'
      />
    </div>
  
    <div class='py-16 px-8 sm:py-28 sm:px-20 md:p-0 flex flex-col justify-center bg-[#FFC700]'>
      <h3 class='font-medium text-3xl md:text-4xl mb-8 lg:mb-10'>{{@data.heading}}</h3>
      <div class='md:max-w-[640px] lg:max-w-[780px] pl-6'>
        <ul class='list-disc'>
          {{#each @data.bulletList as |list|}}
            <li class='lg:text-lg xl:text-xl'>{{list}}</li>
          {{/each}}
        </ul>
      </div>
    </div>
  </section>
  */
  {
    "id": "Nvn50Vv8",
    "block": "[[[10,\"section\"],[14,0,\"flex items-center justify-center gap-16 md:bg-[#FFC700] md:h-[400px] lg:h-[420px] mt-8 md:mt-16 flex-col md:flex-row md:p-24 xl:py-28 xl:px-44\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"pl-4 pr-4 md:p-0 md:h-[500px] lg:h-[600px] md:relative top-0 lg:bottom-24\"],[14,5,\"flex-shrink: 0;\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"alt\",\"iPhone image\"],[24,\"title\",\"iPhone image\"],[24,0,\"h-[400px] md:h-[100%] md:w-[400px] lg:w-[500px]\"]],[[\"@src\"],[[30,1,[\"imgSrc\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"py-16 px-8 sm:py-28 sm:px-20 md:p-0 flex flex-col justify-center bg-[#FFC700]\"],[12],[1,\"\\n    \"],[10,\"h3\"],[14,0,\"font-medium text-3xl md:text-4xl mb-8 lg:mb-10\"],[12],[1,[30,1,[\"heading\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"md:max-w-[640px] lg:max-w-[780px] pl-6\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"list-disc\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"bulletList\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[14,0,\"lg:text-lg xl:text-xl\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@data\",\"list\"],false,[\"common/app-image\",\"each\",\"-track-array\"]]",
    "moduleName": "primathon-ember-web/components/case-study-blog/project-goal/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});