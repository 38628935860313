define("primathon-ember-web/components/case-studies/poster/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Container class='case-studies-poster'>
    <h1 class="heading">
      Case Studies
    </h1>
    <p class='description'>
      Over the course of recent years, our tech experts supported dozens of companies across various markets and
      industries. Read these stories and learn, what they can do for your business.
    </p>
  </Container>
  */
  {
    "id": "s+tXRhoU",
    "block": "[[[8,[39,0],[[24,0,\"case-studies-poster\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h1\"],[14,0,\"heading\"],[12],[1,\"\\n    Case Studies\\n  \"],[13],[1,\"\\n  \"],[10,2],[14,0,\"description\"],[12],[1,\"\\n    Over the course of recent years, our tech experts supported dozens of companies across various markets and\\n    industries. Read these stories and learn, what they can do for your business.\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"container\"]]",
    "moduleName": "primathon-ember-web/components/case-studies/poster/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});