define("primathon-ember-web/components/pages/services/ai-discovery/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Header />
  <div class='app-container'>
    <Services::HeroSectionNew @data={{@heroSection}} @imgStyle='w-[500px] h-[500px]' class='bg-[#198CCA]' />
    <Chatgpt::Benefits @benefits={{@offeringTailoredAIStrategies}} />
    <Homepage::PartnersSection />
    <Chatgpt::Getting-started @gettingStarted={{@featuresOfPrimathonAIDiscovery}} />
    <Services::BulletsList @data={{@seeingAiInAction}} @imgStyle='w-[350px] h-[320px]' />
    <Services::ArrowList @content={{@aiDiscoveryJourney}} @style='chatGpt-arrowList' />
    <Services::CircularList @content={{@circularList}} />
    <Services::LinearList @content={{@linearList}} />
    <Services::CtaSection @data={{@ctaSection}} />
    <Services::BulletsList @data={{@potentialWithAIDiscovery}} @imgStyle='w-[330px] h-[300px]' />
    <Services::WhyHireSection @data={{@whyChoose}} />
    <Homepage::Testimonial
      @title={{@data.testinomialData.title}}
      @description={{@data.testinomialData.description}}
      @data={{@data.testinomialData.data}}
    />
    <Technologies::CtaSection @ctaSection={{@elevateYourJourney}} @isModal={{true}} />
    <Footer />
  </div>
  */
  {
    "id": "yl5yWfle",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"bg-[#198CCA]\"]],[[\"@data\",\"@imgStyle\"],[[30,1],\"w-[500px] h-[500px]\"]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@benefits\"],[[30,2]]],null],[1,\"\\n  \"],[8,[39,3],null,null,null],[1,\"\\n  \"],[8,[39,4],null,[[\"@gettingStarted\"],[[30,3]]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@data\",\"@imgStyle\"],[[30,4],\"w-[350px] h-[320px]\"]],null],[1,\"\\n  \"],[8,[39,6],null,[[\"@content\",\"@style\"],[[30,5],\"chatGpt-arrowList\"]],null],[1,\"\\n  \"],[8,[39,7],null,[[\"@content\"],[[30,6]]],null],[1,\"\\n  \"],[8,[39,8],null,[[\"@content\"],[[30,7]]],null],[1,\"\\n  \"],[8,[39,9],null,[[\"@data\"],[[30,8]]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@data\",\"@imgStyle\"],[[30,9],\"w-[330px] h-[300px]\"]],null],[1,\"\\n  \"],[8,[39,10],null,[[\"@data\"],[[30,10]]],null],[1,\"\\n  \"],[8,[39,11],null,[[\"@title\",\"@description\",\"@data\"],[[30,11,[\"testinomialData\",\"title\"]],[30,11,[\"testinomialData\",\"description\"]],[30,11,[\"testinomialData\",\"data\"]]]],null],[1,\"\\n  \"],[8,[39,12],null,[[\"@ctaSection\",\"@isModal\"],[[30,12],true]],null],[1,\"\\n  \"],[8,[39,13],null,null,null],[1,\"\\n\"],[13]],[\"@heroSection\",\"@offeringTailoredAIStrategies\",\"@featuresOfPrimathonAIDiscovery\",\"@seeingAiInAction\",\"@aiDiscoveryJourney\",\"@circularList\",\"@linearList\",\"@ctaSection\",\"@potentialWithAIDiscovery\",\"@whyChoose\",\"@data\",\"@elevateYourJourney\"],false,[\"header\",\"services/hero-section-new\",\"chatgpt/benefits\",\"homepage/partners-section\",\"chatgpt/getting-started\",\"services/bullets-list\",\"services/arrow-list\",\"services/circular-list\",\"services/linear-list\",\"services/cta-section\",\"services/why-hire-section\",\"homepage/testimonial\",\"technologies/cta-section\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/services/ai-discovery/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});