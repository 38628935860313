define("primathon-ember-web/components/services/call-us/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='px-6 py-8 md:py-16 md:px-12' style='background-color: {{@data.bgColor}} '>
    <div
      class='max-w-[1150px] mx-auto flex flex-col md:flex-row justify-center md:justify-between items-center gap-x-10 gap-y-4'
    >
      <div class='max-w-[900px]'>
        {{#if @data.onlyDesc}}
          <p class='text-xl text-center	sm:text-left sm:text-2xl'>{{@data.descp1}}
            <span class='text-[#F1553F]'>{{@data.colouredDescp}}</span>
            {{@data.descp2}}</p>
        {{else}}
          <h2 class='text-xl md:text-2xl lg:text-3xl text-white text-center md:text-left'>{{@data.title}}</h2>
          <p class='text-gray-400 mb-0 mt-1 text-center md:text-left'>{{@data.description}}</p>
        {{/if}}
      </div>
      <a href={{@data.redirect}} style='flex-shrink: 0'>
        <button
          {{on 'click' (if @data.gtagEventName (fired-gtag-event @data.gtagEventName) (fn (noop)))}}
          class='rounded border border-[#24B768] border-solid font-medium text-white py-4 px-9 bg-[#24B768]'
        >
          {{@data.ctaText}}
        </button>
      </a>
    </div>
  </section>
  */
  {
    "id": "z7UswrI+",
    "block": "[[[10,\"section\"],[14,0,\"px-6 py-8 md:py-16 md:px-12\"],[15,5,[29,[\"background-color: \",[30,1,[\"bgColor\"]],\" \"]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"max-w-[1150px] mx-auto flex flex-col md:flex-row justify-center md:justify-between items-center gap-x-10 gap-y-4\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"max-w-[900px]\"],[12],[1,\"\\n\"],[41,[30,1,[\"onlyDesc\"]],[[[1,\"        \"],[10,2],[14,0,\"text-xl text-center\\tsm:text-left sm:text-2xl\"],[12],[1,[30,1,[\"descp1\"]]],[1,\"\\n          \"],[10,1],[14,0,\"text-[#F1553F]\"],[12],[1,[30,1,[\"colouredDescp\"]]],[13],[1,\"\\n          \"],[1,[30,1,[\"descp2\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"h2\"],[14,0,\"text-xl md:text-2xl lg:text-3xl text-white text-center md:text-left\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n        \"],[10,2],[14,0,\"text-gray-400 mb-0 mt-1 text-center md:text-left\"],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,3],[15,6,[30,1,[\"redirect\"]]],[14,5,\"flex-shrink: 0\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"rounded border border-[#24B768] border-solid font-medium text-white py-4 px-9 bg-[#24B768]\"],[4,[38,1],[\"click\",[52,[30,1,[\"gtagEventName\"]],[28,[37,2],[[30,1,[\"gtagEventName\"]]],null],[28,[37,3],[[28,[37,4],null,null]],null]]],null],[12],[1,\"\\n        \"],[1,[30,1,[\"ctaText\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@data\"],false,[\"if\",\"on\",\"fired-gtag-event\",\"fn\",\"noop\"]]",
    "moduleName": "primathon-ember-web/components/services/call-us/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});