define("primathon-ember-web/components/chatgpt/getting-started", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='chatgpt-getStartedContainer'>
    <div class='chatgpt-getStartedHeadingContainer'>
      <h2>
        {{@gettingStarted.title}}
        <span>{{@gettingStarted.subTitle}}</span>
      </h2>
    </div>
    {{#if @gettingStarted.description}}
      <p class='mt-2 text-center max-w-[1050px] mx-auto'>{{@gettingStarted.description}}</p>
    {{/if}}
    <div class='chatgpt-getStartedCardContainer'>
      {{#each @gettingStarted.cardData as |card|}}
        <div class='chatgpt-getStartedCard'>
          <h2>{{card.cardTitle}}</h2>
          <p>
            {{html-safe card.content}}
          </p>
        </div>
      {{/each}}
    </div>
  </div>
  */
  {
    "id": "YnhU86ID",
    "block": "[[[10,0],[14,0,\"chatgpt-getStartedContainer\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"chatgpt-getStartedHeadingContainer\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"\\n      \"],[1,[30,1,[\"title\"]]],[1,\"\\n      \"],[10,1],[12],[1,[30,1,[\"subTitle\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,1,[\"description\"]],[[[1,\"    \"],[10,2],[14,0,\"mt-2 text-center max-w-[1050px] mx-auto\"],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"chatgpt-getStartedCardContainer\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"cardData\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"chatgpt-getStartedCard\"],[12],[1,\"\\n        \"],[10,\"h2\"],[12],[1,[30,2,[\"cardTitle\"]]],[13],[1,\"\\n        \"],[10,2],[12],[1,\"\\n          \"],[1,[28,[35,3],[[30,2,[\"content\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@gettingStarted\",\"card\"],false,[\"if\",\"each\",\"-track-array\",\"html-safe\"]]",
    "moduleName": "primathon-ember-web/components/chatgpt/getting-started.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});