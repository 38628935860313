define("primathon-ember-web/components/services/linear-list/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='app-container linear-list-section'>
    <div class='linear-list-container'>
      <h2>
        {{@content.heading}}
      </h2>
      <ul class='linear-list__list'>
        <li class='linear-list__item'>
          <span class='red circle'></span>
          <hr />
          <span class="text"> {{get @content.items 0}}</span>
          <div></div>
        </li>
        <li class='linear-list__item'>
          <div></div>
          <span class="text"> {{get @content.items 1}}</span>
          <hr />
          <span class='blue circle'></span>
        </li>
        <li class='linear-list__item'>
          <span class='yellow circle'></span>
          <hr />
          <span class="text"> {{get @content.items 2}}</span>
          <div></div>
        </li>
        <li class='linear-list__item'>
          <div></div>
          <span class="text"> {{get @content.items 3}}</span>
          <hr />
  
          <span class='green circle'></span>
        </li>
      </ul>
    </div>
  </section>
  */
  {
    "id": "GFogbrwS",
    "block": "[[[10,\"section\"],[14,0,\"app-container linear-list-section\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"linear-list-container\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"\\n      \"],[1,[30,1,[\"heading\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"linear-list__list\"],[12],[1,\"\\n      \"],[10,\"li\"],[14,0,\"linear-list__item\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"red circle\"],[12],[13],[1,\"\\n        \"],[10,\"hr\"],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"text\"],[12],[1,\" \"],[1,[28,[35,0],[[30,1,[\"items\"]],0],null]],[13],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[14,0,\"linear-list__item\"],[12],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"text\"],[12],[1,\" \"],[1,[28,[35,0],[[30,1,[\"items\"]],1],null]],[13],[1,\"\\n        \"],[10,\"hr\"],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"blue circle\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[14,0,\"linear-list__item\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"yellow circle\"],[12],[13],[1,\"\\n        \"],[10,\"hr\"],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"text\"],[12],[1,\" \"],[1,[28,[35,0],[[30,1,[\"items\"]],2],null]],[13],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[14,0,\"linear-list__item\"],[12],[1,\"\\n        \"],[10,0],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"text\"],[12],[1,\" \"],[1,[28,[35,0],[[30,1,[\"items\"]],3],null]],[13],[1,\"\\n        \"],[10,\"hr\"],[12],[13],[1,\"\\n\\n        \"],[10,1],[14,0,\"green circle\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@content\"],false,[\"get\"]]",
    "moduleName": "primathon-ember-web/components/services/linear-list/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});