define("primathon-ember-web/components/job-desc/list-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='about-position'>
    <h3>{{@data.title}}</h3>
    {{#if @data.description}}
      <p>
        {{@data.description}}
      </p>
    {{/if}}
  
    {{#each @data.list as |lst|}}
      <div class='bullet-container'>
        <p>{{lst.title}}</p>
        <ul>
          {{#each lst.items as |l|}}
            <li>{{l}}</li>
          {{/each}}
        </ul>
      </div>
    {{/each}}
  </div>
  */
  {
    "id": "yJO6JHfi",
    "block": "[[[10,0],[14,0,\"about-position\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"description\"]],[[[1,\"    \"],[10,2],[12],[1,\"\\n      \"],[1,[30,1,[\"description\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"list\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"bullet-container\"],[12],[1,\"\\n      \"],[10,2],[12],[1,[30,2,[\"title\"]]],[13],[1,\"\\n      \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,2,[\"items\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[3]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null],[13]],[\"@data\",\"lst\",\"l\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "primathon-ember-web/components/job-desc/list-container.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});