define("primathon-ember-web/templates/services/end-to-end-products/admin-panels-and-cms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2nwofIkh",
    "block": "[[[8,[39,0],null,[[\"@about\",\"@title\",\"@subHeading\",\"@description\",\"@imgUrl\",\"@bg\",\"@circular_list\",\"@arrow_list\",\"@linear_list\"],[[30,0,[\"adminPanelsAndCmsAbout\"]],[30,0,[\"adminPanelAndCmsTitle\"]],[30,0,[\"adminPanelAndCmsSubHeading\"]],[30,0,[\"adminPanelAndCmsDescription\"]],[30,0,[\"adminPanelAndCmsImg\"]],[30,0,[\"adminPanelAndCmsBG\"]],[30,0,[\"adminPanelAndCmsCircularList\"]],[30,0,[\"adminPanelAndCmsArrowList\"]],[30,0,[\"adminPanelAndCmsLinearList\"]]]],null],[1,\"\\n\"]],[],false,[\"pages/services/end-to-end-product-services\"]]",
    "moduleName": "primathon-ember-web/templates/services/end-to-end-products/admin-panels-and-cms.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});