define("primathon-ember-web/components/emberjs/ember-hero-section/index", ["exports", "@glimmer/component", "primathon-ember-web/constants/event-name"], function (_exports, _component, _eventName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='emberFirstSection'>
    <div class='emberJsPoster'>
      <div class='emberImg'>
        <Common::AppImage @src='/assets/emberjs/ember.svg' loading='lazy' />
      </div>
      <h1 class='posterHeading'>Professional Ember.js Development Company</h1>
      <p class='posterSubHeading'>Partner with the top-notch Emberjs development company for dynamic, responsive, and
        user-centric web application development.
      </p>
      <a href='/contact'><button>Book a Call</button></a>
    </div>
  </div>
  */
  {
    "id": "ctGvXrMK",
    "block": "[[[10,0],[14,0,\"emberFirstSection\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"emberJsPoster\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"emberImg\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,\"loading\",\"lazy\"]],[[\"@src\"],[\"/assets/emberjs/ember.svg\"]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"posterHeading\"],[12],[1,\"Professional Ember.js Development Company\"],[13],[1,\"\\n    \"],[10,2],[14,0,\"posterSubHeading\"],[12],[1,\"Partner with the top-notch Emberjs development company for dynamic, responsive, and\\n      user-centric web application development.\\n    \"],[13],[1,\"\\n    \"],[10,3],[14,6,\"/contact\"],[12],[10,\"button\"],[12],[1,\"Book a Call\"],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/emberjs/ember-hero-section/index.hbs",
    "isStrictMode": false
  });

  let EmberHeroSectionComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class EmberHeroSectionComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "isSubmitted", _descriptor2, this);

      _initializerDefineProperty(this, "apiInProgress", _descriptor3, this);

      _initializerDefineProperty(this, "apiError", _descriptor4, this);

      _defineProperty(this, "_COUNTRY_CODE_REGEX", /^(\+?\d{1,3}|\d{1,4})$/);

      _initializerDefineProperty(this, "userQuestion", _descriptor5, this);

      _initializerDefineProperty(this, "validation", _descriptor6, this);
    }

    //to remove validation message if focus on input
    resetErrorMessage(name) {
      if (this.apiError) {
        this.apiError = false;
      }

      if (this.validation[name]) {
        this.validation = { ...this.validation,
          [name]: ''
        };
      }
    }

    validate() {
      let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      let validateObj = { ...this.validation
      };
      let formObj = {};
      let isValidated = true;

      for (let key in this.userQuestion) {
        formObj[key] = this.userQuestion[key].trim();
      }

      if (formObj.name.length < 1) {
        validateObj.name = 'Please enter valid Name';
        isValidated = false;
      }

      if (!emailRegex.test(formObj.email)) {
        validateObj.email = 'Please enter valid email';
        isValidated = false;
      }

      if (!this.countryCodeValidator(formObj.countryCode)) {
        validateObj.countryCode = 'Please enter valid country code';
        isValidated = false;
      }

      if (!formObj.phone || formObj.phone.length !== 10) {
        validateObj.phone = 'Please enter valid phone number';
        isValidated = false;
      }

      if (formObj.message.length < 10) {
        validateObj.message = 'Please enter valid message';
        isValidated = false;
      }

      this.validation = validateObj;
      return isValidated;
    }

    countryCodeValidator(code) {
      return this._COUNTRY_CODE_REGEX.test(code);
    }

    async sendMessage(evt) {
      evt.preventDefault();
      this.trackEvent(_eventName.CONTACT_SEND_MESSAGE_CLICK);

      if (this.validate()) {
        this.trackEvent(_eventName.CONTACT_FORM_PAYLOAD_VALID, this.userQuestion);
        this.apiInProgress = true;
        const res = await this.api.sendContactMail(this.userQuestion);

        if (res.status === 200) {
          this.isSubmitted = true;
          this.apiInProgress = true;
          this.trackEvent(_eventName.CONTACT_API_SUCCESS, this.userQuestion);
        } else {
          this.apiError = true;
          this.apiInProgress = true;
          this.trackEvent(_eventName.CONTACT_API_ERROR, this.userQuestion);
        }
      } else {
        this.trackEvent(_eventName.CONTACT_FORM_PAYLOAD_INVALID, this.userQuestion);
      }
    }

    trackEvent(name, payload) {
      if (window.gtag) {
        window.gtag('event', name, payload);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isSubmitted", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "apiInProgress", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "apiError", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "userQuestion", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        name: '',
        email: '',
        countryCode: '+91',
        phone: '',
        budget: '',
        message: ''
      };
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "validation", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        name: '',
        email: '',
        countryCode: '',
        phone: '',
        budget: '',
        message: ''
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "resetErrorMessage", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "resetErrorMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validate", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendMessage", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "sendMessage"), _class.prototype)), _class));
  _exports.default = EmberHeroSectionComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, EmberHeroSectionComponent);
});