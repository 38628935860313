define("primathon-ember-web/controllers/contact-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ContactFormController extends Ember.Controller {}

  _exports.default = ContactFormController;
});