define("primathon-ember-web/components/pages/technologies/react-js/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Header />
  <div class='app-container'>
    <Ai-development::Hero-section class='react-js-heroSectionContainer' @heroSection={{@data.reactJsData.heroSection}} />
    <Ai-development::Introduction @intro={{@data.reactJsData.serviceByPrimathon}} @imgStyle='w-[400px] h-[320px]' />
    <Chatgpt::Getting-started @gettingStarted={{@data.reactJsData.customizedReactJsSolution}} />
    <Chatgpt::Benefits @benefits={{@data.reactJsData.whyChoose}} />
    <Ai-development::Introduction @intro={{@data.reactJsData.primathonApproach}} @imgStyle='w-[400px] h-[300px]' />
    <Technologies::CtaSection @ctaSection={{@data.reactJsData.boostYourWebDevelopment}} />
    <Homepage::Testimonial
      @title={{@data.testinomialData.title}}
      @description={{@data.testinomialData.description}}
      @data={{@data.testinomialData.data}}
    />
    <Chatgpt::Getting-started @gettingStarted={{@data.reactJsData.keyBenefitsOfReactJSDevelopment}} />
    <Technologies::CtaSection @ctaSection={{@data.reactJsData.getReadyToBeginReactJsDevelopment}} />
    <Ai-development::Introduction @intro={{@data.reactJsData.driveInnovation}} @imgStyle='w-[350px] h-[275px]' />
    <Footer />
  </div>
  */
  {
    "id": "unqqii4e",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"react-js-heroSectionContainer\"]],[[\"@heroSection\"],[[30,1,[\"reactJsData\",\"heroSection\"]]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@intro\",\"@imgStyle\"],[[30,1,[\"reactJsData\",\"serviceByPrimathon\"]],\"w-[400px] h-[320px]\"]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@gettingStarted\"],[[30,1,[\"reactJsData\",\"customizedReactJsSolution\"]]]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@benefits\"],[[30,1,[\"reactJsData\",\"whyChoose\"]]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@intro\",\"@imgStyle\"],[[30,1,[\"reactJsData\",\"primathonApproach\"]],\"w-[400px] h-[300px]\"]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@ctaSection\"],[[30,1,[\"reactJsData\",\"boostYourWebDevelopment\"]]]],null],[1,\"\\n  \"],[8,[39,6],null,[[\"@title\",\"@description\",\"@data\"],[[30,1,[\"testinomialData\",\"title\"]],[30,1,[\"testinomialData\",\"description\"]],[30,1,[\"testinomialData\",\"data\"]]]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@gettingStarted\"],[[30,1,[\"reactJsData\",\"keyBenefitsOfReactJSDevelopment\"]]]],null],[1,\"\\n  \"],[8,[39,5],null,[[\"@ctaSection\"],[[30,1,[\"reactJsData\",\"getReadyToBeginReactJsDevelopment\"]]]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@intro\",\"@imgStyle\"],[[30,1,[\"reactJsData\",\"driveInnovation\"]],\"w-[350px] h-[275px]\"]],null],[1,\"\\n  \"],[8,[39,7],null,null,null],[1,\"\\n\"],[13]],[\"@data\"],false,[\"header\",\"ai-development/hero-section\",\"ai-development/introduction\",\"chatgpt/getting-started\",\"chatgpt/benefits\",\"technologies/cta-section\",\"homepage/testimonial\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/technologies/react-js/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});