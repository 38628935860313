define("primathon-ember-web/constants/sitemap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SITEMAP = void 0;
  const SITEMAP = {
    headerLink: [{
      id: 1,
      name: 'About Us',
      url: 'about-us'
    }, {
      id: 2,
      name: 'End to End Products',
      url: 'services/end-to-end-products'
    }, {
      id: 3,
      name: 'AI Hub',
      url: 'services/ai-hub'
    }, {
      id: 4,
      name: 'Staff Augmentation',
      url: 'services/staff-augmentation'
    }, {
      id: 5,
      name: 'Build And Manage',
      url: 'services/build-and-manage'
    }, {
      id: 6,
      name: 'Blog',
      url: 'blog'
    }, {
      id: 7,
      name: 'Careers',
      url: 'careers'
    }, {
      id: 8,
      name: 'Case Studies',
      url: 'case-studies'
    }, {
      id: 9,
      name: 'Contact',
      url: 'contact'
    }],
    endToEndProducts: [{
      id: 1,
      name: 'Booking Platforms',
      url: 'services/end-to-end-products/booking-platforms'
    }, {
      id: 2,
      name: 'Shopify Stores',
      url: 'services/end-to-end-products/shopify-stores'
    }, {
      id: 3,
      name: 'Dashboard And Data Visualization',
      url: 'services/end-to-end-products/dashboard-and-data-visualization'
    }, {
      id: 4,
      name: 'Admin Panels And CMS',
      url: 'services/end-to-end-products/admin-panels-and-cms'
    }, {
      id: 5,
      name: 'Web And Mobile App Development',
      url: 'services/end-to-end-products/web-and-mobile-app-development'
    }, {
      id: 6,
      name: 'ERC20 Token',
      url: 'services/end-to-end-products/erc20-token'
    }, {
      id: 7,
      name: 'Smart Contract',
      url: 'services/end-to-end-products/smart-contract'
    }, {
      id: 8,
      name: 'Chatgpt Services',
      url: 'services/end-to-end-products/chatgpt'
    }],
    staffAugmentation: [{
      id: 1,
      name: 'Frontend Developers',
      url: 'services/staff-augmentation/hire-frontend-developers'
    }, {
      id: 2,
      name: 'Product Managers',
      url: 'services/staff-augmentation/product-managers'
    }, {
      id: 3,
      name: 'Backend Developers',
      url: 'services/staff-augmentation/hire-backend-developers'
    }, {
      id: 4,
      name: 'Quality Analysts',
      url: 'services/staff-augmentation/quality-analysts'
    }, {
      id: 5,
      name: 'Android Developers',
      url: 'services/staff-augmentation/hire-android-developers'
    }, {
      id: 6,
      name: 'Python Developers',
      url: 'services/staff-augmentation/hire-python-developers'
    }, {
      id: 7,
      name: 'Angular Developers',
      url: 'services/staff-augmentation/hire-angular-js-developers'
    }, {
      id: 8,
      name: 'Javascript Developers',
      url: 'services/staff-augmentation/hire-javascript-developers'
    }, {
      id: 9,
      name: 'React Developers',
      url: 'services/staff-augmentation/hire-react-js-developers'
    }, {
      id: 10,
      name: 'IOS Developers',
      url: 'services/staff-augmentation/hire-ios-developers'
    }, {
      id: 11,
      name: 'Swift Developers',
      url: 'services/staff-augmentation/hire-swift-developers'
    }, {
      id: 12,
      name: 'Vew Developers',
      url: 'services/staff-augmentation/hire-vue-developers'
    }, {
      id: 13,
      name: 'Ember.js Developers',
      url: 'services/staff-augmentation/hire-ember-js-developers'
    }, {
      id: 13,
      name: 'Node.js Developers',
      url: 'services/staff-augmentation/hire-node-js-developers'
    }, {
      id: 14,
      name: 'UI/UX Designers',
      url: 'services/staff-augmentation/uiux-designers'
    }, {
      id: 15,
      name: 'Platform',
      url: 'services/staff-augmentation/platform'
    }],
    solutions: [{
      id: 1,
      name: 'Ai Conversational chatbot',
      url: 'solutions/search-gpt'
    }, {
      id: 2,
      name: 'Ai Discovery',
      url: 'solutions/ai-discovery'
    }, {
      id: 3,
      name: 'Search GPT',
      url: 'solutions/search-gpt'
    }, {
      id: 4,
      name: 'Customer Behavior Analysis Tool',
      url: 'solutions/customer-behavior-analysis-tool'
    }, {
      id: 5,
      name: 'AI Stock Predictors',
      url: 'solutions/ai-stock-predictor'
    }, {
      id: 6,
      name: 'AI Credit Scoring',
      url: 'solutions/ai-credit-scoring'
    }],
    aiHub: [{
      id: 1,
      name: 'AI Consulting Services',
      url: 'services/ai-hub/ai-consulting-services'
    }, {
      id: 2,
      name: 'Custom Ai Solutions',
      url: 'services/ai-hub/custom-ai-solutions'
    }, {
      id: 3,
      name: 'AI Integration',
      url: 'services/ai-hub/ai-integration'
    }, {
      id: 4,
      name: 'Generative AI Services',
      url: 'services/ai-hub/generative-ai-services'
    }, {
      id: 5,
      name: 'AI Strategy',
      url: 'services/ai-hub/ai-strategy'
    }, {
      id: 6,
      name: 'AI Software Development Services',
      url: 'services/ai-hub/ai-software-development-services'
    }],
    technologies: [{
      id: 1,
      name: 'Node Js',
      url: 'technologies/node-js'
    }, {
      id: 2,
      name: 'React Js',
      url: 'technologies/react-js'
    }, {
      id: 3,
      name: 'React Native',
      url: 'technologies/react-native'
    }, {
      id: 4,
      name: 'Angular Js',
      url: 'technologies/angular-js'
    }, {
      id: 5,
      name: 'Ember Js',
      url: 'technologies/ember-js'
    }, {
      id: 6,
      name: 'Javascript',
      url: 'technologies/javascript'
    }, {
      id: 7,
      name: 'Python',
      url: 'technologies/python'
    }, {
      id: 8,
      name: 'iOS',
      url: 'technologies/ios'
    }, {
      id: 9,
      name: 'Android',
      url: 'technologies/android'
    }, {
      id: 10,
      name: 'Vue',
      url: 'technologies/vue'
    }, {
      id: 11,
      name: 'Swift',
      url: 'technologies/swift'
    }]
  };
  _exports.SITEMAP = SITEMAP;
});