define("primathon-ember-web/components/career/poster/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Container class="career-poster">
    <h1>
      Careers at Primathon
    </h1>
    <h3>
      <span class="red">
        Learn.
      </span>
      <span class="green">
        Explore.
      </span>
      <span class="yellow">
        Grow.
      </span>
    </h3>
    <p class="phone">
      We believe that great work can only happen when you work with great people.
    </p>
  </Container>
  <div class="career-poster-ring">
    <Common::AppImage @src="/assets/images/career-red-ring.png" alt="red ellipse" />
  </div>
  */
  {
    "id": "3qDaDcAk",
    "block": "[[[8,[39,0],[[24,0,\"career-poster\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"\\n    Careers at Primathon\\n  \"],[13],[1,\"\\n  \"],[10,\"h3\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"red\"],[12],[1,\"\\n      Learn.\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"green\"],[12],[1,\"\\n      Explore.\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"yellow\"],[12],[1,\"\\n      Grow.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[14,0,\"phone\"],[12],[1,\"\\n    We believe that great work can only happen when you work with great people.\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[10,0],[14,0,\"career-poster-ring\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,\"alt\",\"red ellipse\"]],[[\"@src\"],[\"/assets/images/career-red-ring.png\"]],null],[1,\"\\n\"],[13]],[],false,[\"container\",\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/career/poster/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});