define("primathon-ember-web/components/contact/contact-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='bg-[#323F60] px-6 sm:px-12 md:px-20 py-10'>
    <div class='max-w-[800px] flex flex-col sm:flex-row items-center gap-6 justify-between mx-auto'>
      <div class=''>
        <Common::AppImage @src='/assets/contact/sale.svg' alt='sale' title='sale' />
        <p class='text-lg font-semibold text-[#EEEEEE] mb-0'>Sales Inquiries</p>
        <a class='text-[#FFFFFF] text-xs block' href='tel:+1-3474164888'>+1-3474164888</a>
        <a class='text-[#FFFFFF] text-xs' href='mailto:aamir@primathon.co'>aamir@primathon.co</a>
      </div>
      <div>
        <Common::AppImage @src='/assets/contact/press.svg' alt='press' title='press' />
        <p class='text-lg font-semibold text-[#EEEEEE] mb-0'>Press</p>
        <a class='text-[#FFFFFF] text-xs' href='mailto:hello@primathon.co'>hello@primathon.co</a>
      </div>
      <div>
        <Common::AppImage @src='/assets/contact/partnership.svg' alt='partnership' title='partnership' />
        <p class='text-lg font-semibold text-[#EEEEEE] mb-0'>Partnerships</p>
        <a class='text-[#FFFFFF] text-xs' href='mailto:aamir@primathon.co'>aamir@primathon.co</a>
      </div>
    </div>
  </div>
  */
  {
    "id": "u9CkQUSq",
    "block": "[[[10,0],[14,0,\"bg-[#323F60] px-6 sm:px-12 md:px-20 py-10\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"max-w-[800px] flex flex-col sm:flex-row items-center gap-6 justify-between mx-auto\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,\"alt\",\"sale\"],[24,\"title\",\"sale\"]],[[\"@src\"],[\"/assets/contact/sale.svg\"]],null],[1,\"\\n      \"],[10,2],[14,0,\"text-lg font-semibold text-[#EEEEEE] mb-0\"],[12],[1,\"Sales Inquiries\"],[13],[1,\"\\n      \"],[10,3],[14,0,\"text-[#FFFFFF] text-xs block\"],[14,6,\"tel:+1-3474164888\"],[12],[1,\"+1-3474164888\"],[13],[1,\"\\n      \"],[10,3],[14,0,\"text-[#FFFFFF] text-xs\"],[14,6,\"mailto:aamir@primathon.co\"],[12],[1,\"aamir@primathon.co\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[8,[39,0],[[24,\"alt\",\"press\"],[24,\"title\",\"press\"]],[[\"@src\"],[\"/assets/contact/press.svg\"]],null],[1,\"\\n      \"],[10,2],[14,0,\"text-lg font-semibold text-[#EEEEEE] mb-0\"],[12],[1,\"Press\"],[13],[1,\"\\n      \"],[10,3],[14,0,\"text-[#FFFFFF] text-xs\"],[14,6,\"mailto:hello@primathon.co\"],[12],[1,\"hello@primathon.co\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[8,[39,0],[[24,\"alt\",\"partnership\"],[24,\"title\",\"partnership\"]],[[\"@src\"],[\"/assets/contact/partnership.svg\"]],null],[1,\"\\n      \"],[10,2],[14,0,\"text-lg font-semibold text-[#EEEEEE] mb-0\"],[12],[1,\"Partnerships\"],[13],[1,\"\\n      \"],[10,3],[14,0,\"text-[#FFFFFF] text-xs\"],[14,6,\"mailto:aamir@primathon.co\"],[12],[1,\"aamir@primathon.co\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/contact/contact-us.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});