define("primathon-ember-web/components/pages/services/staff-augmentation/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='sticky top-0 z-10'>
    <Header />
  </div>
  <Services::HeroSectionNew
    @data={{@data.heroSection}}
    @imgStyle='w-[500px] h-[530px] xl:w-[500px] xl:h-[530px]'
    class='bg-[#1A284D]'
  />
  <Services::BulletsList @data={{@data.whyChoose}} @imgStyle='w-[350px] h-[350px] xl:w-[420px] xl:h-[380px]' />
  <Services::CallUs @data={{@data.ctaSection1}} />
  <Services::WhatAreYouLooking />
  <Chatgpt::Benefits @benefits={{@data.whatWeOffer}} />
  <Chatgpt::Getting-started @gettingStarted={{@data.whyChooseUs}} />
  <Services::ArrowList @content={{@data.arrowList}} @style='chatGpt-arrowList' />
  <Chatgpt::Getting-started @gettingStarted={{@data.offshoreDevelopmentProcess}} />
  <Homepage::Tools />
  <Services::CallUs @data={{@data.ctaSection2}} />
  <Chatgpt::Chatgptuse @useCases={{@data.industryWeServe}} />
  <Chatgpt::Getting-started @gettingStarted={{@data.whyOffshoreIsCostEffective}} />
  <Services::FaqList @title={{@data.faqs.title}} @data={{@data.faqs.cardData}} />
  <Footer />
  */
  {
    "id": "84Y3d3bI",
    "block": "[[[10,0],[14,0,\"sticky top-0 z-10\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,1],[[24,0,\"bg-[#1A284D]\"]],[[\"@data\",\"@imgStyle\"],[[30,1,[\"heroSection\"]],\"w-[500px] h-[530px] xl:w-[500px] xl:h-[530px]\"]],null],[1,\"\\n\"],[8,[39,2],null,[[\"@data\",\"@imgStyle\"],[[30,1,[\"whyChoose\"]],\"w-[350px] h-[350px] xl:w-[420px] xl:h-[380px]\"]],null],[1,\"\\n\"],[8,[39,3],null,[[\"@data\"],[[30,1,[\"ctaSection1\"]]]],null],[1,\"\\n\"],[8,[39,4],null,null,null],[1,\"\\n\"],[8,[39,5],null,[[\"@benefits\"],[[30,1,[\"whatWeOffer\"]]]],null],[1,\"\\n\"],[8,[39,6],null,[[\"@gettingStarted\"],[[30,1,[\"whyChooseUs\"]]]],null],[1,\"\\n\"],[8,[39,7],null,[[\"@content\",\"@style\"],[[30,1,[\"arrowList\"]],\"chatGpt-arrowList\"]],null],[1,\"\\n\"],[8,[39,6],null,[[\"@gettingStarted\"],[[30,1,[\"offshoreDevelopmentProcess\"]]]],null],[1,\"\\n\"],[8,[39,8],null,null,null],[1,\"\\n\"],[8,[39,3],null,[[\"@data\"],[[30,1,[\"ctaSection2\"]]]],null],[1,\"\\n\"],[8,[39,9],null,[[\"@useCases\"],[[30,1,[\"industryWeServe\"]]]],null],[1,\"\\n\"],[8,[39,6],null,[[\"@gettingStarted\"],[[30,1,[\"whyOffshoreIsCostEffective\"]]]],null],[1,\"\\n\"],[8,[39,10],null,[[\"@title\",\"@data\"],[[30,1,[\"faqs\",\"title\"]],[30,1,[\"faqs\",\"cardData\"]]]],null],[1,\"\\n\"],[8,[39,11],null,null,null]],[\"@data\"],false,[\"header\",\"services/hero-section-new\",\"services/bullets-list\",\"services/call-us\",\"services/what-are-you-looking\",\"chatgpt/benefits\",\"chatgpt/getting-started\",\"services/arrow-list\",\"homepage/tools\",\"chatgpt/chatgptuse\",\"services/faq-list\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/services/staff-augmentation/index.hbs",
    "isStrictMode": false
  });

  class PagesServicesStaffAugmentationIndexComponent extends _component.default {
    get circular_list_content() {
      return {
        heading: 'Why choose us for your project?',
        items: ['Agile Methodology', 'On time delivery', 'User Centric Approach', 'Highly Qualified and Experienced Team']
      };
    }

    get linear_list_content() {
      return {
        heading: 'Why your buisness needs Staff Augmmentation?',
        items: ['Flexible', 'Cost Effective', 'Saves time', 'No training required']
      };
    }

    get arrow_list_content() {
      return {
        heading: 'Scale up your team with Primathon!',
        items: ['We get in touch with you', 'You share your requirements', 'We curate the best fit for your requirements', 'You shortlist and we start working instantly!']
      };
    }

  }

  _exports.default = PagesServicesStaffAugmentationIndexComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PagesServicesStaffAugmentationIndexComponent);
});