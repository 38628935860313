define("primathon-ember-web/components/services/faq-list/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='faq-list-section px-6 py-10 sm:px-10 sm:py-24 lg:px-16 xl:p-24 max-w-[1600px] mx-auto'>
    <div class='flex flex-col justify-between gap-10 md:flex-row'>
      <div class='relative' style='flex-basis: 25%;'>
        <div class='sticky top-32 md:mb-8'>
          <h2 class='text-2xl sm:text-3xl font-semibold text-[#393F44]'>Frequently Asked Questions</h2>
          <p class='text-[#4D5156] font-medium'>Find answers to commonly asked questions about hiring an Android
            developer.</p>
          <p class='text-lg font-bold text-[#4D5156]'>Still have questions ?</p>
          <LinkTo @route='contact'>
            <button
              class='rounded border-2 border-[#24B768] text-[#24B768] border-solid font-medium text-white py-2 px-7'
            >LET’S TALK!</button></LinkTo>
        </div>
      </div>
      <div class='relative flex-1'>
        {{#each @data as |data|}}
          <details>
            <summary>
              {{data.question}}
              <Common::AppImage class='up-down-icon' @src='/assets/svgs/down.svg' height='20px' width='20px' alt='button icon' />
            </summary>
            <p> {{data.answer}}</p>
          </details>
        {{/each}}
      </div>
    </div>
  </div>
  */
  {
    "id": "2RRa3dFy",
    "block": "[[[10,0],[14,0,\"faq-list-section px-6 py-10 sm:px-10 sm:py-24 lg:px-16 xl:p-24 max-w-[1600px] mx-auto\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col justify-between gap-10 md:flex-row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"relative\"],[14,5,\"flex-basis: 25%;\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"sticky top-32 md:mb-8\"],[12],[1,\"\\n        \"],[10,\"h2\"],[14,0,\"text-2xl sm:text-3xl font-semibold text-[#393F44]\"],[12],[1,\"Frequently Asked Questions\"],[13],[1,\"\\n        \"],[10,2],[14,0,\"text-[#4D5156] font-medium\"],[12],[1,\"Find answers to commonly asked questions about hiring an Android\\n          developer.\"],[13],[1,\"\\n        \"],[10,2],[14,0,\"text-lg font-bold text-[#4D5156]\"],[12],[1,\"Still have questions ?\"],[13],[1,\"\\n        \"],[8,[39,0],null,[[\"@route\"],[\"contact\"]],[[\"default\"],[[[[1,\"\\n          \"],[10,\"button\"],[14,0,\"rounded border-2 border-[#24B768] text-[#24B768] border-solid font-medium text-white py-2 px-7\"],[12],[1,\"LET’S TALK!\"],[13]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"relative flex-1\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"        \"],[10,\"details\"],[12],[1,\"\\n          \"],[10,\"summary\"],[12],[1,\"\\n            \"],[1,[30,2,[\"question\"]]],[1,\"\\n            \"],[8,[39,3],[[24,0,\"up-down-icon\"],[24,\"height\",\"20px\"],[24,\"width\",\"20px\"],[24,\"alt\",\"button icon\"]],[[\"@src\"],[\"/assets/svgs/down.svg\"]],null],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,2],[12],[1,\" \"],[1,[30,2,[\"answer\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@data\",\"data\"],false,[\"link-to\",\"each\",\"-track-array\",\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/services/faq-list/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});