define("primathon-ember-web/components/chatgpt/benefits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='chatgpt-benefitsSection'>
    <div class='beneitsContainer'>
      <div class='headingContainer'>
        <h2 class='heading'>{{@benefits.title}} <span>{{@benefits.subTitle}}</span></h2>
        {{#if @benefits.description}}
          <p class='description'>{{@benefits.description}}</p>
        {{/if}}
      </div>
    </div>
    <div class='cardContainer {{if @maxWidthStyle @maxWidthStyle "max-w-[1400px]"}}'>
      <div class='cards'>
  
        {{#each @benefits.cardData as |card|}}
          <div class='card'>
            {{#if card.imageSrc}}
              <div class='icon'>
                <Common::AppImage @src={{card.imageSrc}} />
              </div>
            {{/if}}
            <div class='cardHeading'>
              <h3>{{card.cardTitle}}</h3>
            </div>
            <div class='cardContent'>
              <p>{{html-safe card.cardData}}</p>
            </div>
          </div>
        {{/each}}
  
      </div>
    </div>
  </div>
  */
  {
    "id": "tmCw1OG9",
    "block": "[[[10,0],[14,0,\"chatgpt-benefitsSection\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"beneitsContainer\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"headingContainer\"],[12],[1,\"\\n      \"],[10,\"h2\"],[14,0,\"heading\"],[12],[1,[30,1,[\"title\"]]],[1,\" \"],[10,1],[12],[1,[30,1,[\"subTitle\"]]],[13],[13],[1,\"\\n\"],[41,[30,1,[\"description\"]],[[[1,\"        \"],[10,2],[14,0,\"description\"],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"cardContainer \",[52,[30,2],[30,2],\"max-w-[1400px]\"]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"cards\"],[12],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"cardData\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"card\"],[12],[1,\"\\n\"],[41,[30,3,[\"imageSrc\"]],[[[1,\"            \"],[10,0],[14,0,\"icon\"],[12],[1,\"\\n              \"],[8,[39,3],null,[[\"@src\"],[[30,3,[\"imageSrc\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"          \"],[10,0],[14,0,\"cardHeading\"],[12],[1,\"\\n            \"],[10,\"h3\"],[12],[1,[30,3,[\"cardTitle\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"cardContent\"],[12],[1,\"\\n            \"],[10,2],[12],[1,[28,[35,4],[[30,3,[\"cardData\"]]],null]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@benefits\",\"@maxWidthStyle\",\"card\"],false,[\"if\",\"each\",\"-track-array\",\"common/app-image\",\"html-safe\"]]",
    "moduleName": "primathon-ember-web/components/chatgpt/benefits.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});