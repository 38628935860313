define("primathon-ember-web/helpers/load-script", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loadScript = loadScript;

  function loadScript(src, setupCallback) {
    let script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.head.appendChild(script);
    script.addEventListener('load', () => {
      if (setupCallback) {
        setupCallback();
      }
    });
    script.addEventListener('error', () => {
      console.error(`The script ${src} failed to load.`);
    });
  }

  var _default = Ember.Helper.helper(loadScript);

  _exports.default = _default;
});