define("primathon-ember-web/constants/services/end-to-end-products/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SHOPIFY_STORES = _exports.ERC = _exports.DATA_VISUALIZATION = _exports.CONTRACT = _exports.BOOKING_PLATFORMS = _exports.APPDEVELOPEMENT = _exports.AIML = _exports.ADMINPALENANDCMS = void 0;
  const BOOKING_PLATFORMS = {
    title: 'Best Booking System Software Provider',
    about: 'An online booking system is a software solution that allows potential guests to self-book and pay through your website and other channels. At Primathon, we provide the best booking system software services and make sure we stay one step ahead at all times.',
    description: 'By automatically updating procedures like payment scheduling, inventory management, and booking tracking, it makes the booking process easier for both you and your clients. Primathon is the best online scheduling software company. The consumers can view all the offered options and reserve the ones they require without jumping between web pages or visiting other websites, it keeps clients on your website!',
    imgUrl: '/assets/end-to-end-services/booking-platform.png',
    bg: 'booking',
    circular_list: {
      heading: 'Why choose us for your project?',
      items: ['Business Centric Approach', 'Seamless integration with your existing system', 'Created with attention to your target customers', 'Highly qualified and experienced team']
    },
    arrow_list: {
      heading: 'Let us take your project on a journey!',
      items: ['You share your idea with us', 'We create a strategy for your project', 'Our team starts building it', 'Product gets launched to the market']
    },
    linear_list: {
      heading: 'Why choose us?',
      items: ['Business Centric Approach', 'Seamless integration with your existing system', 'Created with attention to your target customers ', 'Highly qualified and experienced team']
    }
  };
  _exports.BOOKING_PLATFORMS = BOOKING_PLATFORMS;
  const SHOPIFY_STORES = {
    title: ' Expert Shopify Web Development Services for Your E-commerce Store',
    about: 'Shopify is an e-commerce platform that allows you to create online shopping websites and start selling your products without any hassle. Hire the best shopify ecommerce developers today, choose Primathon!',
    description: 'Shopify is now being used by 11% eCommerce businesses worldwide. It is secure, fast and reliable! It is excellent for online companies that offer products or services and only need a platform to market and sell it. Depending on your business needs, you may use an advanced Shopify homepage section to sell a single item or a number of items. This ultimately raises your ROI and raises the revenue from your online store.',
    imgUrl: '/assets/end-to-end-services/shopify.png',
    bg: 'shopify',
    circular_list: {
      heading: 'Why choose us for your project?',
      items: ['One stop solution to build your Shopify store', 'SEO Optimization', 'Created with attention to your target customers', 'Responsive Design for Optimal Mobile & Tablet View']
    },
    arrow_list: {
      heading: 'Let us take your project on a journey!',
      items: ['You share your idea with us', 'We create a strategy for your project', 'Our team starts building it', 'Product gets launched to the market']
    },
    linear_list: {
      heading: 'Why choose us?',
      items: ['Business Centric Approach', 'Seamless integration with your existing system', 'Created with attention to your target customers ', 'Highly qualified and experienced team']
    }
  };
  _exports.SHOPIFY_STORES = SHOPIFY_STORES;
  const ADMINPALENANDCMS = {
    title: 'Top Admin Panel and CMS Development Services',
    sub_heading: 'We are there every step of the way, from your idea to your product launch! We’re the admin panel development company that you’ve been looking for.',
    about: "It's a tool that gives you access to common administrative operations, server information, and project updates. It gives you the access to monitor and update your website.",
    description: "It gives administrators the tools they need to manage business operations effectively, keep tabs on their performance, and make the necessary adjustments. It can help with user-related tasks including managing user profiles and observing user behavior. It can also keep an eye out for violations of the site's rules, terms and conditions, and all transactions.",
    imgUrl: '/assets/end-to-end-services/admin-pannel.png',
    bg: 'admin',
    circular_list: {
      heading: 'Why choose us for your project?',
      items: ['Agile development and project methodology', 'Rigorous quality assurance (QA) testing', 'Proven track record of successful project', 'Highly qualified and experienced team']
    },
    arrow_list: {
      heading: 'Let us take your project on a journey!',
      items: ['You share your idea with us', 'We create a strategy for your project', 'Our team starts building it', 'Product gets launched to the market']
    },
    linear_list: {
      heading: 'Why choose us?',
      items: ['Business Centric Approach', 'Seamless integration with your existing system', 'Created with attention to your target customers ', 'Highly qualified and experienced team']
    }
  };
  _exports.ADMINPALENANDCMS = ADMINPALENANDCMS;
  const APPDEVELOPEMENT = {
    title: 'Web And Mobile App Development Company',
    about: 'It is the method through which programmers build apps for smartphones, tablets, and other platforms- IOS Apps, Android Apps, and Web Apps. We provide top-notch web application development services worldwide.',
    description: 'With the increased use of technology around us, all kinds of organization and businesses are leveraging technology and improving their existing workflow with technology. Applications built for different device need in depth knowledge of ideal practices for building applications for the platforms, what technologies to use, how to use the application to solve not just the primary, but also additional business requirements!',
    imgUrl: '/assets/end-to-end-services/web-mobile-application.png',
    bg: 'appdevelopment',
    circular_list: {
      heading: 'Why choose us for your project?',
      items: ['Agile development and project methodology', 'Rigorous quality assurance (QA) testing', 'Proven track record of successful project', 'Solid technology expertise across multiple platforms']
    },
    arrow_list: {
      heading: 'Let us take your project on a journey!',
      items: ['You share your idea with us', 'We create a strategy for your project', 'Our team starts building it', 'Product gets launched to the market']
    },
    linear_list: {
      heading: 'Why choose us?',
      items: ['Business Centric Approach', 'Seamless integration with your existing system', 'Created with attention to your target customers ', 'Highly qualified and experienced team']
    }
  };
  _exports.APPDEVELOPEMENT = APPDEVELOPEMENT;
  const AIML = {
    title: 'Artificial Intelligence Services And Solutions',
    about: "With the help of AI, your recommendation engines can make quick and to-the-point recommendations tailored to each customer's needs and preferences. To get the <b>artificial intelligence services for business</b>, Contact us.",
    description: "Recommendations AI uses this expertise in machine learning to give tailored recommendations across all of your touchpoints including your website, mobile experience, email, contact center, and more that are tailored to each customer's tastes and preferences. By demonstrating your understanding of your customers, you may win their trust and loyalty and Primathon provides the best <b>artificial intelligence service offerings Noida</b>.",
    imgUrl: '/assets/end-to-end-services/aiml.png',
    bg: 'aiml',
    circular_list: {
      heading: 'Why choose us for your project?',
      items: ['Business Centric Approach', 'Scalable and future ready', 'Integration with CRM systems', 'Team of experts to maximize value of your data']
    },
    arrow_list: {
      heading: 'Let us take your project on a journey!',
      items: ['You share your idea with us', 'We create a strategy for your project', 'Our team starts building it', 'Product gets launched to the market']
    },
    linear_list: {
      heading: 'Why choose us?',
      items: ['Business Centric Approach', 'Seamless integration with your existing system', 'Created with attention to your target customers ', 'Highly qualified and experienced team']
    }
  };
  _exports.AIML = AIML;
  const ERC = {
    title: 'ERC20 Tokens',
    about: 'On the Ethereum blockchain, an ERC20 token is a standard for creating and issuing smart contracts.',
    description: "On the Ethereum network, there are groups of 'fungible' digital tokens called ERC-20 tokens. When a collection of tokens is fungible, it indicates that each token is identical to every other token in the set. The Ethereum network now supports the creation of custom tokens thanks to the ERC-20 token standard. It has made it simpler for businesses to create blockchain goods rather than creating their own coin.",
    imgUrl: '/assets/end-to-end-services/erc-20.png',
    bg: 'erc',
    circular_list: {
      heading: 'Why choose us for your project?',
      items: ['Flexible to customize as per your requirements', 'Comprehensive testing and QA process', 'Proven track record of successful project', 'Experts in blockchain and Ethereum development']
    },
    arrow_list: {
      heading: 'Let us take your project on a journey!',
      items: ['You share your idea with us', 'We create a strategy for your project', 'Our team starts building it', 'Product gets launched to the market']
    },
    linear_list: {
      heading: 'Why choose us?',
      items: ['Business Centric Approach', 'Seamless integration with your existing system', 'Created with attention to your target customers ', 'Highly qualified and experienced team']
    }
  };
  _exports.ERC = ERC;
  const CONTRACT = {
    title: 'Smart Contract',
    about: 'Smart contracts are programs that run when certain criteria are satisfied and are maintained on a blockchain. They are usually used to automate the execution of an agreement to save time.',
    description: "It not only records the transaction history but also specifies penalties and ground conditions. Once stored, the data cannot be changed by anybody, including the parties to the agreement. The decentralized ledger's contracts have the ability to autonomously enforce obligations and present a higher level of security.",
    imgUrl: '/assets/end-to-end-services/smart-contract.png',
    bg: 'contract',
    circular_list: {
      heading: 'Why choose us for your project?',
      items: ['Customized for your target audience', 'Business centric approach', 'Attention to technical architecture', 'Team of expert to help you get a tangible impact']
    },
    arrow_list: {
      heading: 'Let us take your project on a journey!',
      items: ['You share your idea with us', 'We create a strategy for your project', 'Our team starts building it', 'Product gets launched to the market']
    },
    linear_list: {
      heading: 'Why choose us?',
      items: ['Business Centric Approach', 'Seamless integration with your existing system', 'Created with attention to your target customers ', 'Highly qualified and experienced team']
    }
  };
  _exports.CONTRACT = CONTRACT;
  const DATA_VISUALIZATION = {
    heroSection: {
      gradientTitle: 'Hire Dedicated Shopify Developers',
      description: 'Build a user-friendly, and sales-driven e-commerce platform with our shopify development services.',
      listItems: [{
        id: 1,
        text: '100% Skilled and Certified Developers'
      }, {
        id: 2,
        text: 'Possesses an Average of 5+ Years of Experience'
      }, {
        id: 3,
        text: 'Swift Onboarding Process'
      }, {
        id: 4,
        text: 'Competitive Pricing Models'
      }, {
        id: 5,
        text: 'Transparent and Smooth Communication'
      }],
      ctaText1: 'Book a Call',
      ctaText2: 'Get Free Consultation',
      redirect: '/contact',
      imageSrc: '/assets/end-to-end-services/shopify-stores.png',
      imageAlt: 'Shopify stores'
    },
    dataVisualizationDevelopmentServices: {
      title: 'Why Choose Primathon’s Shopify Development Services?',
      cardData: [{
        id: 1,
        imageSrc: '/assets/data-visualization/clarity.svg',
        cardTitle: 'Expertise and Experience',
        content: 'Our team of shopify web developers specializes in creating customized Shopify solutions that are customized according to your business needs.'
      }, {
        id: 2,
        imageSrc: '/assets/data-visualization/insights.svg',
        cardTitle: 'Comprehensive Services',
        content: 'Our Shopify development services include everything from app development and integrations to store setup and design.'
      }, {
        id: 3,
        imageSrc: '/assets/data-visualization/effective-communication.svg',
        cardTitle: 'Client-Centric Approach',
        content: 'Our Shopify web developer prioritizes your goals and collaborates closely with you to ensure the smooth running and success of your online store.'
      }, {
        id: 4,
        imageSrc: '/assets/data-visualization/decision-making.svg',
        cardTitle: 'Cutting-Edge Technology',
        content: ' With Primathon’s shopify development services, you can use the newest trends and technologies all while staying ahead of the game with our creative solutions.'
      }, {
        id: 5,
        imageSrc: '/assets/data-visualization/decision-making.svg',
        cardTitle: 'Reliable Support',
        content: '  Hire dedicated shopify developers from our team as we offer proactive support and maintenance services to keep your store operating efficiently.'
      }, {
        id: 6,
        imageSrc: '/assets/data-visualization/decision-making.svg',
        cardTitle: 'Proven Track Record',
        content: ' With over 6 years of experience and expertise, we have a skilled team of shopify web developers that adds up to the client satisfaction, significant growth and success.'
      }]
    },
    whyChoose: {
      title: 'Check Out Our Shopify Ecommerce Development Services',
      description: 'Shopify Store Setup: Our team of shopify developers is highly skilled and experienced, thus we understand what your company requires to create an online store that easily fits with your goals.',
      cardData: [{
        id: '1',
        cardTitle: 'Integration and Configuration of Shopify Apps',
        imageSrc: '/assets/data-visualization/tailored-insights.svg',
        cardData: 'With our Shopify integration and configuration service, we help you to integrate payment gateways, custom functionalities, third-party apps, or APIs, among other settings.'
      }, {
        id: '2',
        imageSrc: '/assets/data-visualization/interactive-dashboard.svg',
        cardTitle: 'Shopify Store Design and Development',
        cardData: 'As a leading Shopify eCommerce development company, you can rely on us to get your store configured and make it market-ready without any hassles.'
      }, {
        id: '3',
        imageSrc: '/assets/data-visualization/payment-shipping.png',
        cardTitle: 'Payment and Shipping Zones Setup',
        cardData: 'With our shipping zone setup service, we keep an eye on order fulfillment while assisting you in minimizing logistics expenses. We want to provide your consumers with a completely hassle-free transaction experience with our payment solution.'
      }, {
        id: '4',
        imageSrc: '/assets/data-visualization/real-time-updates.svg',
        cardTitle: 'Theme Development',
        cardData: 'Our Shopify web development service can provide you with responsive, user-friendly, and dynamic Shopify themes for your store since we have a team of effective UI/UX designers and certified Shopify web developers at our disposal.'
      }, {
        id: '5',
        imageSrc: '/assets/data-visualization/shopify-seo.png',
        cardTitle: 'Implementing Shopify SEO',
        cardData: 'With our Shopify SEO installation solution, you can take center stage, stand out from the competition, and draw in a never-ending stream of clients.'
      }, {
        id: '6',
        imageSrc: '/assets/data-visualization/data-migration.png',
        cardTitle: 'Shopify Migrations',
        cardData: 'Our shopify experts will help migrate your store seamlessly from legacy platforms to Shopify. We take minimal downtime and also ensure a hassle-free experience.'
      }]
    },
    serviceProcess: {
      title: 'Our Data Visualization Service Process',
      description: 'At Primathon, our data visualization services follow a meticulous process of data visualization service that drives informed decision-making. Let’s look at how we create impactful data visualizations throughout the stages',
      cardData: [{
        id: 1,
        cardTitle: 'Data Collection',
        content: 'We begin by gathering relevant data from various sources: databases, APIs, spreadsheets, and more. Our team ensures data accuracy, completeness, and relevance.'
      }, {
        id: 2,
        cardTitle: 'Data Analysis',
        content: 'Next, we dive deep into the data. We identify patterns, trends, and outliers. Statistical techniques, machine learning, and domain expertise guide our analysis.'
      }, {
        id: 3,
        cardTitle: 'Visualization Design',
        content: 'Our designers collaborate with data scientists. Together, they choose the right visualization types: bar charts, heatmaps, scatter plots, etc. We focus on clarity, aesthetics, and user experience..'
      }, {
        id: 4,
        cardTitle: 'Implementation',
        content: 'Using tools like D3.js, Tableau, or custom libraries, we bring visualizations to life. Interactive features allow users to explore data dynamically.'
      }, {
        id: 5,
        cardTitle: 'Testing and Iteration',
        content: 'Rigorous testing ensures accuracy and responsiveness. We iterate based on feedback, ensuring our visualizations meet your goals.From line charts to heatmaps, we create interactive visualizations that allow users to explore data dynamically. Choose Primathon for a seamless data visualization process that transforms raw data into actionable insights!.'
      }]
    },
    whatWeOffer: {
      title: `Primathon's Approach to Shopify Store Development Services`,
      description: '',
      cardData: [{
        id: '1',
        imageSrc: '/assets/data-visualization/personalized-insight.svg',
        cardTitle: 'Assessment of Requirements',
        content: 'In order to provide customized Shopify development services that are in line with your vision, our certified Shopify developers start with a thorough consultation to understand your business goals. Being a leading shopify development company, we start by curating a roadmap according to your project requirements.'
      }, {
        id: '2',
        imageSrc: '/assets/data-visualization/flexible-design.svg',
        cardTitle: 'Agreement on Service Models',
        content: `In order to accommodate your project's goals and budget, we provide different engagement models so that you can hire qualified Shopify developers within your budget. As we are a trusted shopify development company, we make sure that you get the best services with financial feasibility. `
      }, {
        id: '3',
        imageSrc: '/assets/data-visualization/technical-support.png',
        cardTitle: 'Support',
        content: 'Our dedicated Shopify developers provide ongoing support and maintenance even after the deployment process to make sure your e-commerce store stays updated and fully functional. Being recognized as the top-notch shopify development company, our experts will go above and beyond to optimize the performance of your shopify store.'
      }, {
        id: '4',
        imageSrc: '/assets/data-visualization/scalability.svg',
        cardTitle: 'Testing',
        content: 'Being a leading Shopify development company, our team conducts rigorous testing and quality assurance to ensure your Shopify store is free of any bugs, has secured gateways, and performs optimally across all devices.'
      }, {
        id: '5',
        imageSrc: '/assets/data-visualization/user-centric-approach.svg',
        cardTitle: 'Design and Development',
        content: 'Our Shopify web developers create custom, responsive designs and integrate essential features, ensuring a seamless user experience and optimized performance for your Shopify website.'
      }]
    },
    whatWeServe: {
      title: 'Industries We Serve with Our Data Visualization',
      subtitle: 'At Primathon, our industry-specific data visualization solutions cater to a diverse range of sectors. Our adaptable services empower decision-makers across',
      imageSrc: '/assets/data-visualization/industries-we-serve.png',
      alt: 'Seeing Ai In Action',
      listItems: [{
        id: 1,
        text: 'Healthcare and Life Sciences'
      }, {
        id: 2,
        text: 'Finance and Banking'
      }, {
        id: 3,
        text: 'Retail and E-Commerce'
      }, {
        id: 4,
        text: 'Manufacturing and Supply Chain'
      }, {
        id: 5,
        text: 'Energy and Utilities'
      }, {
        id: 6,
        text: 'Education and EdTech'
      }]
    },
    ctaSection1: {
      title: 'Ready to start your offshore project?',
      description: 'Contact our offshore development team today to discuss your requirements and take the first step towards achieving your business goals.',
      ctaText: 'HIRE US',
      redirect: '/contact',
      bgColor: '#21315B'
    },
    ctaSection2: {
      title: 'Contact Us for Your Data Visualization Needs',
      description: 'Ready to transform your data into actionable insights? Reach out for data visualization services tailored to your business. Request a data visualization consultation from Primathon today! Let’s illuminate your data journey together.',
      ctaText: 'Contact Us',
      redirect: '/contact',
      bgColor: '#21315B'
    },
    approachToShopifyDevelopment: {
      heading: '95% of our Clients Choose to Return to Us for Additional Services',
      description: [` Hire shopify developers today to boost your online sales Contact us today! We are trusted by 100+ Companies globally.`],
      buttonText: 'Contact Us',
      buttonLink: '/contact',
      imageSrc: '/assets/react-native/approach-to-react-native.png'
    },
    caseStudies: {
      title: 'Case Studies of Our Data Visualization Projects',
      subtitle: 'Our successful data visualization case studies demonstrate how insightful dashboards can transform businesses. Whether it’s understanding customer behavior or optimizing supply chains, our services drive data-driven decisions. Explore our data visualization service portfolio to unlock your business potential!'
    },
    enhanceDecisionMaking: {
      title: 'Key Benefits of Shopify Development Included in Our Services',
      description: '',
      cardData: [{
        id: 1,
        cardTitle: 'Performance Optimization',
        content: 'Being a leading shopify development company, we specialize in optimizing websites for speed, responsiveness, and seamless user experiences. Our goal is to ensure your e-commerce store can efficiently manage increased traffic without sacrificing performance.'
      }, {
        id: 2,
        cardTitle: 'Quality Assurance',
        content: 'We offer Shopify development services with an extensive quality assurance process. We test every aspect of your e-commerce web development to ensure the highest standards of quality, reliability, security, and performance.'
      }, {
        id: 3,
        cardTitle: 'Continuous Support',
        content: 'We offer continuous support and maintenance for your shopify store, ensuring their smooth operation and efficiency. We also ensure they adapt seamlessly to new challenges and requirements as they emerge.'
      }]
    },
    ctaSection2: {
      onlyDesc: true,
      descp1: 'With our experience, quality and proven record of providing incredible solutions to clients across various industries, we have 100+ clients worldwide. Connect with our experts today to achieve 1.5X faster delivery of your project.',
      colouredDescp: '',
      descp2: '',
      ctaText: 'Let’s Get in Touch Today',
      redirect: '/contact',
      bgColor: '#F4F4F4'
    },
    whyChoosePrimathon: {
      title: 'What Sets Us Apart?',
      description: 'At Primathon, our approach to shopify website development goes through careful planning, robust development, rigorous testing, and seamless deployment phases. We prioritize scalability, maintainability, and security to deliver web solutions that perfectly align with your business objectives.',
      listItems: [{
        id: 1,
        text: 'Experience 1.5X Faster Delivery'
      }, {
        id: 2,
        text: 'Work at Your Time Zone'
      }, {
        id: 3,
        text: 'Hassle-free communication'
      }, {
        id: 4,
        text: 'Receive 24/7 Support'
      }, {
        id: 5,
        text: 'Benefit from Flexible Engagement Models'
      }],
      service1: {
        number: '6+',
        text: 'Years of business'
      },
      service2: {
        number: '100+',
        text: 'Software Developers'
      },
      service3: {
        number: '150+',
        text: 'Project Delivered'
      },
      service4: {
        number: '100+',
        text: 'Happy Client'
      }
    },
    faqs: {
      title: 'FAQs About Shopify store',
      cardData: [{
        id: 1,
        question: 'What are Shopify development services?',
        answer: ['Shopify development services includes a variety of services that are intended to help create, modify, and enhance Shopify ecommerce sites. This covers setting up the store, modifying the theme, integrating the app, creating unique features, providing migration services, and active support and maintenance.']
      }, {
        id: 2,
        question: 'Why should I hire a professional Shopify developer?',
        answer: [`By hiring a qualified Shopify developer, you can be sure that your store will be developed using best practices, optimized performance, and tailored to your unique business requirements. To save you time and money, a competent developer can also integrate third-party programs, troubleshoot problems, and offer continuing support.`]
      }, {
        id: 3,
        question: 'How long does it take to develop a Shopify store?',
        answer: [`The time of developing a shopify store depends upon the project's complexity. It could take a few weeks to put up a simple store, but several months to set up a completely customized store with cutting edge features. A seasoned Shopify development company will let you know about the time taken according to the size of your project.`]
      }, {
        id: 4,
        question: 'What kind of support do you offer after the store is live?',
        answer: [`Post-launch support typically includes ongoing maintenance, security updates, bug fixes, and performance optimization. Many Shopify development services also offer additional support plans to help with store updates, feature enhancements, and technical assistance as needed.`]
      }]
    }
  };
  _exports.DATA_VISUALIZATION = DATA_VISUALIZATION;
});