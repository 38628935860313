define("primathon-ember-web/templates/careers/open-position", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XxGS6JCJ",
    "block": "[[[46,[28,[37,1],null,null],null,null,null]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "primathon-ember-web/templates/careers/open-position.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});