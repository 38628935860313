define("primathon-ember-web/components/services/hero-end-to-end/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Services::HeroSection>
    <div class='services-staff-augmentation'>
      <h1>
        Solve your business problem with our technology solution
      </h1>
      <h2>
        We are there every step of the way, from the birth of your idea to your product launch!<br/>
        <b>Services in AI, Data Science, Web and Mobile Applications Development!</b>
      </h2>
      <p>
        End-to-end refers to the entire process: from creating a design concept and planning the product development right through to launching the software.
        Get the best Software development services in the world. Enjoy continuous support that will help you to add new features and increase your value. We keep our clients’ needs first and foremost.
      </p>
    </div>
  </Services::HeroSection>
  
  */
  {
    "id": "9hHfYbZO",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"services-staff-augmentation\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      Solve your business problem with our technology solution\\n    \"],[13],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"\\n      We are there every step of the way, from the birth of your idea to your product launch!\"],[10,\"br\"],[12],[13],[1,\"\\n      \"],[10,\"b\"],[12],[1,\"Services in AI, Data Science, Web and Mobile Applications Development!\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      End-to-end refers to the entire process: from creating a design concept and planning the product development right through to launching the software.\\n      Get the best Software development services in the world. Enjoy continuous support that will help you to add new features and increase your value. We keep our clients’ needs first and foremost.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"services/hero-section\"]]",
    "moduleName": "primathon-ember-web/components/services/hero-end-to-end/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});