define("primathon-ember-web/templates/services/ai-hub/ai-consulting-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9dcMEypD",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@heroSection\",\"@heroSectionImgStyle\",\"@heroSectionClass\",\"@services\",\"@useCases\",\"@benefits\",\"@whyChoose\",\"@journey\",\"@bulletsList\",\"@bulletsListImgStyle\",\"@services2\",\"@caseStudies\",\"@features\",\"@blogData\",\"@isDiscoverAiSolutions\",\"@faqs\"],[[30,1],[30,1,[\"data\",\"heroSection\"]],\"w-[550px] h-[600px] xl:h-[610px]\",\"bg-[#0A1323] lg:pr-0\",[30,1,[\"data\",\"aiConsultingDevelopmentServices\"]],[30,1,[\"data\",\"aiConsultancyExpertise\"]],[30,1,[\"data\",\"whyChoose\"]],[30,1,[\"data\",\"whyChooseUs\"]],[30,1,[\"data\",\"customAiJourney\"]],[30,1,[\"data\",\"unlockBusinessPotential\"]],\"w-[350px] h-[280px] xl:w-[420px] xl:h-[330px]\",[30,1,[\"data\",\"benefitsOfAiConsulting\"]],[30,1,[\"data\",\"caseStudies\"]],[30,1,[\"data\",\"commonlyAiServices\"]],[30,1,[\"blogData\"]],true,[30,1,[\"data\",\"faqs\"]]]],null]],[\"@model\"],false,[\"pages/solutions\"]]",
    "moduleName": "primathon-ember-web/templates/services/ai-hub/ai-consulting-services.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});