define("primathon-ember-web/helpers/html-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.htmlSafe = htmlSafe;

  function htmlSafe(_ref) {
    let [arg] = _ref;
    // Same as default Ember's "htmlSafe" utility function
    // Should be used only with trusted "safe" strings
    return Ember.String.htmlSafe(arg);
  }

  var _default = Ember.Helper.helper(htmlSafe);

  _exports.default = _default;
});