define("primathon-ember-web/constants/homepage/core-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CORE_VALUE_DATA = void 0;
  const CORE_VALUE_DATA = {
    title: 'More than just Developers!',
    description: '',
    data: [{
      title: 'Engineering Mindset',
      description: 'Every developer at Primathon works with an Engineering mindset. This allows us to work on creative solutions and build our own libraries uniquely.'
    }, {
      title: 'Process Driven',
      description: 'From start to end, we follow agile methodology on our projects. We execute the process for continuous improvement discovering solutions for you.'
    }, {
      title: 'On Time Delivery',
      description: 'You don’t have to think about dealing with any delays. Our expert team is confident to design, develop, and deliver on time for a seamless experience.'
    }, {
      title: 'User Driven',
      description: 'How end users interact with the product becomes extremely important in this journey. We understand and value this in our software building process.'
    }]
  };
  _exports.CORE_VALUE_DATA = CORE_VALUE_DATA;
});