define("primathon-ember-web/templates/thank-you", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/bR+O7W6",
    "block": "[[[8,[39,0],null,[[\"@data\"],[[30,1]]],null]],[\"@model\"],false,[\"pages/thank-you\"]]",
    "moduleName": "primathon-ember-web/templates/thank-you.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});