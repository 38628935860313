define("primathon-ember-web/components/pages/services/build-and-manage/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Header />
  <div class='app-container'>
    <Services::HeroBuildAndManage />
    <Services::CircularList @content={{this.circular_list_content}} @color={{'green'}} />
    <Services::LinearList @content={{this.linear_list_content}} />
    <Services::ArrowList @content={{this.arrow_list_content}} @buildAndManage={{true}} />
    <Services::ToolsOfTrades />
    <Services::WeAreTrusted />
    <Homepage::HireUs />
    <Services::BrowseThroughServices @activeService={{2}} />
    <Footer />
  </div>
  */
  {
    "id": "An/yjQdN",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n  \"],[8,[39,2],null,[[\"@content\",\"@color\"],[[30,0,[\"circular_list_content\"]],\"green\"]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@content\"],[[30,0,[\"linear_list_content\"]]]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@content\",\"@buildAndManage\"],[[30,0,[\"arrow_list_content\"]],true]],null],[1,\"\\n  \"],[8,[39,5],null,null,null],[1,\"\\n  \"],[8,[39,6],null,null,null],[1,\"\\n  \"],[8,[39,7],null,null,null],[1,\"\\n  \"],[8,[39,8],null,[[\"@activeService\"],[2]],null],[1,\"\\n  \"],[8,[39,9],null,null,null],[1,\"\\n\"],[13]],[],false,[\"header\",\"services/hero-build-and-manage\",\"services/circular-list\",\"services/linear-list\",\"services/arrow-list\",\"services/tools-of-trades\",\"services/we-are-trusted\",\"homepage/hire-us\",\"services/browse-through-services\",\"footer\"]]",
    "moduleName": "primathon-ember-web/components/pages/services/build-and-manage/index.hbs",
    "isStrictMode": false
  });

  class PagesServicesBuildAndManageIndexComponent extends _component.default {
    get circular_list_content() {
      return {
        heading: 'Why choose us for your project?',
        items: ['Agile Methodology', 'On time delivery', 'User Centric Approach', 'Highly Qualified and Experienced Team']
      };
    }

    get linear_list_content() {
      return {
        heading: 'Why choose us?',
        items: ['Agile Methadology', 'User Centric Approach', 'On-time delivery', 'Highly qualified team']
      };
    }

    get arrow_list_content() {
      return {
        heading: 'Let us take your project on a journey!',
        items: ['You share your idea with us', 'We create a strategy for your project', 'Our team starts building it', 'Product gets launched to the market', 'We continue to manage the product and its growth!']
      };
    }

  }

  _exports.default = PagesServicesBuildAndManageIndexComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PagesServicesBuildAndManageIndexComponent);
});