define("primathon-ember-web/templates/services/staff-augmentation/platform", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UdbPCvgg",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@subHeading\",\"@about\",\"@imgData\",\"@bg\",\"@circular_list\"],[[30,0,[\"platformsTitle\"]],[30,0,[\"platformsSubHeading\"]],[30,0,[\"platformsAbout\"]],[30,0,[\"platformsData\"]],[30,0,[\"platformsBg\"]],[30,0,[\"platformsCircularList\"]]]],null],[1,\"\\n\"]],[],false,[\"pages/services/staff-augmentation-services\"]]",
    "moduleName": "primathon-ember-web/templates/services/staff-augmentation/platform.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});