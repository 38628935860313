define("primathon-ember-web/components/case-study-blog/result-section/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='result-container'>
    <section class='left-part-result'>
      <p class='result-heading'>
        {{@result.title}}
      </p>
      <p class='result-description'>
        {{@result.description}}
      </p>
    </section>
    <section class='right-part-result-desktop'>
      <Common::AppImage @src={{@result.image}} alt='caseStudyResultImage' />
    </section>
  </section>
  */
  {
    "id": "NH/RIVov",
    "block": "[[[10,\"section\"],[14,0,\"result-container\"],[12],[1,\"\\n  \"],[10,\"section\"],[14,0,\"left-part-result\"],[12],[1,\"\\n    \"],[10,2],[14,0,\"result-heading\"],[12],[1,\"\\n      \"],[1,[30,1,[\"title\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"result-description\"],[12],[1,\"\\n      \"],[1,[30,1,[\"description\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"section\"],[14,0,\"right-part-result-desktop\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"alt\",\"caseStudyResultImage\"]],[[\"@src\"],[[30,1,[\"image\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@result\"],false,[\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/case-study-blog/result-section/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});