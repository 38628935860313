define("primathon-ember-web/templates/services/ai-hub/ai-strategy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S/vZDROH",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@heroSection\",\"@heroSectionImgStyle\",\"@heroSectionClass\",\"@services\",\"@benefits\",\"@useCases\",\"@ctaSection1\",\"@whyChoose\",\"@bulletsList\",\"@bulletsListImgStyle\",\"@journey\",\"@blogData\",\"@features\",\"@faqs\"],[[30,1],[30,1,[\"data\",\"heroSection\"]],\"w-[560px] h-[720px] xl:w-[550px] xl:h-[750px]\",\"bg-[#010717] lg:pr-0\",[30,1,[\"data\",\"aiStrategyServices\"]],[30,1,[\"data\",\"needOfAiStrategy\"]],[30,1,[\"data\",\"benefitsOfAiStrategy\"]],[30,1,[\"data\",\"ctaSection1\"]],[30,1,[\"data\",\"whyChoose\"]],[30,1,[\"data\",\"trustedPartner\"]],\"w-[350px] h-[270px]\",[30,1,[\"data\",\"aiStrategyJourney\"]],[30,1,[\"blogData\"]],[30,1,[\"data\",\"featuresOfAiStrategy\"]],[30,1,[\"data\",\"faqs\"]]]],null]],[\"@model\"],false,[\"pages/solutions\"]]",
    "moduleName": "primathon-ember-web/templates/services/ai-hub/ai-strategy.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});