define("primathon-ember-web/components/case-study-blog/custom-solution/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='px-6 mt-12 mb-12 sm:px-16 md:mt-44 max-w-[1400px] mx-auto'>
    <div>
      <h2 class='text-4xl font-medium'>{{@data.title}}</h2>
      <h3 class='text-lg text-black font-medium mt-4 mb-4'>{{@data.subTitle}}</h3>
      <p class='md:mb-12 mb-8 max-w-[1040px] text-base text-gray-500'>{{@data.description}}</p>
    </div>
    <div class='grid grid-cols-1 md:grid-cols-2 gap-x-10 md:gap-x-20 xl:gap-x-24 gap-y-4 md:gap-y-8'>
      {{#each @data.cardData as |case|}}
        <div>
          <div class='flex gap-3'>
            <div>
              <Common::AppImage @src={{case.imageSrc}} alt={{case.cardTitle}} title={{case.cardTitle}} />
            </div>
            <div class='w-11/12'>
              <h3 class='font font-medium mb-1 text-lg'>{{case.cardTitle}}</h3>
              <p class='text-gray-500'>{{html-safe case.cardContent}}</p>
            </div>
          </div>
        </div>
      {{/each}}
    </div>
  </section>
  */
  {
    "id": "2BQ+1EUF",
    "block": "[[[10,\"section\"],[14,0,\"px-6 mt-12 mb-12 sm:px-16 md:mt-44 max-w-[1400px] mx-auto\"],[12],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"text-4xl font-medium\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n    \"],[10,\"h3\"],[14,0,\"text-lg text-black font-medium mt-4 mb-4\"],[12],[1,[30,1,[\"subTitle\"]]],[13],[1,\"\\n    \"],[10,2],[14,0,\"md:mb-12 mb-8 max-w-[1040px] text-base text-gray-500\"],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"grid grid-cols-1 md:grid-cols-2 gap-x-10 md:gap-x-20 xl:gap-x-24 gap-y-4 md:gap-y-8\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"cardData\"]]],null]],null],null,[[[1,\"      \"],[10,0],[12],[1,\"\\n        \"],[10,0],[14,0,\"flex gap-3\"],[12],[1,\"\\n          \"],[10,0],[12],[1,\"\\n            \"],[8,[39,2],[[16,\"alt\",[30,2,[\"cardTitle\"]]],[16,\"title\",[30,2,[\"cardTitle\"]]]],[[\"@src\"],[[30,2,[\"imageSrc\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"w-11/12\"],[12],[1,\"\\n            \"],[10,\"h3\"],[14,0,\"font font-medium mb-1 text-lg\"],[12],[1,[30,2,[\"cardTitle\"]]],[13],[1,\"\\n            \"],[10,2],[14,0,\"text-gray-500\"],[12],[1,[28,[35,3],[[30,2,[\"cardContent\"]]],null]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@data\",\"case\"],false,[\"each\",\"-track-array\",\"common/app-image\",\"html-safe\"]]",
    "moduleName": "primathon-ember-web/components/case-study-blog/custom-solution/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});