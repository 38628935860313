define("primathon-ember-web/components/case-study-blog/process-section/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='process-section'>
    <p class='process-heading'>
      The Process
    </p>
    <p class='about-process'>
      {{@process.description}}
    </p>
  </section>
  */
  {
    "id": "CYOe27uL",
    "block": "[[[10,\"section\"],[14,0,\"process-section\"],[12],[1,\"\\n  \"],[10,2],[14,0,\"process-heading\"],[12],[1,\"\\n    The Process\\n  \"],[13],[1,\"\\n  \"],[10,2],[14,0,\"about-process\"],[12],[1,\"\\n    \"],[1,[30,1,[\"description\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@process\"],false,[]]",
    "moduleName": "primathon-ember-web/components/case-study-blog/process-section/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});