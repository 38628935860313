define("primathon-ember-web/components/services/hero-staff-augmentation/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Services::HeroSection>
    <div class="services-staff-augmentation">
      <h1>
        Staff Augmentation
      </h1>
      <h2>
        The
        <b>
          fastest,
        </b>
        most
        <b>
          reliable
        </b>
        and
        <b>
          costs effective
        </b>
        way to scale up your team!
      </h2>
      <p>
        Staff augmentation is an outsourcing technique that involves acquiring qualified technical employees from outside your company. It enables businesses to select only those candidates who meet their needs and to downsize or expand their augmented teams as needed.
      </p>
      <p>
        It helps businesses to get an extra set of
        <b>
          experienced hands
        </b>
        for when they need it the most.
      </p>
    </div>
  </Services::HeroSection>
  */
  {
    "id": "G3zyi6Vg",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"services-staff-augmentation\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"\\n      Staff Augmentation\\n    \"],[13],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"\\n      The\\n      \"],[10,\"b\"],[12],[1,\"\\n        fastest,\\n      \"],[13],[1,\"\\n      most\\n      \"],[10,\"b\"],[12],[1,\"\\n        reliable\\n      \"],[13],[1,\"\\n      and\\n      \"],[10,\"b\"],[12],[1,\"\\n        costs effective\\n      \"],[13],[1,\"\\n      way to scale up your team!\\n    \"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Staff augmentation is an outsourcing technique that involves acquiring qualified technical employees from outside your company. It enables businesses to select only those candidates who meet their needs and to downsize or expand their augmented teams as needed.\\n    \"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      It helps businesses to get an extra set of\\n      \"],[10,\"b\"],[12],[1,\"\\n        experienced hands\\n      \"],[13],[1,\"\\n      for when they need it the most.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"services/hero-section\"]]",
    "moduleName": "primathon-ember-web/components/services/hero-staff-augmentation/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});