define("primathon-ember-web/components/case-study-blog/empower-vision/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='py-20 md:py-32' style="background-image: url('/assets/images/bottom-section-big-image.png')">
    <div class='max-w-4xl mx-auto px-4 sm:px-6 lg:px-8'>
      <p class='text-3xl md:4xl font-medium text-center text-white mb-4'>Empower Your Vision</p>
      <p class='text-base md:text-lg text-center text-white mb-8'>
        Join forces with industry leaders and turn your dream into reality. Let's collaborate to bring your vision to
        life, leveraging our expertise with the best innovative ideas and brands across diverse industries.
      </p>
      <div class='buttons mt-4 sm:mt-6 mb-6 sm:mb-10 flex justify-center'>
        <LinkTo @route='contact' class='bg-[#1AA0DD] text-white px-12 py-4 rounded'>
          contactUs
        </LinkTo>
      </div>
    </div>
  </div>
  */
  {
    "id": "OF6p8Uj1",
    "block": "[[[10,0],[14,0,\"py-20 md:py-32\"],[14,5,\"background-image: url('/assets/images/bottom-section-big-image.png')\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"max-w-4xl mx-auto px-4 sm:px-6 lg:px-8\"],[12],[1,\"\\n    \"],[10,2],[14,0,\"text-3xl md:4xl font-medium text-center text-white mb-4\"],[12],[1,\"Empower Your Vision\"],[13],[1,\"\\n    \"],[10,2],[14,0,\"text-base md:text-lg text-center text-white mb-8\"],[12],[1,\"\\n      Join forces with industry leaders and turn your dream into reality. Let's collaborate to bring your vision to\\n      life, leveraging our expertise with the best innovative ideas and brands across diverse industries.\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"buttons mt-4 sm:mt-6 mb-6 sm:mb-10 flex justify-center\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"bg-[#1AA0DD] text-white px-12 py-4 rounded\"]],[[\"@route\"],[\"contact\"]],[[\"default\"],[[[[1,\"\\n        contactUs\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"link-to\"]]",
    "moduleName": "primathon-ember-web/components/case-study-blog/empower-vision/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});