define("primathon-ember-web/components/solutions/page-options/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='technology-page-options h-[250px]' ...attributes>
    <ul>
      {{! template-lint-disable no-invalid-interactive }}
      <li>
        <LinkTo class='service-page-link' @route='solutions.ai-chatbot'>
          AI Conversational Chatbot
        </LinkTo>
      </li>
      {{! template-lint-disable no-invalid-interactive }}
      <li>
        <LinkTo class='service-page-link' @route='solutions.ai-discovery'>
          AI Discovery
        </LinkTo>
      </li>
      {{! template-lint-disable no-invalid-interactive }}
      <li>
        <LinkTo class='service-page-link' @route='solutions.search-gpt'>
          Search GPT
        </LinkTo>
      </li>
      {{! template-lint-disable no-invalid-interactive }}
      <li>
        <LinkTo class='service-page-link' @route='solutions.customer-behavior-analysis-tool'>
          Customer Behavior Analysis Tool
        </LinkTo>
      </li>
      {{! template-lint-disable no-invalid-interactive }}
      <li>
        <LinkTo class='service-page-link' @route='solutions.ai-stock-predictor'>
          AI Stock Predictor
        </LinkTo>
      </li>
      <li>
        <LinkTo class='service-page-link' @route='solutions.ai-credit-scoring'>
          AI Credit Scoring
        </LinkTo>
      </li>
    </ul>
  </div>
  */
  {
    "id": "947y9RpT",
    "block": "[[[11,0],[24,0,\"technology-page-options h-[250px]\"],[17,1],[12],[1,\"\\n  \"],[10,\"ul\"],[12],[1,\"\\n\"],[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"service-page-link\"]],[[\"@route\"],[\"solutions.ai-chatbot\"]],[[\"default\"],[[[[1,\"\\n        AI Conversational Chatbot\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"service-page-link\"]],[[\"@route\"],[\"solutions.ai-discovery\"]],[[\"default\"],[[[[1,\"\\n        AI Discovery\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"service-page-link\"]],[[\"@route\"],[\"solutions.search-gpt\"]],[[\"default\"],[[[[1,\"\\n        Search GPT\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"service-page-link\"]],[[\"@route\"],[\"solutions.customer-behavior-analysis-tool\"]],[[\"default\"],[[[[1,\"\\n        Customer Behavior Analysis Tool\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"service-page-link\"]],[[\"@route\"],[\"solutions.ai-stock-predictor\"]],[[\"default\"],[[[[1,\"\\n        AI Stock Predictor\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"service-page-link\"]],[[\"@route\"],[\"solutions.ai-credit-scoring\"]],[[\"default\"],[[[[1,\"\\n        AI Credit Scoring\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"link-to\"]]",
    "moduleName": "primathon-ember-web/components/solutions/page-options/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});