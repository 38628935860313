define("primathon-ember-web/components/open-position/filter/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield this.results}}
  */
  {
    "id": "kBXoy+ta",
    "block": "[[[18,1,[[30,0,[\"results\"]]]]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "primathon-ember-web/components/open-position/filter/index.hbs",
    "isStrictMode": false
  });

  class OpenPositionFilterIndexComponent extends _component.default {
    get results() {
      let {
        data,
        query
      } = this.args;
      let ans = data;

      if (query) {
        if (query.keyword) ans = ans.filter(job => job.title.includes(query.keyword));
        if (query.department) ans = ans.filter(job => job.department == query.department);
        if (query.jobType) ans = ans.filter(job => job.jobType == query.jobType);
      }

      return ans;
    }

  }

  _exports.default = OpenPositionFilterIndexComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OpenPositionFilterIndexComponent);
});