define("primathon-ember-web/components/chatgpt/chatgptuse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='chatgpt-chatgptUseSection'>
    <div class='chatgptUseContainer'>
      <div class='headingContainer'>
        <h2 class='heading max-w-[750px]'>{{@useCases.title}} <span>{{@useCases.subTitle}}</span></h2>
      </div>
      {{#if @useCases.description}}
        <p class='max-w-[1050px] mx-auto'>{{@useCases.description}}</p>
      {{/if}}
    </div>
    <div class='cardContainer'>
      <div class='cards'>
        {{#each @useCases.cardData as |case|}}
          <div class='cards'>
            <div class='card'>
              <div class='icon'>
                <Common::AppImage @src={{case.imageSrc}} />
              </div>
              <div class='cardHeadingContainer'>
                <h3 class='cardHeading'>{{case.cardTitle}}</h3>
              </div>
              <div class='cardContent'>
                <p class='cardPara'>{{html-safe case.content}}</p>
              </div>
            </div>
          </div>
        {{/each}}
      </div>
    </div>
  </div>
  */
  {
    "id": "Ag5dNFNt",
    "block": "[[[10,0],[14,0,\"chatgpt-chatgptUseSection\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"chatgptUseContainer\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"headingContainer\"],[12],[1,\"\\n      \"],[10,\"h2\"],[14,0,\"heading max-w-[750px]\"],[12],[1,[30,1,[\"title\"]]],[1,\" \"],[10,1],[12],[1,[30,1,[\"subTitle\"]]],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,1,[\"description\"]],[[[1,\"      \"],[10,2],[14,0,\"max-w-[1050px] mx-auto\"],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"cardContainer\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"cards\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"cardData\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"cards\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"card\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"icon\"],[12],[1,\"\\n              \"],[8,[39,3],null,[[\"@src\"],[[30,2,[\"imageSrc\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"cardHeadingContainer\"],[12],[1,\"\\n              \"],[10,\"h3\"],[14,0,\"cardHeading\"],[12],[1,[30,2,[\"cardTitle\"]]],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"cardContent\"],[12],[1,\"\\n              \"],[10,2],[14,0,\"cardPara\"],[12],[1,[28,[35,4],[[30,2,[\"content\"]]],null]],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@useCases\",\"case\"],false,[\"if\",\"each\",\"-track-array\",\"common/app-image\",\"html-safe\"]]",
    "moduleName": "primathon-ember-web/components/chatgpt/chatgptuse.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});