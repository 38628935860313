define("primathon-ember-web/routes/index", ["exports", "primathon-ember-web/constants/homepage/services", "primathon-ember-web/constants/homepage/core-value", "primathon-ember-web/constants/homepage/blog", "primathon-ember-web/constants/homepage/testimonial", "primathon-ember-web/constants/careers/testimonial", "primathon-ember-web/constants/event-name", "fetch", "primathon-ember-web/constants/homepage/cta"], function (_exports, _services, _coreValue, _blog, _testimonial, _testimonial2, _eventName, _fetch, _cta) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IndexRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class IndexRoute extends Ember.Route {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "headData", _descriptor, this);

      _initializerDefineProperty(this, "modal", _descriptor2, this);

      _defineProperty(this, "_HOSTNAME", '/blog');

      _defineProperty(this, "_PAGE_SIZE", 4);

      _defineProperty(this, "_URL", `https://primathon.in/blog/wp-json/wp/v2/posts?_embed&per_page=${this._PAGE_SIZE}`);
    }

    getImageUrl(data) {
      if (data._embedded && data._embedded['wp:featuredmedia'] && data._embedded['wp:featuredmedia'][0] && data._embedded['wp:featuredmedia'][0].source_url) {
        return data._embedded['wp:featuredmedia']['0'].source_url;
      }
    }

    async model() {
      const serviceData = _services.SERVICE_DATA;
      const coreValueData = _coreValue.CORE_VALUE_DATA;
      const testimonialData = _testimonial.TESTIMONIAL_DATA;
      const blogData = _blog.BLOG_DATA;
      const employeeTestimonialData = _testimonial2.EMPLOYEE_TESTIMONIAL_DATA;
      const getInTouchClickEvent = _eventName.HOME_POSTER_GET_IN_TOUCH;
      const ctaSection = _cta.CTA_SECTION_DATA;

      try {
        const blogResponse = (await (await (0, _fetch.default)(this._URL)).json()).slice(0, 6);
        blogData['data'] = blogResponse.map(data => {
          return {
            title: data.title?.rendered,
            link: data.link,
            blogImageUrl: this.getImageUrl(data),
            descriptionHtml: data.excerpt?.rendered
          };
        });
      } catch (error) {
        blogData['data'] = [];
      }

      return {
        serviceData,
        coreValueData,
        testimonialData,
        blogData,
        employeeTestimonialData,
        getInTouchClickEvent,
        ctaSection
      };
    }

    beforeModel() {
      const title = 'Best Custom Software Development Service Company | Primathon';
      const description = 'Get custom software development from primathon. We help our customers to transform their products and improve processes to design and develop profitable software faster.';
      let canonical = '';

      if (window.location) {
        canonical = window.location?.origin;
      }

      this.headData.setData({
        title,
        description,
        canonical
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "headData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = IndexRoute;
});