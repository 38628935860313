define("primathon-ember-web/components/services/development-strategy/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='px-6 py-10 sm:px-10 sm:py-24 lg:px-16 xl:p-24'>
    <h2 class='text-2xl sm:text-3xl text-[#05164D] font-semibold text-center mb-10 sm:mb-20'>{{@data.title}}</h2>
    <div class='flex justify-center items-center gap-x-12'>
      <Common::AppImage @src={{@data.imageSrc}} class='{{@imgStyle}} hidden lg:block' alt={{@data.imageAlt}} title={{@data.imageAlt}} />
      <div class='flex flex-col gap-y-8'>
        {{#each @data.cardData as |item|}}
          <div key={{item.id}} class='flex items-center gap-x-8'>
            <div
              class='w-[60px] h-[60px] xl:w-[80px] xl:h-[80px] rounded-full bg-[#198CCA] flex items-center hidden sm:flex'
              style='flex-shrink: 0'
            >
              <p class='text-2xl text-white m-auto'>{{item.id}}</p>
            </div>
            <div class='bg-[#EEEEEE] p-4 sm:p-6 rounded-2xl max-w-[650px] xl:max-w-[700px]'>
              <p class='text-lg text-[#05164D] font-semibold mb-0'>{{item.text}}</p>
              <p class='text-sm text-[#05164D] mb-0'>{{item.description}}</p>
            </div>
          </div>
        {{/each}}
  
      </div>
    </div>
  </section>
  */
  {
    "id": "KSfLZZOf",
    "block": "[[[10,\"section\"],[14,0,\"px-6 py-10 sm:px-10 sm:py-24 lg:px-16 xl:p-24\"],[12],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"text-2xl sm:text-3xl text-[#05164D] font-semibold text-center mb-10 sm:mb-20\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex justify-center items-center gap-x-12\"],[12],[1,\"\\n    \"],[8,[39,0],[[16,0,[29,[[30,2],\" hidden lg:block\"]]],[16,\"alt\",[30,1,[\"imageAlt\"]]],[16,\"title\",[30,1,[\"imageAlt\"]]]],[[\"@src\"],[[30,1,[\"imageSrc\"]]]],null],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-col gap-y-8\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"cardData\"]]],null]],null],null,[[[1,\"        \"],[10,0],[15,\"key\",[30,3,[\"id\"]]],[14,0,\"flex items-center gap-x-8\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"w-[60px] h-[60px] xl:w-[80px] xl:h-[80px] rounded-full bg-[#198CCA] flex items-center hidden sm:flex\"],[14,5,\"flex-shrink: 0\"],[12],[1,\"\\n            \"],[10,2],[14,0,\"text-2xl text-white m-auto\"],[12],[1,[30,3,[\"id\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"bg-[#EEEEEE] p-4 sm:p-6 rounded-2xl max-w-[650px] xl:max-w-[700px]\"],[12],[1,\"\\n            \"],[10,2],[14,0,\"text-lg text-[#05164D] font-semibold mb-0\"],[12],[1,[30,3,[\"text\"]]],[13],[1,\"\\n            \"],[10,2],[14,0,\"text-sm text-[#05164D] mb-0\"],[12],[1,[30,3,[\"description\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@data\",\"@imgStyle\",\"item\"],false,[\"common/app-image\",\"each\",\"-track-array\"]]",
    "moduleName": "primathon-ember-web/components/services/development-strategy/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});