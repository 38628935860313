define("primathon-ember-web/components/chatgpt/hero-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="chatgpt-heroSectionContainer">
      <h1>{{@hero-section.title}}</h1>
      <h3>{{@hero-section.subTitle}}</h3>
  </div>
  */
  {
    "id": "QvuJ2v6k",
    "block": "[[[10,0],[14,0,\"chatgpt-heroSectionContainer\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n    \"],[10,\"h3\"],[12],[1,[30,1,[\"subTitle\"]]],[13],[1,\"\\n\"],[13]],[\"@hero-section\"],false,[]]",
    "moduleName": "primathon-ember-web/components/chatgpt/hero-section.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});