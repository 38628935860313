define("primathon-ember-web/templates/services/end-to-end-products/dashboard-and-data-visualization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O+NOZEzt",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@heroSection\",\"@heroSectionImgStyle\",\"@heroSectionClass\",\"@services\",\"@benefits\",\"@useCases\",\"@features\",\"@bulletsList\",\"@bulletsListImgStyle\",\"@caseStudies\",\"@features2\",\"@benefits2\",\"@faqs\"],[[30,1],[30,1,[\"data\",\"heroSection\"]],\"w-[550px] h-[520px] xl:w-[610px] xl:h-[550px]\",\"bg-[#1A284D] lg:pr-0\",[30,1,[\"data\",\"dataVisualizationDevelopmentServices\"]],[30,1,[\"data\",\"whyChoose\"]],[30,1,[\"data\",\"whatWeOffer\"]],[30,1,[\"data\",\"serviceProcess\"]],[30,1,[\"data\",\"whatWeServe\"]],\"w-[400px] h-[370px] xl:w-[420px] xl:h-[390px]\",[30,1,[\"data\",\"caseStudies\"]],[30,1,[\"data\",\"enhanceDecisionMaking\"]],[30,1,[\"data\",\"contactOurService\"]],[30,1,[\"data\",\"faqs\"]]]],null]],[\"@model\"],false,[\"pages/solutions\"]]",
    "moduleName": "primathon-ember-web/templates/services/end-to-end-products/dashboard-and-data-visualization.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});