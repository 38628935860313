define("primathon-ember-web/app", ["exports", "ember-resolver", "ember-load-initializers", "primathon-ember-web/config/environment", "primathon-ember-web/helpers/load-script"], function (_exports, _emberResolver, _emberLoadInitializers, _environment, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class App extends Ember.Application {
    constructor() {
      super(...arguments);

      _defineProperty(this, "modulePrefix", _environment.default.modulePrefix);

      _defineProperty(this, "podModulePrefix", _environment.default.podModulePrefix);

      _defineProperty(this, "Resolver", _emberResolver.default);
    }

  }

  _exports.default = App;
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  function gtag() {
    window.dataLayer.push(arguments);
  } // Load Google Analytics script


  Ember.run.scheduleOnce('afterRender', void 0, function () {
    if (_environment.default.environment === 'production') {
      // Google Analytics
      const GOOGLE_TAG_MANAGER = `https://www.googletagmanager.com/gtag/js?id=G-EEXF1B3HZD`;
      (0, _loadScript.loadScript)(GOOGLE_TAG_MANAGER);
      window.dataLayer = window.dataLayer || [];
      gtag('js', new Date());
      gtag('config', 'G-EEXF1B3HZD'); // Google Tag Manager

      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-W96LSFHD'); // Clarity


      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () {
          (c[a].q = c[a].q || []).push(arguments);
        };

        t = l.createElement(r);
        t.async = 1;
        t.src = 'https://www.clarity.ms/tag/' + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, 'clarity', 'script', 'kzhc5gore9'); // Amplitude script


      const AMPLITUDE_LINK_1 = 'https://cdn.amplitude.com/libs/analytics-browser-2.7.1-min.js.gz';
      const AMPLITUDE_LINK_2 = 'https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.2.0-min.js.gz';
      const AMPLITUDE_LINK_3 = 'https://cdn.amplitude.com/libs/plugin-autocapture-browser-0.9.0-min.js.gz';
      (0, _loadScript.loadScript)(AMPLITUDE_LINK_1);
      (0, _loadScript.loadScript)(AMPLITUDE_LINK_2);
      (0, _loadScript.loadScript)(AMPLITUDE_LINK_3);

      (function () {
        window.amplitude.init('75734eba145f30e39019234ed103dd78');
        window.amplitude.add(window.sessionReplay.plugin({
          sampleRate: 1
        }));
        window.amplitude.add(window.amplitudeAutocapturePlugin.plugin());
      })();
    } // Calendly


    const CALENDLY_LINK = 'https://assets.calendly.com/assets/external/widget.js';
    (0, _loadScript.loadScript)(CALENDLY_LINK);
    const CALENDLY_STYLE_LINK = 'https://assets.calendly.com/assets/external/widget.css';
    (0, _loadScript.loadScript)(CALENDLY_STYLE_LINK);
  });
});