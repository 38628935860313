define("primathon-ember-web/components/job-desc/post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div style='position: relative;'>
    <div class='job-desc-wrapper'>
      {{#each @jobData as |item|}}
        <JobDesc::ListContainer @data={{item}} />
      {{/each}}
      <hr />
    </div>
  </div>
  */
  {
    "id": "qiVlSAsE",
    "block": "[[[10,0],[14,5,\"position: relative;\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"job-desc-wrapper\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"      \"],[8,[39,2],null,[[\"@data\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[10,\"hr\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@jobData\",\"item\"],false,[\"each\",\"-track-array\",\"job-desc/list-container\"]]",
    "moduleName": "primathon-ember-web/components/job-desc/post.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});