define("primathon-ember-web/templates/services/ai-hub/ai-integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iwFu0yUS",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@heroSection\",\"@heroSectionImgStyle\",\"@heroSectionClass\",\"@services\",\"@benefits\",\"@features\",\"@useCases\",\"@ctaSection1\",\"@bulletsList2\",\"@bulletsListImgStyle2\",\"@bulletsList3\",\"@bulletsListImgStyle3\",\"@journey\",\"@bulletsList\",\"@blogData\",\"@bulletsListImgStyle\",\"@faqs\"],[[30,1],[30,1,[\"data\",\"heroSection\"]],\"w-[550px] h-[630px] xl:w-[585px] xl:h-[680px]\",\"bg-[#010B22] lg:pr-0\",[30,1,[\"data\",\"aiIntegrationServices\"]],[30,1,[\"data\",\"potentialBusiness\"]],[30,1,[\"data\",\"whyChoose\"]],[30,1,[\"data\",\"ourServicesInclude\"]],[30,1,[\"data\",\"ctaSection\"]],[30,1,[\"data\",\"whyAiIntegrationMatters\"]],\"w-[350px] h-[300px] xl:w-[380px] xl:h-[300px]\",[30,1,[\"data\",\"ourApproach\"]],\"w-[300px] h-[220px] xl:w-[340px] xl:h-[240px]\",[30,1,[\"data\",\"aiIntegrationJourney\"]],[30,1,[\"data\",\"bulletData\"]],[30,1,[\"blogData\"]],\"w-[350px] h-[300px] xl:w-[380px] xl:h-[310px]\",[30,1,[\"data\",\"faqs\"]]]],null]],[\"@model\"],false,[\"pages/solutions\"]]",
    "moduleName": "primathon-ember-web/templates/services/ai-hub/ai-integration.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});