define("primathon-ember-web/modifiers/on-resize", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class OnResizeModifier extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);

      _defineProperty(this, "handleResize", () => {
        if (this.useDebounce) {
          this.debounce = Ember.run.debounce(null, this.handler, this.actualElement, this.debounceTime);
        } else {
          this.handler(this.actualElement);
        }
      });

      _defineProperty(this, "cleanup", () => {
        this._resizeObserver.disconnect();

        if (this.debounce) {
          Ember.run.cancel(this.debounce);
        }
      });

      Ember._registerDestructor(this, this.cleanup);
    }

    modify(element, _ref, _ref2) {
      let [handler] = _ref;
      let {
        useDebounce = true,
        debounceTime = 200
      } = _ref2;
      this.actualElement = element;
      this.handler = handler;
      this.useDebounce = useDebounce;
      this.debounceTime = debounceTime;
      this._resizeObserver = new ResizeObserver(this.handleResize);

      this._resizeObserver.observe(element);
    }

  }

  _exports.default = OnResizeModifier;
});