define("primathon-ember-web/helpers/array-contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function arrayContains(_ref) {
    let [array, elem] = _ref;

    if (array && elem) {
      return array.includes(elem);
    }

    return false;
  });

  _exports.default = _default;
});