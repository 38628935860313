define("primathon-ember-web/components/open-position/write-us/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='write'>
    <div style='overflow: hidden;'>
      <Common::AppImage @src='/assets/images/yellow_ellipse.png' class='yellowEllipse' />
    </div>
  
    <section class='container-fluid pre-footer-wrap dark-blue-bg writeUs'>
      <div class='row'>
        <div class='container'>
          <div class='global-comm-wrap'>
            <h2>
              Did not find your profile?
            </h2>
            <p>
              Don’t worry, write to us at hiring@primathon.in and we will get back to you.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
  */
  {
    "id": "+iUvlSCy",
    "block": "[[[10,0],[14,0,\"write\"],[12],[1,\"\\n  \"],[10,0],[14,5,\"overflow: hidden;\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"yellowEllipse\"]],[[\"@src\"],[\"/assets/images/yellow_ellipse.png\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"section\"],[14,0,\"container-fluid pre-footer-wrap dark-blue-bg writeUs\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"container\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"global-comm-wrap\"],[12],[1,\"\\n          \"],[10,\"h2\"],[12],[1,\"\\n            Did not find your profile?\\n          \"],[13],[1,\"\\n          \"],[10,2],[12],[1,\"\\n            Don’t worry, write to us at hiring@primathon.in and we will get back to you.\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"common/app-image\"]]",
    "moduleName": "primathon-ember-web/components/open-position/write-us/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});