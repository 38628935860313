define("primathon-ember-web/components/about-us/our-story/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="section-common our-story-section">
    <div class="responsive-container">
      <h2 class="about-us-heading">
        Our Story
      </h2>
      <p class="about-us-para">
        From our Founders to our Team members, each of us is a graduate of one of India's best universities, which is ranked in the top 50 globally. We regard our work as art and are enthusiastic about all that we craft. With the finest skills and potential to make your projects our ardor and be your partners in growth, our team of talented individuals help you deliver the best.
  We have an eye for
        <b>
          detail
        </b>
        and a knack for
        <b>
          perfection
        </b>
        .
      </p>
    </div>
  </section>
  */
  {
    "id": "9RIMQSYO",
    "block": "[[[10,\"section\"],[14,0,\"section-common our-story-section\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"responsive-container\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"about-us-heading\"],[12],[1,\"\\n      Our Story\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"about-us-para\"],[12],[1,\"\\n      From our Founders to our Team members, each of us is a graduate of one of India's best universities, which is ranked in the top 50 globally. We regard our work as art and are enthusiastic about all that we craft. With the finest skills and potential to make your projects our ardor and be your partners in growth, our team of talented individuals help you deliver the best.\\nWe have an eye for\\n      \"],[10,\"b\"],[12],[1,\"\\n        detail\\n      \"],[13],[1,\"\\n      and a knack for\\n      \"],[10,\"b\"],[12],[1,\"\\n        perfection\\n      \"],[13],[1,\"\\n      .\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "primathon-ember-web/components/about-us/our-story/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});