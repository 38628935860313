define("primathon-ember-web/routes/technologies/ember-js", ["exports", "primathon-ember-web/constants/emberjs/emberjs", "primathon-ember-web/constants/homepage/testimonial"], function (_exports, _emberjs, _testimonial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EmberJsRoute = (_dec = Ember.inject.service, (_class = class EmberJsRoute extends Ember.Route {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "headData", _descriptor, this);
    }

    async model() {
      const emberData = _emberjs.EMBER;
      const testinomialData = _testimonial.TESTIMONIAL_DATA;
      return {
        emberData,
        testinomialData
      };
    }

    beforeModel() {
      const title = `India's Best Ember.js Development Service Company | Primathon`;
      const description = `Launch and build you application faster with primathon's dedicated ember js development services. Connect with us to get best rates.`;
      const keywords = 'top software development services india custom software application development services web application development services india top web application development company delhi best companies for software engineers gurgaon software application development company noida ';
      this.headData.setData({
        title,
        description,
        keywords
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "headData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EmberJsRoute;
});