define("primathon-ember-web/modifiers/timer-callback", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function timerCallback(element, _ref) {
    let [callback, time] = _ref;
    let timeOut = null;

    if (element && callback) {
      timeOut = setInterval(callback, time);
    }

    return () => {
      if (timeOut) {
        clearInterval(timeOut);
      }
    };
  });

  _exports.default = _default;
});