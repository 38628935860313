define("primathon-ember-web/constants/homepage/blog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BLOG_DATA = void 0;
  const BLOG_DATA = {
    title: 'Take a look at our Publications',
    description: ''
  };
  _exports.BLOG_DATA = BLOG_DATA;
});