define("primathon-ember-web/constants/services/ai-hub/ai-services/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AI_SERVICES = void 0;
  const AI_SERVICES = {
    heroSection: {
      gradientTitle: 'Leading',
      title2: 'AI Development Company Offering Advanced AI Services',
      subtitle: 'Your Comprehensive Partner to Explore the Power of Advanced Artificial Intelligence',
      description: 'Get ready to experience the boundless capabilities of Artificial Intelligence (AI). With a comprehensive suite of services, we guide you through every step of your AI journey, from planning, strategising to implementation. Our team of experts tailors solutions to your specific needs, ensuring seamless integration and maximum impact. Partner with Primathon to take the full power of AI and push your business into the future',
      ctaText1: 'Book a Call',
      redirect: '/contact',
      imageSrc: '/assets/ai-services/ai-service-hero-img.png',
      imageAlt: 'AI Consulting services'
    },
    whyChooseAiService: {
      title: 'Why Choose Primathon’s AI Services?',
      cardData: [{
        id: 1,
        imageSrc: '/assets/ai-services/expertise.svg',
        cardTitle: 'Expertise',
        content: ' Benefit from the expertise of professionals in the field of Artificial Intelligence (AI), ensuring that your projects are handled with precision and proficiency.'
      }, {
        id: 2,
        imageSrc: '/assets/ai-services/tailored-solutions.svg',
        cardTitle: 'Tailored Solutions',
        content: 'Enjoy customized AI solutions designed specifically to address your unique business challenges and objectives, maximizing the relevance and effectiveness of our services.'
      }, {
        id: 3,
        imageSrc: '/assets/ai-services/innovation.svg',
        cardTitle: 'Innovation',
        content: "Stay ahead of the curve with Primathon's commitment to continuous innovation in AI technology, leveraging the latest advancements to drive optimal results for your projects."
      }, {
        id: 4,
        imageSrc: '/assets/ai-services/track-record.svg',
        cardTitle: 'Proven Track Record',
        content: " Trust in Primathon's track record of successful AI implementations across various industries, backed by tangible results and satisfied clients."
      }, {
        id: 5,
        imageSrc: '/assets/ai-services/talent-pool.svg',
        cardTitle: 'Global Talent Pool',
        content: 'Access a diverse pool of AI experts and professionals from around the world, ensuring that your projects benefit from diverse perspectives and specialized skills.'
      }, {
        id: 6,
        imageSrc: '/assets/ai-services/communication.svg',
        cardTitle: 'Transparent Communication',
        content: ' Enjoy transparent communication and collaboration throughout the project lifecycle, ensuring that you are kept informed and involved every step of the way.'
      }, {
        id: 7,
        imageSrc: '/assets/ai-services/cost-effectiveness.svg',
        cardTitle: 'Cost-Effectiveness',
        content: "Optimize your AI investments with Primathon's cost-effective solutions, delivering maximum value without compromising on quality or performance"
      }, {
        id: 8,
        imageSrc: '/assets/ai-services/success.svg',
        cardTitle: 'Commitment to Success',
        content: 'Partner with Primathon for a dedicated commitment to your success, as we work tirelessly to achieve your goals and exceed your expectations in every project'
      }]
    },
    exploreUs: {
      title: 'Explore Our List of Comprehensive Services',
      cardData: [{
        id: '1',
        cardTitle: ' AI Consulting Services',
        content: "Our seasoned experts provide comprehensive consulting services tailored to your unique needs. From initial assessment to strategic roadmap development, we guide you every step of the way. Whether you're a startup exploring AI possibilities or an established enterprise looking to optimize operations, our consultancy services ensure you make informed decisions that drive tangible results."
      }, {
        id: '2',
        cardTitle: 'Custom AI Solutions',
        content: " One size does not fit all when it comes to the AI. That's why we specialize in crafting tailor-made solutions aligned with your specific objectives. From predictive analytics to natural language processing, we empower you with bespoke tools that enhance efficiency, productivity, and competitiveness."
      }, {
        id: '3',
        cardTitle: 'AI Integration',
        content: 'Seamless integration of AI into existing workflows is key to maximizing its potential. Our integration services streamline the adoption process, ensuring minimal disruption and maximum impact.'
      }, {
        id: '4',
        cardTitle: 'Generative AI',
        content: 'Explore the creative frontier with our Generative AI solutions. From art and music generation to content creation and design, our advanced algorithms push the boundaries of creativity.'
      }, {
        id: '5',
        cardTitle: 'AI Strategy',
        content: "Crafting a robust AI strategy is essential for long-term success in today's competitive landscape. Our strategic advisors work closely with you to develop comprehensive AI strategies that align with your overarching business goals. From identifying opportunities to mitigating risks, we help you chart a clear path towards AI-driven innovation and growth."
      }]
    },
    howToStart: {
      heading: 'How to Get Started with Our AI Services?',
      items: ['Define Your Requirements', 'Consultation and Proposa', 'Agreement and Kickoff', 'Regular Updates and Communication']
    },
    ctaSection: {
      title: 'Ready to Start Your AI Hub Services?',
      description: 'Contact our team today to discuss your requirements and take the first step towards achieving your business goals.',
      ctaText: 'Contact Us',
      redirect: '/contact',
      bgColor: '#21315B'
    },
    whyChoose: {
      title: 'Why Choose Primathon as Your AI Hub Partner?',
      description: '',
      cardData: [{
        id: 1,
        imageSrc: '/assets/ai-services/expertise-white-icon.svg',
        cardTitle: 'Expertise',
        cardData: 'Our team comprises seasoned professionals with deep expertise across various AI domains, ensuring you receive best-in-class solutions and guidance.'
      }, {
        id: 2,
        imageSrc: '/assets/ai-services/innovation-white-icon.svg',
        cardTitle: 'Innovation',
        cardData: 'We stay at the forefront of technological advancements, continuously exploring new possibilities and pushing the boundaries of what AI can achieve.'
      }, {
        id: 3,
        imageSrc: '/assets/ai-services/tailored-solutions-white-icon.svg',
        cardTitle: 'Tailored Solutions',
        cardData: "We understand that every business is unique. That's why we take a personalized approach, delivering solutions that are specifically tailored to your needs and objectives."
      }, {
        id: 4,
        imageSrc: '/assets/ai-services/collaborative-partnership.svg',
        cardTitle: 'Collaborative Partnership',
        cardData: 'We view our clients as partners on a shared journey towards success. Through open communication and collaboration, we build enduring relationships based on trust and mutual respect.'
      }]
    },
    unlockBusinessPotential: {
      title: 'Unlock the Potential of AI with AI Hub',
      description: "Embrace the future of business with confidence. Whether you're embarking on your AI journey or seeking to optimize existing initiatives, AI Hub is your trusted partner every step of the way. Contact us today to discover how we can help you unlock the full potential of Artificial Intelligence and drive sustainable growth in the digital age",
      imageSrc: '/assets/ai-services/unlock-business-potential.png',
      alt: 'Unlock your Business Potential'
    },
    industryWeServeFor: {
      title: 'Industries We Serve',
      cardData: [{
        id: 1,
        imageSrc: '/assets/ai-services/finance-and-banking.svg',
        cardTitle: 'Finance and Banking',
        content: 'Deliveringaa secure and scalable fintech solutions, including online banking platforms, payment gateways, and trading applications'
      }, {
        id: 2,
        imageSrc: '/assets/ai-services/healthcare.svg',
        cardTitle: 'Healthcare',
        content: 'Providing secure and scalable solutions, encompassing online platforms, payment gateways, and applications.'
      }, {
        id: 3,
        imageSrc: '/assets/ai-services/e-commerce-and-retail.svg',
        cardTitle: 'E-commerce and Retail',
        content: 'We have been providing AI solutions within the e-commerce sector for various years. Our expertise spans from crafting e-commerce platforms and inventory management systems to omnichannel retail solutions and CRM software. With a track record of delivering exceptional solutions, we continue to excel in this domain.'
      }, {
        id: 4,
        imageSrc: '/assets/ai-services/education.svg',
        cardTitle: 'Education',
        content: 'Another area in which our industry expertise lies is e-learning. Building interactive e-learning platforms, learning management systems (LMS), virtual classrooms, and educational apps for students and educators.'
      }, {
        id: 5,
        imageSrc: '/assets/ai-services/hospitality.svg',
        cardTitle: 'Hospitality and Travel',
        content: ' We have been providing booking engines, hotel management software, travel itinerary planners, and customer engagement solutions for the hospitality and travel industry.'
      }]
    },
    ctaSection2: {
      title: 'Are You Still Confused?',
      description: 'Get in Touch with Our AI Experts Today',
      ctaText: 'GET IN TOUCH',
      ctaRoute: '/contact',
      bgColor: '#21315B'
    }
  };
  _exports.AI_SERVICES = AI_SERVICES;
});