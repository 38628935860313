define("primathon-ember-web/templates/solutions/ai-discovery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TRPtHc6F",
    "block": "[[[8,[39,0],null,[[\"@data\",\"@heroSection\",\"@offeringTailoredAIStrategies\",\"@ctaSection\",\"@whyChoose\",\"@featuresOfPrimathonAIDiscovery\",\"@aiDiscoveryJourney\",\"@seeingAiInAction\",\"@potentialWithAIDiscovery\",\"@circularList\",\"@linearList\",\"@elevateYourJourney\"],[[30,1],[30,1,[\"data\",\"heroSection\"]],[30,1,[\"data\",\"offeringTailoredAIStrategies\"]],[30,1,[\"data\",\"ctaSection\"]],[30,1,[\"data\",\"whyChoose\"]],[30,1,[\"data\",\"featuresOfPrimathonAIDiscovery\"]],[30,1,[\"data\",\"aiDiscoveryJourney\"]],[30,1,[\"data\",\"seeingAiInAction\"]],[30,1,[\"data\",\"potentialWithAIDiscovery\"]],[30,1,[\"data\",\"circular_list\"]],[30,1,[\"data\",\"linear_list\"]],[30,1,[\"data\",\"elevateYourJourney\"]]]],null]],[\"@model\"],false,[\"pages/services/ai-discovery\"]]",
    "moduleName": "primathon-ember-web/templates/solutions/ai-discovery.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});