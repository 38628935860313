define("primathon-ember-web/components/services/cta-section/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='bg-[#1A284D] px-6 py-10 sm:p-26'>
    <div class='flex flex-col items-center gap-y-5 max-w-[655px] mx-auto'>
      <h2 class='text-2xl sm:text-3xl text-white text-center'>{{@data.title}}</h2>
      <p class='text-base text-center text-gray-400'>{{@data.description}}</p>
      <div class='flex flex-col sm:flex-row gap-6 items-center'>
        <a href={{@data.redirect1}}>
          <button
            {{on 'click' (if @data.gtagEventName (fired-gtag-event @data.gtagEventName) (fn (noop)))}}
            class='rounded border-2 border-[#24B768] border-solid font-medium text-white py-4 px-9 hover:bg-[#24B768]'
          >
            {{@data.ctaText1}}
          </button>
        </a>
        {{#if @data.ctaText2}}
          <p class='text-sm text-gray-200 mb-0'>OR</p>
          <a href={{@data.redirect2}}>
            <button
              class='rounded border-2 border-[#24B768] border-solid font-medium text-white py-4 px-9 hover:bg-[#24B768]'
            >
              {{@data.ctaText2}}
            </button>
          </a>
        {{/if}}
      </div>
    </div>
  </section>
  */
  {
    "id": "m7bFaC/y",
    "block": "[[[10,\"section\"],[14,0,\"bg-[#1A284D] px-6 py-10 sm:p-26\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col items-center gap-y-5 max-w-[655px] mx-auto\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"text-2xl sm:text-3xl text-white text-center\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n    \"],[10,2],[14,0,\"text-base text-center text-gray-400\"],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-col sm:flex-row gap-6 items-center\"],[12],[1,\"\\n      \"],[10,3],[15,6,[30,1,[\"redirect1\"]]],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"rounded border-2 border-[#24B768] border-solid font-medium text-white py-4 px-9 hover:bg-[#24B768]\"],[4,[38,0],[\"click\",[52,[30,1,[\"gtagEventName\"]],[28,[37,2],[[30,1,[\"gtagEventName\"]]],null],[28,[37,3],[[28,[37,4],null,null]],null]]],null],[12],[1,\"\\n          \"],[1,[30,1,[\"ctaText1\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,1,[\"ctaText2\"]],[[[1,\"        \"],[10,2],[14,0,\"text-sm text-gray-200 mb-0\"],[12],[1,\"OR\"],[13],[1,\"\\n        \"],[10,3],[15,6,[30,1,[\"redirect2\"]]],[12],[1,\"\\n          \"],[10,\"button\"],[14,0,\"rounded border-2 border-[#24B768] border-solid font-medium text-white py-4 px-9 hover:bg-[#24B768]\"],[12],[1,\"\\n            \"],[1,[30,1,[\"ctaText2\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@data\"],false,[\"on\",\"if\",\"fired-gtag-event\",\"fn\",\"noop\"]]",
    "moduleName": "primathon-ember-web/components/services/cta-section/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});