define("primathon-ember-web/components/contact/explore-openings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class='px-6 py-8 md:py-10 md:px-12 bg-[#F4F4F4]'>
    <div
      class='max-w-[1100px] mx-auto flex flex-col md:flex-row justify-center md:justify-between items-center gap-x-10 gap-y-4'
    >
      <div class='max-w-[700px]'>
        <h2 class='text-xl md:text-2xl text-center md:text-left text-[#F1553F] font-semibold'>Are you currently seeking
          job opportunities?</h2>
        <p class='mb-0 mt-1 text-xl text-center md:text-left'>We encourage you to explore our career page to find the
          perfect fit for you.</p>
      </div>
      <LinkTo
        @route='careers'
        class='rounded bg-[#24B768] font-medium text-white py-4 px-9'
        {{on 'click' (fn @trackEvent @eventName)}}
      >Explore Openings</LinkTo>
    </div>
  </section>
  */
  {
    "id": "NCrep9Gf",
    "block": "[[[10,\"section\"],[14,0,\"px-6 py-8 md:py-10 md:px-12 bg-[#F4F4F4]\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"max-w-[1100px] mx-auto flex flex-col md:flex-row justify-center md:justify-between items-center gap-x-10 gap-y-4\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"max-w-[700px]\"],[12],[1,\"\\n      \"],[10,\"h2\"],[14,0,\"text-xl md:text-2xl text-center md:text-left text-[#F1553F] font-semibold\"],[12],[1,\"Are you currently seeking\\n        job opportunities?\"],[13],[1,\"\\n      \"],[10,2],[14,0,\"mb-0 mt-1 text-xl text-center md:text-left\"],[12],[1,\"We encourage you to explore our career page to find the\\n        perfect fit for you.\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,0],[[24,0,\"rounded bg-[#24B768] font-medium text-white py-4 px-9\"],[4,[38,1],[\"click\",[28,[37,2],[[30,1],[30,2]],null]],null]],[[\"@route\"],[\"careers\"]],[[\"default\"],[[[[1,\"Explore Openings\"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@trackEvent\",\"@eventName\"],false,[\"link-to\",\"on\",\"fn\"]]",
    "moduleName": "primathon-ember-web/components/contact/explore-openings.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});